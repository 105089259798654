import React from "react";

import rewind from "./icons/Backwards@1.5x.svg";
import forward from "./icons/Forwards@1.5x.svg";
import loopOff from "./icons/LoopOff@1.5x.svg";
import loopOn from "./icons/LoopOn@1.5x.svg";
import next from "./icons/Next@1.5x.svg";
import pause from "./icons/Pause@1.5x.svg";
import play from "./icons/Play@1.5x.svg";
import previous from "./icons/Previous@1.5x.svg";
import volumeOff from "./icons/VolumeOff@1.5x.svg";
import volumeOn from "./icons/VolumeOn@1.5x.svg";

export const customIcons = {
  play: React.createElement("img", {
    id: "play",
    src: play,
  }),
  pause: React.createElement("img", {
    id: "pause",
    src: pause,
  }),
  rewind: React.createElement("img", {
    id: "rewind",
    src: rewind,
  }),
  forward: React.createElement("img", {
    id: "forward",
    src: forward,
  }),
  previous: React.createElement("img", {
    id: "previous",
    src: previous,
  }),
  next: React.createElement("img", {
    id: "next",
    src: next,
  }),
  loop: React.createElement("img", {
    id: "loop",
    src: loopOn,
  }),

  loopOff: React.createElement("img", {
    id: "loop-off",
    src: loopOff,
  }),
  volume: React.createElement("img", {
    id: "volume",
    src: volumeOn,
  }),
  volumeMute: React.createElement("img", {
    id: "mute",
    src: volumeOff,
  }),
};
