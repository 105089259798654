import { Dispatch } from "react";
import { toast } from "react-toastify";

import { AuthSet, authSetAction } from "../../../shared/store/auth";
import {
  IAuthLogin,
  PasswordResetRequired,
  cognitoUserToAuthState,
  loginCognitoUser,
} from "../../../shared/store/auth/api";

import { events } from "../logger";
import { Path } from "../path/constants";

export async function loginUser(
  loginData: IAuthLogin,
  dispatch: Dispatch<AuthSet>,
  history: Path[]
) {
  try {
    const cognitoUser = await loginCognitoUser(loginData);
    const authState = await cognitoUserToAuthState(cognitoUser);
    dispatch(authSetAction({ isLoaded: true, ...authState }));
    events.login.success();
  } catch (e) {
    events.login.error(e.message);
    if (e instanceof PasswordResetRequired) {
      toast.warn(e.message);
      history.push(Path.RESET_PASSWORD);
      return;
    } else {
      toast.error(e.message);
    }
    throw e;
  }
}
