import { Reducer } from "redux";

import { PagesActions } from "./actions";
import { PagesActionTypes, PagesState } from "./types";

const reducer: Reducer<PagesState, PagesActions> = (state = {}, action) => {
  switch (action.type) {
    case PagesActionTypes.PAGES_DATA_SET: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as pagesReducer };
