import React, { useEffect, useState } from "react";

import { SchoolPlaylist, StoryData } from "clients/store/content";

import { isPlaylistData, isStoryData } from "../filter/helpers/helpers";
import { constructUrl } from "./helpers/helpers";

import ContentTile from "../grid/ContentTile";
import PlaylistTile from "../grid/PlaylistTile";

import "./ContentResults.scss";

interface Props {
  baseUrl: string;
  filteredContentData: (StoryData | SchoolPlaylist)[];
  favorites: string[];
  onSongSelected: (storyId: string) => void;
  onPlaylistSelected: (playlistId: string) => void;
  onFavoriteClicked: (storyId: string) => void;
}

const ContentResults = ({
  baseUrl,
  filteredContentData,
  favorites,
  onSongSelected,
  onPlaylistSelected,
  onFavoriteClicked,
}: Props) => {
  const [orderedContentData, setOrderedContentData] =
    useState<(StoryData | SchoolPlaylist)[]>(filteredContentData);
  useEffect(() => {
    setOrderedContentData(filteredContentData);
  }, [filteredContentData, favorites]);

  return (
    <React.Fragment>
      <div className="content-grid">
        {orderedContentData.map((data: StoryData | SchoolPlaylist) => {
          if (isStoryData(data)) {
            return (
              <ContentTile
                key={(data as StoryData).id + "-story"}
                storyData={data as StoryData}
                tileUrl={constructUrl(baseUrl, data as StoryData)}
                isFavorite={favorites.includes((data as StoryData).id)}
                onSelect={onSongSelected}
                onFavorite={onFavoriteClicked}
              />
            );
          } else if (isPlaylistData(data)) {
            return (
              <PlaylistTile
                key={(data as SchoolPlaylist).id + "-playlist"}
                playlistData={data as SchoolPlaylist}
                onSelect={onPlaylistSelected}
              />
            );
          }
        })}
      </div>
    </React.Fragment>
  );
};

export default ContentResults;
