import React from "react";
import Modal from "react-modal";

import withInput from "../../helpers/withInput";
import { WithInputProps } from "../../helpers/withInput.types";

import { ModalConfirmProp } from "./modal.types";

const ModalConfirm = (props: any & ModalConfirmProp) => {
  const {
    question,
    bodyHTML,
    inputName,
    confirmText,
    cancelText,
    loading,
    close,
    onConfirm,
    privateField = false,
    showCancel = true,
  }: {
    question: string | null;
    bodyHTML: string | null;
    inputName: string | null;
    confirmText: string | null;
    cancelText: string | null;
    loading: boolean;
    close: () => void;
    onConfirm: ((arg0?: string) => void) | null;
    privateField: boolean;
    showCancel: boolean;
  } = props;
  Modal.setAppElement("#root");
  const { input, setInput, onChange, onClick }: WithInputProps = props;
  return (
    <Modal
      isOpen={!!question}
      onRequestClose={close}
      className="modal-small"
      contentLabel={question || ""}
    >
      <h2 className="title">{question}</h2>

      {!!bodyHTML && <div dangerouslySetInnerHTML={{ __html: bodyHTML }} />}

      {!!inputName && (
        <div>
          <div className="label mt-2">{inputName}</div>
          <div className="value mb-2">
            <input
              value={input}
              onChange={onChange(setInput)}
              type={privateField ? "password" : ""}
            />
          </div>
        </div>
      )}

      <div className="actions buttons" style={{ alignItems: "center" }}>
        {showCancel && (
          <button className="btn secondary filled" onClick={close}>
            {cancelText || "No"}
          </button>
        )}

        {loading && (
          <img
            style={{ width: "47px", height: "30px", marginLeft: "30px" }}
            src="/media/loader.gif"
            alt="Loading..."
          />
        )}
        {!loading && onConfirm && confirmText && (
          <button className="btn action filled" onClick={onClick(onConfirm)}>
            {confirmText}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default withInput(ModalConfirm);
