import Api from "clients/helpers/api";

import * as Auth from "shared/store/auth/api";

import { ProfileState } from "../types";

export async function listDevices(): Promise<ProfileState> {
  return (await Api.get("/user/current-user/devices")) as ProfileState;
}

/**
 * Delete device from Cognito
 * @param deviceId
 */
export async function deleteDevice(deviceId: string) {
  const cognitoUser = await Auth.currentAuthenticatedUser();

  await new Promise((resolve, reject) => {
    cognitoUser.forgetSpecificDevice(deviceId, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (e: any) => {
        reject(e);
      },
    });
  });
}
