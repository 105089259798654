import { ProfileUpdateParameters } from "shared/helpers/user/types";

import { ProfileActionsTypes, ProfileState } from "./types";

export interface ProfileSet {
  type: ProfileActionsTypes.PROFILE_SET;
  payload: Partial<ProfileState>;
}

export interface ProfileFetch {
  type: ProfileActionsTypes.PROFILE_FETCH;
}

export interface ProfileUpdate {
  type: ProfileActionsTypes.PROFILE_UPDATE;
  payload: ProfileUpdateParameters;
}

export interface ProfileDevicesFetch {
  type: ProfileActionsTypes.PROFILE_DEVICES_FETCH;
}

export interface ProfileDeviceDelete {
  type: ProfileActionsTypes.PROFILE_DEVICE_DELETE;
  payload: string;
}

// holy boilerplate batman!

export function profileSetAction(profile: ProfileState): ProfileSet {
  return {
    type: ProfileActionsTypes.PROFILE_SET,
    payload: profile,
  };
}

export function profileFetchAction(): ProfileFetch {
  return {
    type: ProfileActionsTypes.PROFILE_FETCH,
  };
}

export function profileUpdateAction(
  params: ProfileUpdateParameters
): ProfileUpdate {
  return {
    payload: params,
    type: ProfileActionsTypes.PROFILE_UPDATE,
  };
}

export function profileDevicesFetchAction(): ProfileDevicesFetch {
  return {
    type: ProfileActionsTypes.PROFILE_DEVICES_FETCH,
  };
}
export function profileDeviceDeleteAction(
  deviceId: string
): ProfileDeviceDelete {
  return {
    payload: deviceId,
    type: ProfileActionsTypes.PROFILE_DEVICE_DELETE,
  };
}

export type ProfileActions =
  | ProfileSet
  | ProfileFetch
  | ProfileUpdate
  | ProfileDeviceDelete;
