import React from "react";

import { ClientCognitoDevice } from "../../../helpers/user/types";

import withRenderOnResize from "../../../../clients/components/higherOrderComponents/layout/withRenderOnResize";

import { DeviceRow, DeviceRowMobile } from "./devices/DeviceRow";
import { EmptyDeviceRow, EmptyDeviceRowMobile } from "./devices/EmptyDeviceRow";

interface Props {
  devices: ClientCognitoDevice[] | undefined;
  deleteDevice: (deviceKey: string) => void;
  shouldUseMobileLayout?: boolean;
}
const DevicesSection = ({
  devices,
  deleteDevice,
  shouldUseMobileLayout,
}: Props) => {
  let emptyDevices = 3;
  const renderEmptyCells = () => {
    const emptyCells = [];
    for (let i = 0; i < emptyDevices; ++i) {
      emptyCells[i] = shouldUseMobileLayout ? (
        <EmptyDeviceRowMobile key={i} />
      ) : (
        <EmptyDeviceRow key={i} />
      );
    }
    return emptyCells;
  };
  return (
    <div className="component-box">
      <div className="section devices-section with-loading-block">
        <div className="content">
          <h1 className="text-center mt-0">Devices</h1>
          <p className="text-center font-bold small mb-2">
            Manage your devices
          </p>
          {(!devices || devices.length < 1) && (
            <div className="section devices-section empty-section">
              <h3 className="text-center">No registered devices found</h3>
            </div>
          )}
          {devices && devices.length > 0 && (
            <React.Fragment>
              {devices.map((device: ClientCognitoDevice) => {
                --emptyDevices;
                return shouldUseMobileLayout ? (
                  <DeviceRowMobile
                    device={device}
                    key={device.DeviceKey}
                    deleteDeviceCallback={deleteDevice}
                  />
                ) : (
                  <DeviceRow
                    device={device}
                    key={device.DeviceKey}
                    deleteDeviceCallback={deleteDevice}
                  />
                );
              })}
              {renderEmptyCells()}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRenderOnResize(DevicesSection);
