import React, { RefObject } from "react";
import { toast } from "react-toastify";

import { Severity } from "@sentry/types";
import braintree, {
  ApplePay,
  ApplePayPayload,
  HostedFieldsTokenizePayload,
  ThreeDSecureVerifyPayload,
} from "braintree-web";
import { HostedFieldsState } from "braintree-web/modules/hosted-fields";
import { AuthorizationResponse, FlowType } from "paypal-checkout-components";

import { BraintreeBillingAddress } from "../../../store/profile/api/braintree";
import { Labels } from "../../../store/promotion";

import { Default } from "../../../helpers/constants";
import {
  captureBreadcrumb,
  captureException,
  captureMessage,
  events,
  formReady,
} from "../../../helpers/logger";
import { BraintreeErrors } from "../../../helpers/payment";
import { payPalAddressToAPIParameters } from "../../../helpers/subscription/payPal";

import FieldButton from "../../../../shared/components/forms/elements/FieldButton";
import FieldError from "../../../../shared/components/forms/elements/fieldError";

import "./PaymentForm.scss";

export interface PaymentFormProps {
  onToken: (
    payload:
      | HostedFieldsTokenizePayload
      | ThreeDSecureVerifyPayload
      | AuthorizationResponse
      | ApplePayPayload,
    billingContact?: BraintreeBillingAddress
  ) => void;
  onApplePay?: (instance: ApplePay) => void;
  onCancel?: () => void;
  labels: Labels;
}

export interface PaymentFormState {
  cvvIsOpen: boolean;
  isLoading: boolean;
}

interface FieldDefinitions {
  [key: string]: {
    inputNode?: RefObject<HTMLInputElement>;
    selector?: string;
    placeholder?: string;
    formGroup?: RefObject<HTMLInputElement>;
  };
}

export class PaymentForm<
  T extends PaymentFormProps,
  S extends PaymentFormState
> extends React.Component<T, S> {
  protected fields: FieldDefinitions = {
    paypalButton: {},
    cardholderName: {},
    number: {
      selector: "#card-number",
      placeholder: "Enter Credit Card Number",
    },
    cvv: {
      selector: "#cvv",
      placeholder: "CVV2",
    },
    expirationDate: {
      selector: "#expiration-date",
      placeholder: "Exp. Date",
    },
    postalCode: {
      selector: "#postal-code",
      placeholder: "Zip / Post Code",
    },
  };

  // Styling for hosted fields
  protected styles = {
    input: {
      "font-size": "16px",
      "font-family": "Lato, sans-serif",
      "font-weight": "600",
      height: "14px",
      color: "#2c2c2d",
    },
    "input::placeholder": {
      "font-size": "16px",
      "font-family": "Lato, sans-serif",
      "font-weight": "600",
      height: "14px",
      color: "#05073c80",
    },
    "input.invalid": {
      color: "#ff5454",
      "border-width": "2px",
      "border-style": "solid",
      "border-color": "#F46363",
    },
  };

  protected hostedFields: braintree.HostedFields | null = null;

  protected braintreeClient: braintree.Client | null = null;

  protected applePayInstance: braintree.ApplePay | null = null;

  private readonly authorization: string;

  private readonly isProduction: boolean;

  constructor(props: T) {
    super(props);
    this.onToken = props.onToken;
    this.onApplePay = props.onApplePay;

    this.authorization = process.env.REACT_APP_BRAINTREE_AUTHORIZATION || "";

    this.isProduction =
      process.env.REACT_APP_BRAINTREE_ENVIRONMENT === "production";

    this.fields.cardholderName.inputNode = React.createRef();
    this.fields.paypalButton.inputNode = React.createRef();

    for (const [name] of Object.entries(this.fields)) {
      this.fields[name].formGroup = React.createRef();
    }

    this.state = {
      isLoading: false,
      cvvIsOpen: false,
    } as S;
  }

  public async componentDidMount(): Promise<void> {
    await this.setAuthorization(this.authorization);
  }

  public componentWillUnmount() {
    this.teardown();
  }

  public handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      this.clearErrors();
      await this.validateInput();
      await this.tokenize();
    } catch (e) {
      if (e) {
        this.onError(e, e.message || "Could not process payment");
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  public async validateInput() {
    if (
      !this.fields.cardholderName ||
      !this.fields.cardholderName.inputNode?.current?.value
    ) {
      this.onError(
        {
          code: "CUSTOM_HOSTED_FIELDS_FIELDS_INVALID",
          details: {
            invalidFieldKeys: ["cardholderName"],
          },
        } as braintree.BraintreeError,
        "Please enter card holder name."
      );
      return Promise.reject();
    }
    events.authorization.submitted();
  }

  public async tokenize() {
    if (!this.hostedFields) {
      return Promise.resolve();
    }

    const { hostedFields, fields, onToken } = this;
    return new Promise((resolve, reject) => {
      hostedFields.tokenize(
        {
          cardholderName: fields.cardholderName.inputNode?.current?.value,
        },
        (tokenizeErr, payload) => {
          if (tokenizeErr) {
            reject(tokenizeErr);
          } else {
            // sending the token will unload it all
            events.authorization.success("Card authorized", "card");
            if (payload) {
              onToken(payload);
              resolve();
            } else {
              reject();
            }
          }
        }
      );
    });
  }

  public toggleCVV(e: { preventDefault: () => void }) {
    e.preventDefault();
    this.setState({ cvvIsOpen: !this.state.cvvIsOpen });
  }

  public render() {
    const onCancel = this.props.onCancel
      ? this.props.onCancel.bind(this)
      : null;
    const onClickApplePay = () => {
      const onApplePay = this.onApplePay?.bind(this);
      if (onApplePay) {
        onApplePay(this.applePayInstance as ApplePay);
      }
    };

    const cancelButton = onCancel ? (
      <div className="mt-1">
        <button
          className="btn secondary filled width-80"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    ) : undefined;

    return (
      <form className="payment-form" onSubmit={this.handleSubmit}>
        <div
          id="apple-pay-container"
          style={{ transform: "scale(0)", position: "absolute" }}
        >
          <button
            type="button"
            className="apple-pay-button"
            onClick={onClickApplePay}
          />
        </div>
        <div id="paypal-button" ref={this.fields.paypalButton.inputNode} />
        <div className="form-group" ref={this.fields.cardholderName.formGroup}>
          <input
            ref={this.fields.cardholderName.inputNode}
            type="text"
            className="form-control"
            placeholder="Card Holder Name"
            name="cardholderName"
            maxLength={30}
            minLength={1}
          />
          {this.fields.cardholderName.formGroup?.current &&
            this.fields.cardholderName.formGroup.current.classList.contains(
              "invalid"
            ) && <FieldError message={"Please check this field"} />}
        </div>
        <div ref={this.fields.number.formGroup}>
          <div className="form-group hosted-input">
            <div id="card-number" className="form-control" />
            {this.fields.number.formGroup?.current &&
              this.fields.number.formGroup.current.classList.contains(
                "invalid"
              ) && <FieldError message={"Please check this field"} />}
          </div>
        </div>
        <div className="d-flex form-group">
          <div ref={this.fields.expirationDate.formGroup}>
            <div className="form-group hosted-input">
              <div id="expiration-date" className="form-control" />

              {this.fields.expirationDate.formGroup?.current &&
                this.fields.expirationDate.formGroup.current.classList.contains(
                  "invalid"
                ) && <FieldError message={"Check this field"} />}
            </div>
          </div>

          <div style={{ paddingLeft: "1em" }} ref={this.fields.cvv.formGroup}>
            <div className="form-group hosted-input">
              <div id="cvv" className="form-control" />

              {this.fields.cvv.formGroup?.current &&
                this.fields.cvv.formGroup.current.classList.contains(
                  "invalid"
                ) && <FieldError message={"Check this field"} />}
            </div>
          </div>
        </div>
        <div ref={this.fields.postalCode.formGroup}>
          <div className="form-group hosted-input">
            <div id="postal-code" className="form-control" />

            {this.fields.postalCode.formGroup?.current &&
              this.fields.postalCode.formGroup.current.classList.contains(
                "invalid"
              ) && <FieldError message={"Please check this field"} />}
          </div>
        </div>
        {!this.state.isLoading && (
          <FieldButton
            text={this.props.labels.submitLabel}
            extra={cancelButton}
          />
        )}
      </form>
    );
  }

  protected onToken: (
    payload:
      | HostedFieldsTokenizePayload
      | ThreeDSecureVerifyPayload
      | AuthorizationResponse
      | ApplePayPayload,
    billingContact?: BraintreeBillingAddress
  ) => void;

  protected onApplePay?: (instance: braintree.ApplePay) => void;

  protected onError(e: braintree.BraintreeError, message: string) {
    let formattedMessage = message;
    if (e.code && e.code in BraintreeErrors) {
      formattedMessage = BraintreeErrors[e.code](e);
      captureBreadcrumb({
        message: e.message,
        level: Severity.Info,
        category: "braintree." + e.code,
        data: {
          code: e.code,
          type: e.type,
        },
      });
    } else {
      if (e.code && e.code.endsWith("_NOT_ENABLED")) {
        // show the message but log it to Sentry anyway
        formattedMessage =
          BraintreeErrors.PAYMENT_REQUEST_UNSUPPORTED_PAYMENT_METHOD(e);
      }
      // we're not handling this message yet, log it to sentry
      captureMessage("Unhandled Braintree Message", {
        category: "braintree.unhandled",
        level: Severity.Error,
        extra: {
          code: e.code,
          message,
          error: e,
        },
      });
    }

    events.authorization.error(e.code);
    toast.error(formattedMessage);

    if (!this.fields) {
      return;
    }

    // field errors
    if (e.details && e.details.invalidFieldKeys) {
      for (const field of e.details.invalidFieldKeys) {
        if (field in this.fields) {
          this.fields[field].formGroup?.current?.classList.add("invalid");
        }
      }
    }
  }

  protected handleEvent(event: HostedFieldsState) {
    const fieldStatus = event.fields;
    const fields = this.fields;
    this.clearErrors();

    for (const item of ["cvv", "expirationDate", "number"] as const) {
      if (!fieldStatus[item]?.isEmpty) {
        if (!fieldStatus[item]?.isPotentiallyValid) {
          fields[item].formGroup?.current?.classList.add("invalid");
        } else if (fieldStatus[item]?.isValid) {
          fields[item].formGroup?.current?.classList.add("valid");
        }
      }
    }
  }

  protected async setAuthorization(authorization: string) {
    const handleEvent = this.handleEvent.bind(this);
    try {
      // Braintree Client
      this.braintreeClient = await this.createBraintreeClient(authorization);
      // Hosted Fields
      this.hostedFields = await this.createHostedFields(this.braintreeClient);
      this.hostedFields.on("validityChange", handleEvent);
      // Paypal
      this.addPaypalButton();
      // Apple Pay
      this.applePayInstance = await this.createApplePayInstance(
        this.braintreeClient
      );
      if (this.applePayInstance) {
        await this.addApplePayButton(this.applePayInstance);
      }
      formReady();
    } catch (e) {
      this.onError(e, e.message);
    }
  }

  protected async createBraintreeClient(
    authorization: string
  ): Promise<braintree.Client> {
    return braintree.client.create({ authorization });
  }

  protected async createHostedFields(
    clientInstance: braintree.Client
  ): Promise<braintree.HostedFields> {
    const fields = Object.entries(this.fields).reduce(
      (acc: Record<string, unknown>, [key, value]) => {
        if (value.selector) {
          acc[key] = {
            selector: value.selector,
            placeholder: value.placeholder,
          };
        }
        return acc;
      },
      {}
    ) as braintree.HostedFieldFieldOptions;

    return braintree.hostedFields.create({
      client: clientInstance,
      styles: this.styles,
      fields,
    });
  }

  protected addPaypalButton() {
    if (this.braintreeClient === null) {
      return;
    }

    const onToken = this.onToken.bind(this);

    const dataLayer = window.dataLayer;

    const paypalButtonData = dataLayer.find((element: object) =>
      Object.keys(element).includes("paypalButtonColour")
    );

    braintree.paypalCheckout
      .create({
        client: this.braintreeClient,
      })
      .then(function (paypalCheckoutInstance) {
        return paypalCheckoutInstance.loadPayPalSDK({ vault: true });
      })
      .then(function (paypalCheckoutInstance) {
        // get the paypal object from window
        const paypal = window.paypal;
        return paypal
          .Buttons({
            style: {
              color: paypalButtonData
                ? paypalButtonData.paypalButtonColour
                : Default.PAYPAL_BUTTON_COLOUR,
              shape: "pill",
              size: "responsive",
              height: 50,
              label: "paypal",
              tagline: "false",
              layout: "horizontal",
            },
            createBillingAgreement: () => {
              return paypalCheckoutInstance.createPayment({
                flow: "vault" as FlowType,
                enableShippingAddress: true,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - not enabled by default and is not in types...
                enableBillingAddress: true,
              });
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onApprove: (data) => {
              return paypalCheckoutInstance
                .tokenizePayment(data)
                .then((payload) => {
                  onToken(
                    payload,
                    payPalAddressToAPIParameters(
                      payload.details.shippingAddress
                    )
                  );
                })
                .catch((e) => {
                  toast.error("Something went wrong. Payment not taken");
                  captureException(e, {
                    category: "payment",
                    showReportDialog: false,
                  });
                });
            },
            onCancel: (data) => {
              toast.error("Payment cancelled. Payment not taken");
              captureMessage("PayPal payment cancelled.", {
                level: Severity.Debug,
                category: "payment",
                extra: { data },
              });
            },

            onError: (e) => {
              toast.error("Something went wrong. Payment not taken");
              captureException(new Error(e), {
                category: "payment",
                showReportDialog: false,
              });
            },
          })
          .render("#paypal-button");
      });
  }

  protected async createApplePayInstance(
    clientInstance: braintree.Client
  ): Promise<braintree.ApplePay | null> {
    if (
      window.ApplePaySession &&
      window.ApplePaySession.supportsVersion(3) &&
      window.ApplePaySession.canMakePayments()
    ) {
      return braintree.applePay.create({
        client: clientInstance,
      });
    }
    return null;
  }

  protected async addApplePayButton(applePayInstance: braintree.ApplePay) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - types not available
    const merchantIdentifier = applePayInstance.merchantIdentifier;
    if (
      window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier)
    ) {
      const applePayContainer = document.getElementById("apple-pay-container");

      if (applePayContainer) {
        applePayContainer.style.transform = "scale(1)";
        applePayContainer.style.position = "relative";
      }
    }
  }

  protected clearErrors() {
    // field errors
    for (const [, definition] of Object.entries(this.fields)) {
      if (definition.formGroup && definition.formGroup.current) {
        definition.formGroup.current.classList.remove("valid", "invalid");
      }
    }
  }

  protected teardown() {
    if (this.hostedFields) {
      this.hostedFields?.teardown();
      this.hostedFields = null;
      this.braintreeClient = null;
    }
    if (this.fields.cardholderName.inputNode?.current) {
      this.fields.cardholderName.inputNode.current.value = "";
    }
    if (this.fields.paypalButton.inputNode?.current) {
      this.fields.paypalButton.inputNode.current.innerHTML = "";
    }
  }
}

export default PaymentForm;
