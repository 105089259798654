function getCookie(name: string): string {
  const re = new RegExp(name + "=([^;]+)");
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : "";
}

function getValueFromCookie(cookie: string, key: string): string {
  /** Bail early if value is not in the cookie */
  if (cookie.includes(key)) {
    const splitCookie = cookie.split(",");
    for (const string of splitCookie) {
      /** find the part of the cookie that matches */
      if (string.includes(key)) {
        /** return the value for the key */
        return string.split(":")[1];
      }
    }
  }
  return "";
}

export function hasMarketingConsent(): boolean {
  const consentCookie = getCookie("Consent");
  return getValueFromCookie(consentCookie, "marketing") === "true";
}
