import Api from "clients/helpers/api";

import { GetNewCognitoUsernameResponse } from "../types";

export async function getNewCognitoUsername(): Promise<GetNewCognitoUsernameResponse> {
  return Api.get("/user/new-cognito-username", {
    platformOsVersion: process.env.REACT_APP_CLIENT_VERSION || 2,
  });
}

export default { getNewCognitoUsername };
