import React from "react";

import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import * as Yup from "yup";

import { IAuthChangePassword } from "shared/store/auth/api";

import { PASSWORDS_DO_NOT_MATCH } from "shared/helpers/messages";

import FormButton from "../../elements/FormButton";
import FieldError from "../../elements/fieldError";

export interface PasswordChangeFormProps {
  changePassword: (data: IAuthChangePassword) => Promise<void>;
}

export interface IPasswordChange {
  password: string;
  repeatedPassword: string;
}

const PasswordChangeFormTemplate = (
  props: PasswordChangeFormProps & FormikProps<IPasswordChange>
) => {
  const { errors, touched, isSubmitting } = props;

  return (
    <Form>
      <div className="form-group">
        <Field
          className="form-control"
          name="password"
          type="password"
          placeholder="New Password"
        />
        {errors.password && touched.password && (
          <FieldError message={errors.password} />
        )}
      </div>

      <div className="form-group">
        <Field
          className="form-control"
          name="repeatedPassword"
          type="password"
          placeholder="Repeat Password"
        />
        {errors.repeatedPassword && touched.repeatedPassword && (
          <FieldError message={errors.repeatedPassword} />
        )}
      </div>

      <FormButton text={"Set Password"} withLoading={isSubmitting} />
    </Form>
  );
};

const PasswordChangeForm = withFormik<PasswordChangeFormProps, IPasswordChange>(
  {
    displayName: "PasswordChangeForm",
    handleSubmit: (
      values: IPasswordChange,
      formikBag: FormikBag<PasswordChangeFormProps, IPasswordChange>
    ) => {
      if (values.password !== values.repeatedPassword) {
        formikBag.setFieldError("repeatedPassword", PASSWORDS_DO_NOT_MATCH);
        formikBag.setSubmitting(false);
        return;
      }
      formikBag.props
        .changePassword({ password: values.password })
        .catch()
        .finally(() => {
          formikBag.setSubmitting(false);
        });
    },
    mapPropsToValues: () => {
      return {
        password: "",
        repeatedPassword: "",
      };
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().min(8).required("Password is a required field"),
      repeatedPassword: Yup.string()
        .min(8, "Repeated password must be at least 8 characters")
        .required("Repeated password is a required field"),
    }),
  }
)(PasswordChangeFormTemplate);

export default PasswordChangeForm;
