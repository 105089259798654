import { VersionActionTypes, VersionState } from "./types";

export interface VersionSet {
  type: VersionActionTypes.VERSION_SET;
  payload: Partial<VersionState>;
}

export interface VersionFetch {
  type: VersionActionTypes.VERSION_FETCH;
}

export function versionSetAction(version: VersionState): VersionSet {
  return {
    type: VersionActionTypes.VERSION_SET,
    payload: version,
  };
}

export function versionFetchAction(): VersionFetch {
  return {
    type: VersionActionTypes.VERSION_FETCH,
  };
}

export type VersionActions = VersionSet | VersionFetch;
