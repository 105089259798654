import React, { useState } from "react";

import { LessonPlanData } from "../../../../../store/content";

import ExpandButton from "../../../../../../shared/components/controls/buttons/ExpandButton";

import PlaylistSchoolResourceCell from "./PlaylistSchoolResourceCell";

import "./PlaylistSchoolResouces.scss";

interface Props {
  resources?: LessonPlanData[];
}

const MAX_NOT_EXPANDED_CELLS = 2;

const PlaylistSchoolResources = ({ resources }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const onExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="playlist-resources">
      <div className="playlist-resources-header">
        <h1 className="title">Playlist resources</h1>
        {resources && resources.length > MAX_NOT_EXPANDED_CELLS && (
          <ExpandButton expanded={expanded} onExpandToggle={onExpandToggle} />
        )}
      </div>
      {resources && (
        <div className="playlist-resources-container">
          {resources.map((resource, i) => {
            if (i < MAX_NOT_EXPANDED_CELLS) {
              return (
                <PlaylistSchoolResourceCell key={i} resource={resources[i]} />
              );
            } else if (expanded) {
              return (
                <PlaylistSchoolResourceCell key={i} resource={resources[i]} />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default PlaylistSchoolResources;
