import React, { useState } from "react";

import { SchoolPlaylist, StoryData } from "../../../../store/content";

import HeartIcon from "../../images/Favourite@1_5x.svg";
import HeartIconActive from "../../images/FavouriteActive@1_5x.svg";
import StarIcon from "../../images/Star@1_5x.svg";
import StarIconActive from "../../images/StarActive@1_5x.svg";
import ContentResults from "../results/ContentResults";
import LinkedDevices from "./LinkedDevices";
import Tab from "./Tab";

import "./FeaturedSection.scss";

interface Props {
  baseUrl: string;
  featuredContent: (StoryData | SchoolPlaylist)[];
  favorites: StoryData[];
  onSongSelected: (storyId: string) => void;
  onPlaylistSelected: (playlistId: string) => void;
  onFavoriteClicked: (storyId: string) => void;
}
const FeaturedSection = ({
  baseUrl,
  featuredContent,
  favorites,
  onSongSelected,
  onPlaylistSelected,
  onFavoriteClicked,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>("Featured");
  const onTabClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedTab(e.currentTarget.id);
  };

  enum Tabs {
    FEATURED = "Featured",
    FAVORITES = "Favorites",
    DEVICES = "Student devices",
  }

  return (
    <div className="featured-section">
      <div className="featured-section-header">
        <Tab
          title={Tabs.FEATURED}
          activeIcon={StarIconActive}
          inactiveIcon={StarIcon}
          onClick={onTabClick}
          selected={selectedTab === Tabs.FEATURED}
        />
        <Tab
          title={Tabs.FAVORITES}
          activeIcon={HeartIconActive}
          inactiveIcon={HeartIcon}
          onClick={onTabClick}
          selected={selectedTab === Tabs.FAVORITES}
        />
        <Tab
          title={Tabs.DEVICES}
          activeIcon={StarIconActive}
          inactiveIcon={StarIcon}
          onClick={onTabClick}
          selected={selectedTab === Tabs.DEVICES}
        />
      </div>
      <div className="featured-section-content">
        {selectedTab !== Tabs.DEVICES && (
          <ContentResults
            baseUrl={baseUrl}
            filteredContentData={
              selectedTab === Tabs.FEATURED ? featuredContent : favorites
            }
            favorites={favorites.map((song) => song.id)}
            onSongSelected={onSongSelected}
            onPlaylistSelected={onPlaylistSelected}
            onFavoriteClicked={onFavoriteClicked}
          />
        )}
        {selectedTab === Tabs.DEVICES && (
          <>
            <LinkedDevices />
          </>
        )}
      </div>
    </div>
  );
};

export default FeaturedSection;
