import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { authLogoutAction } from "../../../../shared/store/auth";
import {
  IAuthLogin,
  loginCognitoUser,
} from "../../../../shared/store/auth/api";
import { deleteCurrentUser } from "../../../store/profile/api/user";

import { events } from "../../../helpers/logger";

import ModalConfirm from "shared/components/modal/ModalConfirm";

const AccountManagementSection = ({ username }: { username: string }) => {
  const dispatch = useDispatch();

  const callLogout = () => {
    dispatch(authLogoutAction());
    events.logout.success();
  };

  const [modalQuestion, setModalQuestion] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const showDeleteUserModal = () => {
    setModalQuestion("Are you sure you want to delete your Moshi account?");
  };

  const closeDeleteUserModal = () => {
    setModalQuestion(null);
  };

  const confirmPassword = async (password: string) => {
    if (!password) {
      toast.warn("Please enter your password.");
      return;
    }
    setIsLoading(true);
    const loginData: IAuthLogin = {
      username: username,
      password: password,
    };
    try {
      const cognitoUser = await loginCognitoUser(loginData);
      if (cognitoUser) {
        await deleteCurrentUser();
        callLogout();
        closeDeleteUserModal();
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="component-box">
        <div className="content">
          <h1>Account management</h1>
          <button
            type="button"
            onClick={showDeleteUserModal}
            className="btn action filled wide"
          >
            Delete account
          </button>
        </div>
      </div>
      <ModalConfirm
        question={modalQuestion}
        inputName={"Password"}
        confirmText="Yes"
        cancelText="No"
        loading={isLoading}
        close={closeDeleteUserModal}
        onConfirm={confirmPassword}
        privateField={true}
      />
    </React.Fragment>
  );
};

export default AccountManagementSection;
