export interface Filter {
  categoryFilters: string[];
  selFilters: string[];
  durationFilters: string[];
  themeFilters: string[];
}

export interface PagesState {
  readonly [pathname: string]: PageData;
}

export interface PageData {
  readonly scrollData: PageScrollData;
  readonly filterData?: Filter;
}

export interface PageScrollData {
  readonly yPosition: number;
  readonly xPosition?: number;
}

export interface PageFilterData {
  readonly selectedFilterId: string;
  readonly selectedSubFilterId?: string;
  readonly selectedThemeFilterId?: string;
}

export enum PagesActionTypes {
  PAGES_DATA_SET = "@@pages/PAGES_DATA_SET",
}
