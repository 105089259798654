import React from "react";

import errorIcon from "../images/error.svg";

const FieldError = ({ message }: { message?: string }) => {
  return (
    <React.Fragment>
      <img className="error-icon" src={errorIcon} alt="error" />
      <div className="error-message">{message}</div>
    </React.Fragment>
  );
};

FieldError.defaultProps = {
  name: "An error occurred",
};
export default FieldError;
