// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getConfiguredCache } from "money-clip";
import { call } from "redux-saga/effects";

import { ContentApiDataState } from "../../store/content";

import { config } from "./constants";

export const cache = getConfiguredCache(config);

export function getCache() {
  return cache;
}

/**
 * Used to remove user specific data from content api cache
 * @param {Partial<ContentApiDataState>}data
 */
export function setContentApiData(data: Partial<ContentApiDataState>) {
  return cache.set("contentApiData", data);
}

/**
 * Clear user data from cache on logout
 */
export function* clearUserCacheData() {
  yield call(cache.del, "auth");
  yield call(cache.del, "profile");
  yield call(cache.del, "content");
  yield call(cache.del, "contentApiData.dynamic");
  yield call(cache.del, "contentApiData.favorites");
}
