import React from "react";

const LinkButton = ({
  text,
  link,
  analyticsId,
}: {
  text: string;
  link: string;
  analyticsId?: string;
}) => {
  return (
    <a
      target="_blank"
      className="ga-event"
      id={analyticsId}
      rel="noopener noreferrer"
      href={link}
    >
      {text}
    </a>
  );
};

export default LinkButton;
