export interface SchoolPlaylistsContentApiResponse {
  readonly schoolsPlaylists: SchoolPlaylist[];
  readonly playlistGroups: PlaylistGroup[];
}

export interface Playlist {
  readonly id: string;
  readonly active?: boolean;
  readonly name: string;
  readonly order: number;
  readonly storyIds: string[];
  readonly isSequential: boolean;
  readonly isNew: boolean;
  readonly description: string;
  readonly tileUrl: string;
  readonly miniHomeTileUrl: string;
  readonly featuredHomeTileUrl: string;
  readonly label?: string;
}

export interface SchoolPlaylist extends Playlist {
  readonly lessonPlansData?: LessonPlanData[];
  readonly duration: number;
}

export interface LessonPlanData {
  readonly lessonPlanId: string;
  readonly title: string;
  readonly ageGroup: LessonAgeGroup;
  readonly type: LessonPlanType;
  readonly mediaUrl: string;
}

export enum LessonAgeGroup {
  K_2 = "k-2",
  THREE_TO_FIVE = "3-5",
}

export enum LessonPlanType {
  OVERVIEW = "Overview",
  LESSON_PLAN = "Lesson plan",
  ACTIVITY = "Activity",
  TIP = "Teacher tip",
  ZIP = "Zip Bundle",
}

export interface PlaylistGroup {
  readonly id: string;
  readonly name: string;
  readonly playlistIds: string[];
}
