import React from "react";

import inactiveButton from "../../images/bin-inactive.svg";

export const EmptyDeviceRow = () => {
  return (
    <div className="row">
      <div className="device-group empty">
        <p className="device">Empty</p>
      </div>
      <img
        className="navigation-action-menu-item"
        src={inactiveButton}
        alt="Remove"
      />
    </div>
  );
};

export const EmptyDeviceRowMobile = () => {
  return (
    <div className="row">
      <div className="device-group empty">
        <p className="device">Empty</p>
      </div>
    </div>
  );
};
