import React from "react";

import { MenuItem } from "../types";

interface Props {
  menuItems: MenuItem[];
}
const NavigationFooterMobile = ({ menuItems }: Props) => {
  return (
    <ul className="navigation-footer-mobile">
      {menuItems.map((item) => (
        <li
          id={item.id}
          key={item.id}
          className="navigation-menu-mobile-footer-item"
        >
          <a href={item.href}>{item.label}</a>
        </li>
      ))}
    </ul>
  );
};

export default NavigationFooterMobile;
