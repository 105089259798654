import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { setupCache } from "axios-cache-adapter";

import {
  CodeRedeemError,
  ResourceGoneError,
  ServerError,
  ValidationError,
} from "shared/helpers/errors";

import { Default } from "./constants";
import { captureBreadcrumb, captureException } from "./logger";

axios.defaults.baseURL =
  process.env.REACT_APP_API_ENDPOINT ||
  process.env.REACT_APP_CLIENTS_API_ENDPOINT;
axios.defaults.headers.common["Client-Version"] = Default.WEB_CLIENT_VERSION;

const cache = setupCache({ maxAge: 0 });
const api = axios.create({
  baseURL: axios.defaults.baseURL,
  adapter: cache.adapter,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
});

async function handleSuccess(a: AxiosResponse) {
  return a.data;
}

async function handleError(e: AxiosError) {
  if (e.response) {
    if (e.response.data) {
      const data = e.response.data;

      captureBreadcrumb({ data, message: data.message, category: "response" });

      if (data.reportId) {
        // tslint:disable-next-line:no-console
        console.info(
          "&#1F997; Looks like you've caught a bug: " +
            data.reportId +
            " url: " +
            e.config.url
        );

        throw new ServerError(data);
      }
      if (e.response.status === 410) {
        // resource does not exist - do not retry
        throw new ResourceGoneError(data);
      }
      switch (data.code) {
        case "validation-error":
          throw new ValidationError(data);
        case "redemption-code-error":
        case "referral-code-error":
          throw new CodeRedeemError(data);
        default:
          captureException(data.code, {
            category: "api.unknown",
            extra: { data: data },
          });
          throw e.response.data;
      }
    }
  }
  captureException(e, {
    message: "Unhandled API Exception",
    category: "response",
    extra: e.response,
  });
  throw e;
}

export async function get(path: string, data = {}, headers = {}, maxAge = 0) {
  return api
    .get(path, { params: data, headers, cache: { maxAge } })
    .then(handleSuccess)
    .catch(handleError);
}

export async function post(path: string, data = {}, headers = {}) {
  return axios
    .post(path, data, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function put(path: string, data = {}, headers = {}) {
  return axios
    .put(path, data, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export async function del(path: string, headers = {}) {
  return axios
    .delete(path, {
      headers,
    })
    .then(handleSuccess)
    .catch(handleError);
}

export default {
  post,
  get,
  put,
  del,
};
