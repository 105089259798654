import React from "react";

import { StoryData } from "../../../../store/content";

import { constructUrl } from "./helpers/helpers";

import ContentTile from "../grid/ContentTile";

import "./ContentResults.scss";

interface Props {
  baseUrl: string;
  fallbackContent: StoryData[];
  favorites: string[];
  onSongSelected: (storyId: string) => void;
  onFavoriteClicked: (storyId: string) => void;
}

const FallbackResults = ({
  baseUrl,
  fallbackContent,
  favorites,
  onSongSelected,
  onFavoriteClicked,
}: Props) => {
  return (
    <div className="content-grid">
      {fallbackContent.map((storyData) => (
        <ContentTile
          key={storyData.id}
          storyData={storyData}
          tileUrl={constructUrl(baseUrl, storyData)}
          isFavorite={favorites.includes(storyData.id)}
          onSelect={onSongSelected}
          onFavorite={onFavoriteClicked}
        />
      ))}
    </div>
  );
};

export default FallbackResults;
