import { captureException } from "clients/helpers/logger";
import { Store } from "redux";

import { authSetAction } from "shared/store/auth";
import {
  AuthMissing,
  cognitoUserToAuthState,
  getCognitoUser,
  logoutCognitoUser,
} from "shared/store/auth/api";

import { clearUserCacheData } from "../../helpers/cache/cache";

import { ApplicationState } from "../index";

/**
 * This should actually be within a saga, but the base is shared with admin so
 *  we would have to do some weird inheritance things,
 *   we do a simple call instead
 * @param store
 */
export async function initClientUser(store: Store<ApplicationState>) {
  try {
    const cognitoUser = await getCognitoUser();
    const authState = await cognitoUserToAuthState(cognitoUser);
    store.dispatch(authSetAction({ ...authState, isLoaded: true }));
    return await Promise.resolve();
  } catch (e) {
    if (!(e instanceof AuthMissing)) {
      // User Auth bad, clear to avoid retrying repeatedly.
      await logoutCognitoUser();
      store.dispatch(
        authSetAction({
          isLoaded: true,
          isLoggedIn: false,
          user: undefined,
        })
      );
      return;
    }
    // no user logged in, or error. loading auth is complete
    clearUserCacheData();
    store.dispatch(
      authSetAction({
        isLoaded: true,
        isLoggedIn: false,
        user: undefined,
      })
    );
  }
}

export function getTimeZone(): string | null {
  let timeZone = null;
  try {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    captureException(e, { category: "braintree.timezone" });
  }
  return timeZone;
}
