import React, { useState } from "react";

import {
  CATEGORY_IDS,
  NARRATOR_TEXT_BY_CATEGORY_ID,
} from "clients/store/content/constants";

import ShareModal from "../../../../modal/ShareModal";
import favoriteInactiveIcon from "../../../images/Heart@1_5x.svg";
import favoriteActiveIcon from "../../../images/Icon_Active_Heart@1_5x.svg";

import "./StoryDetails.scss";

const StoryDetails = ({
  categoryName,
  categoryId,
  name,
  artist,
  narrator,
  description,
  isFavorite,
  onFavorite,
  showShare = false,
}: {
  categoryName: string;
  categoryId: string;
  name: string;
  narrator?: string;
  description?: string;
  artist: string;
  isFavorite?: boolean;
  onFavorite?: () => void;
  showShare?: boolean;
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const openShareModal = () => {
    setShareModalOpen(true);
  };
  const closeShareModal = () => {
    setShareModalOpen(false);
  };

  return (
    <div className="details">
      <p className="category">{categoryName}</p>
      <p className="title">{name}</p>
      {narrator && (
        <p className="narrator">
          {NARRATOR_TEXT_BY_CATEGORY_ID[categoryId] || "Told by"} {narrator}
        </p>
      )}
      {description && <p className="description">{description}</p>}
      <p className="artist">
        {categoryId === CATEGORY_IDS.MUSIC || categoryId === CATEGORY_IDS.SOUNDS
          ? `${NARRATOR_TEXT_BY_CATEGORY_ID[categoryId]} ${artist}`
          : `By ${artist}`}
      </p>
      {isFavorite !== undefined && (
        <div
          className={`favorite ${isFavorite ? "active" : ""}`}
          id="add-to-favorites-button"
          onClick={onFavorite}
        >
          <img
            className="favorite-heart"
            src={isFavorite ? favoriteActiveIcon : favoriteInactiveIcon}
            alt={isFavorite ? "favorite active" : "favorite inactive"}
          />
        </div>
      )}
      {showShare && (
        <React.Fragment>
          <div className="btn action filled" onClick={openShareModal}>
            Share
          </div>
          <ShareModal
            isOpen={shareModalOpen}
            title={"Share"}
            url={window.location.href}
            close={closeShareModal}
          />
        </React.Fragment>
      )}
    </div>
  );
};
export default StoryDetails;
