import { Severity } from "@sentry/types";
import { call } from "redux-saga/effects";

import { captureMessage } from "../../../helpers/logger";

export function* requestApiDataOrCache(
  lastServerUpdateTimestamp: number,
  lastCacheUpdateTimestamp: number,
  cachedData: unknown | undefined,
  apiFn: (param?: string) => Promise<unknown>,
  fnPrams?: string
): Generator {
  if (
    lastServerUpdateTimestamp > lastCacheUpdateTimestamp ||
    cachedData === undefined
  ) {
    // call API
    try {
      cachedData = yield call(apiFn, fnPrams);
    } catch (e) {
      captureMessage(e.message, {
        level: Severity.Debug,
        category: "content-api-fetch",
        extra: { code: e.code },
      });
    }
  }
  return cachedData;
}
