import React from "react";
import Modal from "react-modal";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";

import "./ShareModal.scss";

interface Props {
  isOpen: boolean;
  title: string;
  url: string;
  close: () => void;
}

const ShareModal = ({ isOpen, title, url, close }: Props) => {
  Modal.setAppElement("#root");

  const copyUrl = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand("copy", true, url);
    }
    toast.success("Link copied!");
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={close} className="modal-small">
      <h2>{title}</h2>
      <div className="share-container">
        <FacebookShareButton
          children={
            <>
              <FacebookIcon round />
              <p className="share-button-label">Facebook</p>
            </>
          }
          url={url}
        />
        <TwitterShareButton
          children={
            <>
              <TwitterIcon round />
              <p className="share-button-label">Twitter</p>
            </>
          }
          url={url}
        />
        <WhatsappShareButton
          children={
            <>
              <WhatsappIcon round />
              <p className="share-button-label">WhatsApp</p>
            </>
          }
          url={url}
          separator="|"
        />
        <EmailShareButton
          children={
            <>
              <EmailIcon round />
              <p className="share-button-label">Email</p>
            </>
          }
          url={url}
          subject=""
          body=""
          separator="|"
        />
      </div>
      <div className="url-container">
        <p className="url">{url}</p>
        <h4 className="copy-text" onClick={copyUrl}>
          Copy
        </h4>
      </div>
    </Modal>
  );
};

export default ShareModal;
