import React from "react";

import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import { ValidationError } from "shared/helpers/errors";
import {
  ClientDynamoUser,
  ProfileUpdateParameters,
  putCurrentUserSettingsSchema,
} from "shared/helpers/user/types";

import FieldError from "../../../../shared/components/forms/elements/fieldError";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";

interface FormProps {
  editMode: boolean;
  switchMode: () => void;
  userDataUpdate: (u: ProfileUpdateParameters) => Promise<void>;
  userData: ClientDynamoUser;
}

const SettingsTemplate = (
  props: FormikProps<ProfileUpdateParameters> & FormProps
) => {
  const { touched, switchMode, errors, isSubmitting, editMode } = props;
  const cancel = () => {
    props.resetForm();
    switchMode();
  };
  return (
    <Form className="content">
      <h1 className="text-center">Profile</h1>
      <p className="font-bold small">Manage and update personal details</p>
      <div className="form-group with-label ">
        {errors.name && touched.name && <FieldError message={errors.name} />}
        <p className="field-name">First name</p>
        <Field
          id="name"
          name="name"
          type="text"
          className={
            errors.name && touched.name
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={25}
          placeholder="First name"
          disabled={!editMode}
        />
      </div>
      <div className="form-group with-label ">
        {errors.lastName && touched.lastName && (
          <FieldError message={errors.lastName} />
        )}
        <p className="field-name">Last name</p>

        <Field
          id="lastName"
          name="lastName"
          type="text"
          className={
            errors.lastName && touched.lastName
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={25}
          placeholder="Last name"
          disabled={!editMode}
        />
      </div>

      <div className="form-group with-label">
        {errors.email && touched.email && <FieldError message={errors.email} />}
        <p className="field-name with-label ">Email address</p>
        <Field
          id="email"
          name="email"
          type="email"
          autoCapitalize="none"
          className={
            errors.email && touched.email
              ? "form-control email with-error"
              : "form-control email"
          }
          maxLength={128}
          placeholder="Email address"
          disabled={!editMode}
        />
      </div>
      {!editMode && (
        <div className={"form-group with-loading-block"}>
          <div className="actions">
            <button
              className="btn action filled"
              type="button"
              onClick={switchMode}
            >
              Edit
            </button>
          </div>
        </div>
      )}
      {editMode && (
        <div className={"form-group with-loading-block"}>
          {(isSubmitting && <LoadingIndicator />) || (
            <div className="actions">
              <button className="btn action filled" type="submit">
                Save
              </button>
              <button
                className="btn secondary filled"
                type="button"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </Form>
  );
};

const SettingsForm = withFormik({
  displayName: "SettingsForm",
  handleSubmit: (
    values: ProfileUpdateParameters,
    formikBag: FormikBag<FormProps, ProfileUpdateParameters>
  ) => {
    formikBag.props
      .userDataUpdate({
        ...values,
        name: values.name?.trim(),
        lastName: values.lastName?.trim(),
      })
      .then(() => {
        formikBag.setSubmitting(false);
        formikBag.props.switchMode();
      })
      .catch((e) => {
        if (e instanceof ValidationError && e.errors) {
          for (const field of e.errors) {
            formikBag.setFieldError(field.attribute, field.message);
          }
        }
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: (props: FormProps) => ({
    name: props.userData.name.trim(),
    lastName: props.userData.lastName.trim(),
    email: props.userData.email,
    country: props.userData.country,
  }),
  validationSchema: putCurrentUserSettingsSchema,
})(SettingsTemplate);

export default SettingsForm;
