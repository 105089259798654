import React from "react";
import AudioPlayer from "react-h5-audio-player";

import { useElementOnScreen } from "../../../../../../shared/hooks/useElementOnScreen";
import { customIcons } from "../../customIcons";

import "../../AudioPlayer.scss";

interface Props {
  backgroundUrl: string;
  audioUrl: string;
  onPlay: () => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
  onEnded: () => void;
}

const PlaylistAudioPlayer = ({
  backgroundUrl,
  audioUrl,
  onPlay,
  onClickNext,
  onClickPrevious,
  onEnded,
}: Props) => {
  const { containerRef, isVisible } = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  return (
    <div ref={containerRef} className={`player ${isVisible ? "" : "floating"}`}>
      <img
        className="background"
        src={backgroundUrl}
        alt="Moshi background"
        width={500}
      />
      <AudioPlayer
        autoPlay={false}
        showJumpControls={false}
        showFilledVolume
        showSkipControls
        customVolumeControls={undefined}
        customIcons={customIcons}
        src={audioUrl}
        onPlay={onPlay}
        onClickNext={onClickNext}
        onClickPrevious={onClickPrevious}
        onEnded={onEnded}
      />
    </div>
  );
};

export default PlaylistAudioPlayer;
