import {
  BraintreeBillingAddress,
  IBillingContactApple,
} from "../../store/profile/api/braintree";

export function applePayAddressToApiParameters(
  billingContact: IBillingContactApple
): BraintreeBillingAddress {
  return {
    countryCodeAlpha2: billingContact.countryCode,
    countryName: billingContact.country,
    firstName: billingContact.givenName,
    lastName: billingContact.familyName,
    locality: billingContact.locality,
    postalCode: billingContact.postalCode,
    region: billingContact.administrativeArea,
    streetAddress: billingContact.addressLines?.join(" "),
  };
}
