import React, { useEffect, useRef } from "react";

import { CATEGORY_IDS } from "../../../../store/content/constants";

import { SelectedItem } from "./helpers/types";

interface Props {
  filter: SelectedItem;
  onFilterClicked: (filter: SelectedItem) => void;
}

const AppliedFilter = ({ filter, onFilterClicked }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    const animateFlexItemIn = (item: HTMLDivElement) => {
      // Do not animate position if first item
      if (item.previousElementSibling) {
        const previousElementRect =
          item.previousElementSibling.getBoundingClientRect();
        const rect = item.getBoundingClientRect();
        // Do not animate if we wrapped to a new row
        if (previousElementRect.y === rect.y) {
          // set left to move (hidden) element instantly
          item.style.left = `-${rect.width + 10}px`;
          // and translate over transition time
          item.style.transform = `translate(${rect.width + 10}px)`;
        }
      }
      // fade in while animating position
      item.style.opacity = "1";
      item.style.visibility = "visible";
    };

    if (element) {
      animateFlexItemIn(element);
    }

    return () => {
      function animateFlexItemOut(item: HTMLDivElement) {
        // Check if next item needs to be moved
        if (item.nextElementSibling) {
          const nextItem = document.getElementById(item.nextElementSibling.id);
          if (nextItem) {
            // reset the next items position
            nextItem.style.left = "unset";
            // and translate back to this items location
            nextItem.style.transform = "translate(0)";
          }
        }
        // fade out while animating next item's position
        item.style.opacity = "0";
        item.style.visibility = "hidden";
      }

      if (element) {
        animateFlexItemOut(element);
      }
    };
  }, [ref]);

  const onClick = () => {
    // All category is the default. Do not allow user to remove
    if (ref.current?.id === CATEGORY_IDS.ALL) {
      return;
    }
    const eventListener = () => {
      if (ref.current) {
        ref.current.removeEventListener("transitionend", eventListener);
        ref.current.style.visibility = "hidden";
        onFilterClicked(filter);
      }
    };
    if (ref.current) {
      ref.current.style.opacity = "0";
      ref.current.addEventListener("transitionend", eventListener);
    }
  };

  return (
    <div
      className={`filter ${filter.name}`}
      id={filter.name}
      onClick={onClick}
      ref={ref}
    >
      {filter.name}
      {filter.name !== CATEGORY_IDS.ALL && (
        <div className="close-container">
          <span className="close" />
        </div>
      )}
    </div>
  );
};

export default AppliedFilter;
