import { PromotionActionsTypes, PromotionState } from "./types";

export interface PromotionSet {
  type: PromotionActionsTypes.PROMOTION_SET;
  payload: PromotionState;
}

export interface PromotionSelectAlternative {
  type: PromotionActionsTypes.PROMOTION_SELECT;
  payload: boolean;
}

export function promotionSetAction(promotion: PromotionState): PromotionSet {
  return {
    type: PromotionActionsTypes.PROMOTION_SET,
    payload: promotion,
  };
}

export function promotionSelectAlternative(
  selectedAlternativePromotion: boolean
): PromotionSelectAlternative {
  return {
    type: PromotionActionsTypes.PROMOTION_SELECT,
    payload: selectedAlternativePromotion,
  };
}

export type PromotionActions = PromotionSet | PromotionSelectAlternative;
