import { Filter } from "../../../shared/store/pages";
import { ContentState, SchoolPlaylist, StoryData } from "../../store/content";
import { CATEGORY_IDS } from "../../store/content/constants";

import { durationTimes } from "../../components/player/menu/filter/helpers/constants";

function onlyPlaylistCategory(filter: Filter) {
  return (
    filter.categoryFilters.length === 1 &&
    filter.categoryFilters[0] === CATEGORY_IDS.PLAYLISTS
  );
}

export function handleCategoryFilterChangesBasedOnSelection(
  filter: Filter
): Filter {
  if (filter.categoryFilters.length === 0) {
    filter.categoryFilters = [CATEGORY_IDS.ALL];
  }
  if (
    filter.categoryFilters.length > 1 &&
    filter.categoryFilters[0] === CATEGORY_IDS.PLAYLISTS
  ) {
    filter.categoryFilters.splice(
      filter.categoryFilters.indexOf(CATEGORY_IDS.PLAYLISTS),
      1
    );
  }
  return filter;
}

export function filterStoryDataByThemeAndSEL(
  allCategory: {
    readonly id: string;
    readonly name: string;
    readonly storyIds: string[];
  },
  content: ContentState,
  filter: Filter
) {
  return allCategory.storyIds.reduce((accumulator: StoryData[], storyId) => {
    const story = content.data?.storyData.find(
      (item: StoryData) => item.id === storyId
    );
    if (story) {
      if (filter.selFilters.length > 0 && filter.themeFilters.length > 0) {
        if (story.selTag && story.themeName) {
          if (
            filter.selFilters.includes(story.selTag) &&
            filter.themeFilters.includes(story.themeName)
          ) {
            accumulator.push(story);
          }
        }
      } else if (
        filter.selFilters.length > 0 ||
        filter.themeFilters.length > 0
      ) {
        if (story.selTag && filter.selFilters.includes(story.selTag)) {
          accumulator.push(story);
        }
        if (story.themeName && filter.themeFilters.includes(story.themeName)) {
          accumulator.push(story);
        }
      } else {
        accumulator.push(story);
      }
    }

    return accumulator;
  }, []);
}

export function filterStoriesByCategory(
  filter: Filter,
  content: ContentState,
  filteredStoryDataAccumulator: (StoryData | SchoolPlaylist)[]
) {
  if (onlyPlaylistCategory(filter)) {
    return filteredStoryDataAccumulator;
  }

  let filteredContentArray: (StoryData | SchoolPlaylist)[] = [];

  filter.categoryFilters.forEach((categoryId) => {
    const category = content.data?.categoryData.find(
      (categoryData) => categoryData.id === categoryId
    );
    if (category && category.id !== CATEGORY_IDS.PLAYLISTS) {
      filteredContentArray = filteredContentArray.concat(
        filteredStoryDataAccumulator.filter((filteredStoryData) =>
          category.storyIds.includes(filteredStoryData.id)
        ) || []
      );
    }
  });

  return filteredContentArray;
}

export function filterPlaylistsByFilteredStories(
  filteredStoryDataAccumulator: (StoryData | SchoolPlaylist)[],
  content: ContentState,
  filter: Filter
) {
  const filterPlaylistsPredicate = (playlist: SchoolPlaylist) => {
    const filteredStoryIds = filteredStoryDataAccumulator.map(
      (data) => data.id
    );
    return playlist.storyIds.some((storyId) =>
      filteredStoryIds.includes(storyId)
    );
  };

  const filteredPlaylistData = content.data?.schoolPlaylists.filter(
    filterPlaylistsPredicate
  );

  if (filter.categoryFilters.includes(CATEGORY_IDS.PLAYLISTS)) {
    if (filteredPlaylistData) {
      if (filter.categoryFilters.length === 1) {
        filteredStoryDataAccumulator = filteredPlaylistData;
      } else {
        filteredStoryDataAccumulator =
          filteredStoryDataAccumulator.concat(filteredPlaylistData);
      }
    }
  }

  return filteredStoryDataAccumulator;
}

export function filterStoriesOrPlaylistsByDuration(
  filteredStoryDataAccumulator: (StoryData | SchoolPlaylist)[],
  filter: Filter
) {
  let filteredContentArray: (StoryData | SchoolPlaylist)[] = [];

  if (filter.durationFilters.length > 0) {
    filter.durationFilters.forEach((duration) => {
      const { min, max } = durationTimes[duration];
      filteredContentArray = filteredContentArray.concat(
        filteredStoryDataAccumulator.filter(
          (storyOrPlaylist) =>
            storyOrPlaylist.duration >= min &&
            (storyOrPlaylist.duration < max || max === -1)
        )
      );
    });
  } else {
    return filteredStoryDataAccumulator;
  }
  return filteredContentArray;
}
