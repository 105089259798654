import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { Severity } from "@sentry/types";
import { captureMessage } from "clients/helpers/logger";
import qs from "query-string";

import {
  ExpiredPasswordResetCode,
  IAuthChangePassword,
  forgotPasswordSubmit,
} from "shared/store/auth/api";

import { Path } from "../../../helpers/path/constants";
import { PASSWORD_WAS_SET } from "shared/helpers/messages";

import PasswordChangeForm from "shared/components/forms/auth/resetPassword/PasswordChangeForm";

import "../login/Login.scss";

const ConfirmResetPassword = ({ location, history }: RouteComponentProps) => {
  useEffect(() => {
    const query: any = qs.parse(location.search);
    if (!query.user_name || !query.confirmation_code) {
      toast.error("Something went wrong, please try again.");
      captureMessage("Confirmation link malformed", {
        extra: { query },
        level: Severity.Error,
      });
      history.push(Path.LOGIN);
    }
  }, [location, history]);

  const changePassword = async (data: IAuthChangePassword) => {
    try {
      const query: any = qs.parse(location.search);
      await forgotPasswordSubmit({
        password: data.password,
        confirmation_code: query.confirmation_code,
        user_name: query.user_name.replace(" ", "+"),
      });
      toast.success(PASSWORD_WAS_SET);
      history.push(Path.LOGIN);
      return;
    } catch (e) {
      if (e instanceof ExpiredPasswordResetCode) {
        toast.warn(e.message);
        history.push(Path.RESET_PASSWORD);
      } else {
        toast.error(e.message);
        captureMessage(e.message, {
          extra: { query: location.search },
          level: Severity.Error,
        });
        history.push(Path.LOGIN);
      }
      return;
    }
  };

  return (
    <div className="app-body center login-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Enter new password</h1>
        </div>
        <PasswordChangeForm changePassword={changePassword} />
      </div>
    </div>
  );
};

export default ConfirmResetPassword;
