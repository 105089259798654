import store2 from "store2";

import { ClientSubscriptionData } from "../../../shared/helpers/user/types";

const PROMOTION_KEY = "moshi-data-id";
const PROMO_CODE_KEY = "moshi-promo-code";
const PARTNER_KEY = "moshi-partner-name";

export function promotionRemember(promotionId: string) {
  store2.local.set(PROMOTION_KEY, promotionId);
}

export function promotionRecall(): string | undefined {
  const promoSlug = store2.local.get(PROMOTION_KEY);
  if (!promoSlug || promoSlug === "undefined") {
    // yes undefined is string, sigh.
    return;
  }
  return promoSlug;
}

export function promotionForget(): ClientSubscriptionData | null {
  return store2.local.remove(PROMOTION_KEY);
}

export function promoCodeRemember(promoCode: string) {
  store2.local.set(PROMO_CODE_KEY, promoCode);
}

export function promoCodeRecall(): string | undefined {
  const promoCode = store2.local.get(PROMO_CODE_KEY);
  if (!promoCode || promoCode === "undefined") {
    // le sigh, undefined is a string
    return;
  }
  return promoCode;
}

export function promoCodeForget() {
  store2.local.remove(PROMO_CODE_KEY);
}

export function partnerRemember(partnerName: string) {
  store2.local.set(PARTNER_KEY, partnerName);
}

export function partnerRecall(): string | undefined {
  const partnerName = store2.local.get(PARTNER_KEY);
  if (!partnerName || partnerName === "undefined") {
    return;
  }
  return partnerName;
}

export function partnerForget() {
  store2.local.remove(PARTNER_KEY);
}
