declare global {
  interface Window {
    Iterate: (
      method: string,
      params: string | Record<string, string | undefined>
    ) => void;
  }
}

export const identifyUser = (email: string) => {
  const iterate = window.Iterate;

  if (iterate) {
    iterate("identify", { email });
  }
};

export const installSurvey = (surveyId: string, email?: string) => {
  const iterate = window.Iterate;
  if (iterate) {
    if (email) {
      identifyUser(email);
    }
    iterate("install", surveyId);
  }
};
