import React from "react";

import { LessonPlanData, LessonPlanType } from "../../../../../store/content";

import pdfIcon from "../../../../helpers/buttons/images/pdf-icon.svg";
import zipIcon from "../../../../helpers/buttons/images/zip-icon.svg";

import downloadIcon from "../../../images/Icon_Download@1.5x.svg";

import "./PlaylistSchoolResourceCell.scss";

interface Props {
  resource: LessonPlanData;
}

const PlaylistSchoolResourceCell = ({ resource }: Props) => {
  const icon = resource.type === LessonPlanType.ZIP ? zipIcon : pdfIcon;
  return (
    <div className="resource-container">
      <div className="info">
        <ul>
          <li>{resource.type}</li>
          <li>{resource.ageGroup}</li>
        </ul>
      </div>
      <div className="title">{resource.title}</div>
      <img className="icon" src={icon} alt="PDF file" />
      <a
        className="download"
        href={resource.mediaUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="icon" src={downloadIcon} alt={"Download"} />
      </a>
    </div>
  );
};

export default PlaylistSchoolResourceCell;
