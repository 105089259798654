import React from "react";

import "./MarketingItem.scss";

interface Props {
  title: string;
  body: string;
  image: string;
  reverse?: boolean;
}
const MarketingItem = ({ title, body, image, reverse }: Props) => {
  return (
    <div className={`marketing-container ${reverse ? "reverse" : ""}`}>
      <img className="marketing-image" src={image} alt="" />
      <div className="marketing-text-container">
        <h1 className="title">{title}</h1>
        <p className="body">{body}</p>
      </div>
    </div>
  );
};

export default MarketingItem;
