import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ApplicationState } from "../../store";

import { events } from "../../helpers/logger";

import Questions from "./components/questions/Questions";
import Reviews from "./components/reviews/Reviews";
import TryMoshi from "./components/tryMoshi/TryMoshi";

import { usePromotionLabels } from "../../hooks/usePromotionLabels";
import Promotion from "../promotion/Promotion";
import { AllowIndexURIs } from "./data/constants";
import { getQuestionsAndAnswers } from "./data/content";

import "./LandingPage.scss";

interface Props {
  className?: string;
}

const LandingPage = ({ className = "landing-page" }: Props) => {
  const location = useLocation();
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const { labels, showFreeTrial } = usePromotionLabels();

  const [promotionClass, setPromotionClass] = useState("");
  useEffect(() => {
    if (promotion.data) {
      setPromotionClass(`promotion-${promotion.data.promotionSlug}`);
    }
  }, [promotion]);

  const promotionClick = (position: string) => {
    return () => {
      events.promotion.click(promotion, position);
    };
  };

  return (
    <React.Fragment>
      <Helmet>
        {!AllowIndexURIs.includes(location.pathname) && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <div className={"app-body " + className + " " + promotionClass}>
        <Promotion showFreeTrial={showFreeTrial} />
        <TryMoshi
          labels={labels}
          showFreeTrial={showFreeTrial}
          onClick={promotionClick}
        />
        <Reviews
          labels={labels}
          showFreeTrial={showFreeTrial}
          onClick={promotionClick}
        />
        <Questions questions={getQuestionsAndAnswers(showFreeTrial)} />
        <div className="bottom-padding" />
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
