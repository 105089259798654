export enum Path {
  LANDING = "/subscribe",

  REDEEM_CODE_LANDING_LEGACY = "/redeem-code",
  REDEEM_CODE_LANDING = "/redeem",
  REDEEM_CODE_INPUT = "/redeem/input",
  REDEEM_CODE_CONFIRMATION = "/redeem/confirmation",

  PLUSH_CODE_LANDING = "/sleepies",
  PLUSH_CODE_INPUT = "/sleepies/input",
  PLUSH_CODE_PAYMENT = "/sleepies/payment",
  PLUSH_CODE_CONFIRMATION = "/sleepies/confirmation",

  REFERRAL_CODE_LANDING = "/referral",

  PARTNER_CODE_LANDING = "/partners",
  PARTNER_CODE_INPUT = "/partners/input",
  PARTNER_CODE_PAYMENT = "/partners/payment",
  PARTNER_CODE_CONFIRMATION = "/partners/confirmation",
  PARTNER_PLAYLIST = "/partners/:partner/:playlistId",

  ADS_CODE_INPUT = "/ads/input",
  ADS_CODE_PAYMENT = "/ads/payment",
  ADS_CODE_CONFIRMATION = "/ads/confirmation",

  LIBRARY_CODE_LANDING = "/library",
  LIBRARY_CODE_INPUT = "/library/input",
  LIBRARY_CODE_CONFIRMATION = "/library/confirmation",

  TRACKING_CODE_LANDING = "/ads",

  SCHOOL_LANDING = "/educators",
  SCHOOL_SIGNUP = "/educators/signup",
  SCHOOL_CONFIRMATION = "/educators/confirmation",

  PAYMENT = "/payment",
  PAYMENT_CONFIRMATION = "/payment/confirmation",
  PAYMENT_UPDATE = "/payment/update",

  PROFILE = "/profile",
  PROFILE_SUBSCRIPTION = "/profile#subscription",
  SIGNUP = "/sign-up",

  LOGIN = "/login",
  RESET_PASSWORD = "/login/reset-password-form",
  RESET_PASSWORD_SET_NEW = "/login/reset-password",

  ERROR = "/error",
  CONTACT = "/contactus",

  SONG_LIST_SCHOOLS = "/educators/menu",
  SONG_SCHOOLS = "/educators/play",
  PLAYLIST_SCHOOLS = "/educators/playlist",

  ALEXA_PARENTAL_GATE = "/alexa",
  LINK_WITH_SPOTIFY = "/link-with-spotify",

  BEDTIME_STORIES = "/bedtime_stories",
  BEDTIME_STORY = "/bedtime_story",
  BEDTIME = "/bedtime",

  LEGACY_SCHOOL_LANDING = "/schools",
  LEGACY_SCHOOL_SIGNUP = "/schools/signup",

  LEGACY_SONG_LIST_SCHOOLS = "/schools/menu",
  LEGACY_SONG_SCHOOLS = "/schools/play",
  LEGACY_PLAYLIST_SCHOOLS = "/schools/playlist",
}

// non standard promotions that we do not want to reload after navigating away from website.
export const SpecialPromotionsByPath: Record<string, string> = {
  [Path.REDEEM_CODE_LANDING_LEGACY]: "redeem",
  [Path.REDEEM_CODE_LANDING]: "redeem",
  [Path.REFERRAL_CODE_LANDING]: "referral",
  [Path.PLUSH_CODE_LANDING]: "sleepies",
  [Path.SCHOOL_LANDING]: "schools",

  // with trailing forward slash
  [Path.REDEEM_CODE_LANDING_LEGACY + "/"]: "redeem",
  [Path.REDEEM_CODE_LANDING + "/"]: "redeem",
  [Path.REFERRAL_CODE_LANDING + "/"]: "referral",
  [Path.PLUSH_CODE_LANDING + "/"]: "sleepies",
  [Path.SCHOOL_LANDING + "/"]: "schools",
};
