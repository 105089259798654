import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "clients/App";
import { rootReducer, rootSaga } from "clients/store";
import { initClientUser } from "clients/store/profile/helpers";

import configureStore from "./clients/store/configureStore";
import { profileSetAction } from "./clients/store/profile";
import { promotionSetAction } from "./clients/store/promotion";

import { initHook } from "./clients/helpers/hooks";
import logger from "./clients/helpers/logger";

import * as serviceWorker from "./serviceWorker";

// redux-persist is async - export promise for use in logger
export const storePromise = configureStore(rootReducer, rootSaga);

// Then proceed to setup
storePromise.then((store) => {
  /**
   * Setup Hooks
   * - make sure the state is updated if google optimize is late
   */
  initHook("promotion-set", async () => {
    const { promotion } = store.getState();
    store.dispatch(promotionSetAction(promotion));
  });
  initHook("profile-set", async () => {
    const { profile } = store.getState();
    store.dispatch(profileSetAction(profile));
  });

  /**
   * Amplify now fails on some loads, particularly redirects.
   * A short delay here solves the problem until it gets fixed
   */
  setTimeout(() => {
    initClientUser(store).then().catch();
  }, 100);

  /**
   * Start up sentry/analytics
   */
  logger.init();

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root") as HTMLElement
  );
});

// 2019-05-11 Marko Zabreznik
// We're not using service workers since the app is interlaced with another CMS
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
