import React, { useEffect, useState } from "react";

import { StoryData } from "../../../../../store/content";

import { millisecondsToFormattedText } from "shared/helpers/time";

import PlaylistTrackCell from "./PlaylistTrackCell";

import "./PlaylistTracksList.scss";

interface Props {
  storyData: StoryData[];
  currentTrack: number;
  baseUrl: string;
  onTrackSelected: (storyId: string) => void;
  favorites?: string[];
  onFavoriteToggle?: (storyId: string) => void;
}

const PlaylistTracksList = ({
  storyData,
  currentTrack,
  baseUrl,
  onTrackSelected,
  favorites,
  onFavoriteToggle,
}: Props) => {
  const [duration, setDuration] = useState("0 hr 0 min");
  useEffect(() => {
    let durationAccumulator = 0;
    storyData.forEach((story) => {
      durationAccumulator += story.duration;
    });
    setDuration(millisecondsToFormattedText(durationAccumulator));
  }, [storyData]);

  return (
    <div className="track-list">
      <ul className="playlist-info">
        <li className="playlist-info-item">{storyData.length} tracks</li>
        <li className="playlist-info-item">{duration}</li>
      </ul>
      {storyData.map((story, i) => {
        const isCurrentlyPlaying = currentTrack === i;
        const isFavorite = Array.from(favorites || []).includes(story.id);

        const drawTopDivider = () =>
          currentTrack !== 0 &&
          !isCurrentlyPlaying &&
          i !== currentTrack + 1 &&
          i < currentTrack + 1;

        const drawBottomDivider = () =>
          !isCurrentlyPlaying && i !== currentTrack - 1 && !drawTopDivider();

        return (
          <React.Fragment key={i}>
            {drawTopDivider() && <span key={`${i}top`} className="separator" />}

            <PlaylistTrackCell
              key={`${story.id} ${i}`}
              storyData={story}
              isFavorite={isFavorite}
              baseUrl={baseUrl}
              isCurrentlyPlaying={isCurrentlyPlaying}
              onSelect={onTrackSelected}
              onFavorite={onFavoriteToggle}
            />

            {drawBottomDivider() && (
              <span key={`${i}bottom`} className="separator" />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default PlaylistTracksList;
