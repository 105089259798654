import _ from "lodash";
import { Reducer } from "redux";

import { ContentActions } from "./actions";
import { ContentActionTypes, ContentApiDataState, ContentState } from "./types";

const reducer: Reducer<ContentState, ContentActions> = (state = {}, action) => {
  if (action.type === ContentActionTypes.CONTENT_SET) {
    // Copy payload object, and remove readonly protection
    const payload = JSON.parse(JSON.stringify(action.payload));
    if (
      payload.data &&
      payload.data.favorites.length !== state.data?.favorites.length &&
      !_.isEqual(payload.data?.favorites, state.data?.favorites)
    ) {
      const favoriteCategory = action.payload.data?.categoryData.find(
        (category) => category.id === "favorites"
      );

      // write new favorites story ids array into the favorites category object
      payload.data.categoryData = action.payload.data?.categoryData.map(
        (category) => {
          if (category.id === "favorites") {
            return {
              ...favoriteCategory,
              storyIds: action.payload.data?.favorites,
            };
          }
          return category;
        }
      );
    }

    return { ...state, ...payload };
  } else {
    return state;
  }
};

const apiDataReducer: Reducer<ContentApiDataState, ContentActions> = (
  state = {},
  action
) => {
  if (action.type === ContentActionTypes.CONTENT_API_SET) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};
export { reducer as contentReducer, apiDataReducer as contentApiDataReducer };
