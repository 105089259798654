/**
 * See docs/Analytics.md
 *
 * These are helper functions that allow Google Optimize to hook into the data
 *  stream and edit html.
 *
 * Want multiple functions on the same hook?
 *  Add a .suffix and change the hook function to loop the array.
 *  Keep in mind that registerHook might be called multiple times.
 *
 */
import { captureBreadcrumb } from "./logger";

declare global {
  interface Window {
    hooks: any;
  }
}

const hooks: { [index: string]: (input: any) => any } = {};
const initHooks: { [index: string]: (input: any) => any } = {};

export function initHook(name: string, func: (input: any) => any) {
  initHooks[name] = func;
  // eslint-disable-next-line no-prototype-builtins
  if (hooks.hasOwnProperty(name) && typeof hooks[name] === "function") {
    // if the hook is already there, call it right away
    func(hooks[name]);
  }
}

function setHook(name: string, func: (input: any) => any) {
  captureBreadcrumb({
    message: `Setting hook ${name}`,
    category: `hooks.${name}`,
  });
  hooks[name] = func;

  // editing hooks is tricky, you need to make sure you aren't triggering these
  //  on re-render as it will end up in a endless loop
  // eslint-disable-next-line no-prototype-builtins
  if (initHooks.hasOwnProperty(name) && typeof initHooks[name] === "function") {
    // if the hook is already inited, call the init again
    initHooks[name](func);
  }
}

export function hook(name: string, input: any): any {
  // eslint-disable-next-line no-prototype-builtins
  if (hooks.hasOwnProperty(name) && typeof hooks[name] === "function") {
    return hooks[name](input);
  }
  return input;
}

window.hooks = {
  set: setHook,
};
