import Api from "clients/helpers/api";

import { ClientSubscriptionData } from "../../../../shared/helpers/user/types";
import { getTimeZone } from "../helpers";
import { ApiError, ValidationError } from "shared/helpers/errors";

import { ProfileState } from "../types";

/**
 * API: client_api/braintree.types.js
 */
export interface IBraintreeCreateCustomer {
  paymentMethodNonce: string;
  userSub: string;
  withTrial: boolean;
  promotionSlug: string;
  countryCode?: string;
  codeUsed?: string;
  referrer?: string;
  referralUsed?: string;
  billingAddress?: BraintreeBillingAddress;
}

/**
 * API: client_api/braintree.types.js
 */
export interface IBraintreeUpdatePayment {
  paymentMethodNonce: string;
  withTrial?: boolean;
  promotionSlug?: string;
  countryCode?: string;
  codeUsed?: string;
  referrer?: string;
  referralUsed?: string;
  billingAddress?: BraintreeBillingAddress;
}

export interface BraintreeBillingAddress {
  countryCodeAlpha2: string;
  countryName?: string;
  firstName: string;
  lastName: string;
  locality: string;
  postalCode: string;
  region: string;
  streetAddress: string;
}

export interface IBillingContactApple {
  addressLines: string[];
  administrativeArea: string;
  country: string;
  countryCode: string;
  familyName: string;
  givenName: string;
  locality: string;
  postalCode: string;
}

export interface IBraintreeUpdateCancel {
  SubscriptionData: ClientSubscriptionData;
}

export interface IBraintreeUpdateRefund {
  SubscriptionData: ClientSubscriptionData;
}

/**
 * We dont want the user to know what's going on
 */
export class BraintreeGeneralError extends Error {
  constructor() {
    super("Error processing subscription data. Please try again later");
  }
}

function getBraintreeError(code: string | undefined) {
  switch (code) {
    case "braintree-transaction-refund-failed":
      return new Error(
        "Oops! Your payment is still being processed. Please allow 24 hours to process before requesting a refund."
      );
    case "trial-subscription-cannot-refund":
      return new Error("Cannot refund a trial subscription.");
    default:
      return new BraintreeGeneralError();
  }
}
/**
 * Critical server errors are handled,
 *  we catch API errors here and
 */
export function handleBraintreeException(error: Error) {
  console.log(error);
  if ((error as ApiError).code) {
    // a known error happened, lets handle it nicely
    return getBraintreeError((error as ApiError).code);
  }
  return new BraintreeGeneralError();
}

/**
 * Use when the user is not logged in
 */
export async function braintreeCreateCustomer(
  data: IBraintreeCreateCustomer
): Promise<ProfileState> {
  return Api.post("/braintree/create-customer", {
    ...data,
    timeZone: getTimeZone(),
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * Create subscription
 */
export async function braintreeSubscribe(
  data: IBraintreeUpdatePayment
): Promise<ProfileState> {
  return Api.put("/braintree/update-subscription", {
    ...data,
    timeZone: getTimeZone(),
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * Update payment method
 */
export async function braintreeUpdatePayment(
  data: IBraintreeUpdatePayment
): Promise<ProfileState> {
  return Api.put("/braintree/update-subscription", {
    ...data,
    timeZone: getTimeZone(),
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

/**
 * Cancel the subscription
 */
export async function braintreeCancelSubscription(): Promise<IBraintreeUpdateCancel> {
  return Api.put("/braintree/update-subscription/cancel").catch((e) => {
    throw handleBraintreeException(e);
  });
}

export async function braintreeRefundSubscription(): Promise<IBraintreeUpdateRefund> {
  return Api.put("/braintree/update-subscription/refund").catch((e) => {
    throw handleBraintreeException(e);
  });
}
