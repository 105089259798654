import Api from "clients/helpers/api";

import { handleBraintreeException } from "../../../store/profile/api/braintree";

export async function braintreeGetClientToken(
  merchantAccountSlug: string
): Promise<{
  clientToken: string;
}> {
  return Api.get("braintree/get-client-token", {
    merchantAccountSlug,
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}

export async function braintreeGetShouldVerifyWith3DS(
  countryCode: string
): Promise<boolean> {
  return Api.get("/braintree/should-verify", {
    countryCode,
  }).catch((e) => {
    throw handleBraintreeException(e);
  });
}
