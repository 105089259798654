import React, { useState } from "react";

import {
  ClientDynamoUser,
  ISchoolSettingsUpdate,
  ProfileUpdateParameters,
} from "../../../helpers/user/types";

import SchoolSettingsForm from "../../forms/users/SchoolSettingsForm";
import LoadingIndicator from "../../loading/LoadingIndicator";

interface Props {
  userData: ClientDynamoUser;
  updateUserData: (u: ProfileUpdateParameters) => Promise<void>;
}

const SchoolSection = ({ userData, updateUserData }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleUpdateUserWithSchoolParameters = async (
    updateParameters: ISchoolSettingsUpdate
  ) => {
    const profileUpdateParameters = {
      schoolDetails: {
        ...updateParameters,
      },
    };
    try {
      await updateUserData(profileUpdateParameters);
    } catch (e) {
      setEditMode(false);
    }
  };

  return (
    <div className="component-box">
      {!userData ? (
        <LoadingIndicator />
      ) : (
        <SchoolSettingsForm
          editMode={editMode}
          switchMode={toggleEditMode}
          userData={userData}
          userDataUpdate={handleUpdateUserWithSchoolParameters}
        />
      )}
    </div>
  );
};

export default SchoolSection;
