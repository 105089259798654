import React from "react";

import { PlaylistGroup, SchoolPlaylist } from "../../../../store/content";

import PlaylistGroupRow from "../rows/PlaylistGroupRow";

import "./ContentResults.scss";

interface Props {
  playlistGroups: PlaylistGroup[];
  playlistContentData: SchoolPlaylist[];
  onPlaylistSelected: (playlistId: string) => void;
}

const GroupResults = ({
  playlistGroups,
  playlistContentData,
  onPlaylistSelected,
}: Props) => {
  return (
    <React.Fragment>
      {playlistGroups.map((group) => (
        <PlaylistGroupRow
          key={group.id}
          playlistGroup={group}
          playlistData={playlistContentData.filter((playlist) =>
            group.playlistIds.includes(playlist.id)
          )}
          onPlaylistSelected={onPlaylistSelected}
        />
      ))}
    </React.Fragment>
  );
};

export default GroupResults;
