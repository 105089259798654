import { Address } from "paypal-checkout-components";

import { BraintreeBillingAddress } from "../../store/profile/api/braintree";

export function payPalAddressToAPIParameters(
  address?: Address
): BraintreeBillingAddress | undefined {
  if (address) {
    const names = address.recipientName?.split(" ");
    let streetAddress = address.line1;
    if (address.line2) {
      streetAddress += " " + address.line2;
    }
    return {
      countryCodeAlpha2: address.countryCode,
      firstName: names ? names[0] : "",
      lastName: names ? names[names.length - 1] : "",
      locality: address.city,
      postalCode: address.postalCode,
      region: address.state,
      streetAddress: streetAddress,
    };
  }
}
