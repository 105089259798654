export enum RedeemType {
  FREE_ACCESS_CODE = "free-access-code",
  PRODUCT_CODE = "product-code",
  REFERRAL_CODE = "referral-code",
  SCHOOL_CODE = "school-code",
  PARTNER_CODE = "partner-code",
  LIBRARY_CODE = "library-code",
  SUBSCRIPTION_OFFER_CODE = "subscription-offer-code",
}

export enum PromotionSlug {
  REDEEM_CODE = "redeem",
  PLUSH_CODE = "plush",
  LIBRARY_CODE_PREFIX = "library-",
}
