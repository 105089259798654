import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { toast } from "react-toastify";

import { ApplicationState } from "../../store";
import {
  ClientCognitoDevice,
  ClientDynamoUser,
  ProfileUpdateParameters,
} from "../../store/profile";
import * as profileActions from "../../store/profile/actions";
import ProfileApi from "../../store/profile/api/user";

import SchoolSection from "../../../shared/components/users/sections/SchoolSection";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import PrivacyAndNotificationsSection from "shared/components/users/PrivacyAndNotifications";
import "shared/components/users/User.scss";
import DevicesSection from "shared/components/users/sections/DevicesSection";

import AccountManagementSection from "./sections/AccountManagementSection";
import SettingsSection from "./sections/SettingsSection";
import SubscriptionSection from "./sections/SubscriptionSection";

const Profile = ({ history }: RouteChildrenProps) => {
  const dispatch = useDispatch();

  const profile = useSelector((state: ApplicationState) => state.profile);

  useEffect(() => {
    dispatch(profileActions.profileFetchAction());
  }, [dispatch]);

  useEffect(() => {
    if (profile.DeviceList === undefined) {
      dispatch(profileActions.profileDevicesFetchAction());
    }
  }, [dispatch, profile, profile.DeviceList, profile.UserData]);

  const [userData, setUserData] = useState<ClientDynamoUser>();
  const [deviceList, setDeviceList] = useState<ClientCognitoDevice[]>();
  const [isSchoolSubscription, setIsSchoolSubscription] = useState(false);
  useEffect(() => {
    if (profile) {
      if (profile.UserData) {
        setUserData(profile.UserData);
      }

      if (profile.DeviceList) {
        setDeviceList(profile.DeviceList);
      }
      if (profile.SubscriptionData) {
        setIsSchoolSubscription(
          !!profile.SubscriptionData.isSchoolSubscription
        );
      }
    }
  }, [profile, profile.UserData, profile.DeviceList, profile.SubscriptionData]);

  const updateUserData = async (updateParameters: ProfileUpdateParameters) => {
    try {
      const profileState = await ProfileApi.putCurrentUser(updateParameters);
      if (
        updateParameters.email &&
        userData?.email !== updateParameters.email
      ) {
        toast.success("Email changed successfully.");
      }
      dispatch(profileActions.profileSetAction(profileState));
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  };

  const deleteDevice = (deviceId: string) => {
    dispatch(profileActions.profileDeviceDeleteAction(deviceId));
  };

  const redirectToPath = (path: string) => {
    history.push(path);
  };

  if (!userData) {
    return (
      <div className="app-body">
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className="app-body center user-page">
      <SettingsSection userData={userData} updateUserData={updateUserData} />
      <PrivacyAndNotificationsSection
        userData={userData}
        userDataUpdate={updateUserData}
      />
      {isSchoolSubscription && (
        <SchoolSection userData={userData} updateUserData={updateUserData} />
      )}
      <section id="subscription" />
      <SubscriptionSection redirect={redirectToPath} />
      <DevicesSection devices={deviceList} deleteDevice={deleteDevice} />
      {!profile.SubscriptionData?.subscriptionValid && (
        <AccountManagementSection username={userData.username} />
      )}
    </div>
  );
};

export default Profile;
