import React from "react";

import withRenderOnResize from "../../../higherOrderComponents/layout/withRenderOnResize";
import bottomLines from "../../images/lines-bottom.svg";
import topLines from "../../images/lines-top.svg";
import phoneDesktop from "../../images/phone-desktop.svg";
import phoneMobile from "../../images/phone.svg";
import phoneShadows from "../../images/shadows.svg";
import TryMoshiFeatureCards from "./TryMoshiFeatureCards";

import "./TryMoshiPhoneImage.scss";

interface Props {
  shouldUseMobileLayout?: boolean;
}

const TryMoshiPhoneImage = ({ shouldUseMobileLayout }: Props) => {
  return (
    <div className="try-moshi-image-wrapper">
      <TryMoshiFeatureCards />
      <div className="phone-image-container">
        {/*  Reset the position context within the parents margins*/}
        <div style={{ position: "relative" }}>
          <img
            className="try-moshi-sun-lines top"
            src={topLines}
            alt="Sun lines"
          />

          <img
            className="try-moshi-phone-image"
            src={shouldUseMobileLayout ? phoneMobile : phoneDesktop}
            alt="Home screen"
          />
          <img
            className="try-moshi-phone-shadows"
            src={phoneShadows}
            alt="Shadows"
          />
          <img
            className="try-moshi-sun-lines bottom"
            src={bottomLines}
            alt="Sun lines"
          />
        </div>
      </div>
    </div>
  );
};

export default withRenderOnResize(TryMoshiPhoneImage);
