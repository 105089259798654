import React from "react";

import { DateFormat, timeToFormat } from "../../../../helpers/date";
import { ClientCognitoDevice } from "../../../../helpers/user/types";

import binActive from "../../images/bin-active.svg";
import { EmptyDeviceRow, EmptyDeviceRowMobile } from "./EmptyDeviceRow";

export const DeviceRow = ({
  device,
  deleteDeviceCallback,
}: {
  device: ClientCognitoDevice;
  deleteDeviceCallback: (deviceKey: string) => void;
}) => {
  const deviceDelete = () => {
    if (device) {
      deleteDeviceCallback(device.DeviceKey);
    }
  };
  if (!device) {
    return <EmptyDeviceRow />;
  } else {
    return (
      <div className="row">
        <div className="device-group">
          <p className="device">{device.DeviceName}</p>
          <p>
            Last log-in{" "}
            {device.LastAuthenticatedDate
              ? timeToFormat(device.LastAuthenticatedDate, DateFormat.ShortDate)
              : "/"}
          </p>
        </div>
        <img
          className="navigation-action-menu-item active"
          src={binActive}
          alt="Remove"
          onClick={deviceDelete}
        />
      </div>
    );
  }
};

export const DeviceRowMobile = ({
  device,
  deleteDeviceCallback,
}: {
  device: ClientCognitoDevice;
  deleteDeviceCallback: (deviceKey: string) => void;
}) => {
  const deviceDelete = () => {
    if (device) {
      deleteDeviceCallback(device.DeviceKey);
    }
  };

  if (!device) {
    return <EmptyDeviceRowMobile />;
  } else {
    return (
      <div className="row">
        <div className="device-group">
          <p className="device">{device.DeviceName}</p>
          <p>
            {device.LastAuthenticatedDate
              ? timeToFormat(device.LastAuthenticatedDate, DateFormat.ShortDate)
              : "/"}
          </p>
        </div>
        <button
          type="button"
          className="btn action filled"
          onClick={deviceDelete}
        >
          Remove
        </button>
      </div>
    );
  }
};
