import React from "react";

import { BreathingState } from "./types";

import "./BreathingText.scss";

const BreathingText = ({
  characterName,
  state,
}: {
  characterName: string;
  state: BreathingState;
}) => {
  const BreathingTextValues = {
    WELCOME: `Welcome to Moshi Breathing with ${characterName}`,
    BREATHE_IN: "Breathe in ...",
    BREATHE_OUT: "Breathe out ...",
    END: "Well done!",
  };

  return (
    <React.Fragment>
      {state === "WELCOME" && (
        <h2 className="breathing-text">{BreathingTextValues[state]}</h2>
      )}
      {state === "BREATHE_IN" && (
        <h2 className="breathing-text fade-in">{BreathingTextValues[state]}</h2>
      )}
      {state === "BREATHE_OUT" && (
        <h2 className="breathing-text fade-in">{BreathingTextValues[state]}</h2>
      )}
      {state === "END" && (
        <h2 className="breathing-text">{BreathingTextValues[state]}</h2>
      )}
    </React.Fragment>
  );
};

export default BreathingText;
