import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Filter } from "../../../../../shared/store/pages";
import { CATEGORY_IDS } from "../../../../store/content/constants";

import { durationOptions } from "./helpers/constants";
import { FilterTypes, SelectedItem } from "./helpers/types";

import DropDownMenu from "shared/components/controls/dropDown/multipleSelection/DropDownMenu";

import AppliedFilter from "./AppliedFilter";

import "./ContentFilter.scss";

interface Props {
  selTags: string[];
  themes: string[];
  categories: string[];
  onChange: (filter: Filter) => void;
  onClear: () => void;
  preSelected?: Filter;
}

const ContentFilter = forwardRef(
  (
    {
      selTags,
      themes,
      categories,
      onChange,
      onClear,
      preSelected = {
        categoryFilters: [CATEGORY_IDS.PLAYLISTS],
        selFilters: [],
        durationFilters: [],
        themeFilters: [],
      },
    }: Props,
    ref: Ref<{ clearFilters: () => void; clearPlaylistFilter: () => void }>
  ) => {
    const memoizedFilters = useMemo<SelectedItem[]>(() => {
      if (preSelected) {
        const preSelectedCategories = preSelected.categoryFilters.reduce(
          (acc: SelectedItem[], value) => {
            acc.push({ name: value, type: "category" });
            return acc;
          },
          []
        );
        const preSelectedSelTags = preSelected.selFilters.reduce(
          (acc: SelectedItem[], value) => {
            acc.push({ name: value, type: "sel" });
            return acc;
          },
          []
        );
        const preSelectedDurations = preSelected.durationFilters.reduce(
          (acc: SelectedItem[], value) => {
            acc.push({ name: value, type: "duration" });
            return acc;
          },
          []
        );
        const preSelectedThemes = preSelected.themeFilters.reduce(
          (acc: SelectedItem[], value) => {
            acc.push({ name: value, type: "theme" });
            return acc;
          },
          []
        );
        return [
          ...preSelectedCategories,
          ...preSelectedSelTags,
          ...preSelectedDurations,
          ...preSelectedThemes,
        ];
      } else {
        return [{ name: CATEGORY_IDS.PLAYLISTS, type: "category" }];
      }
    }, [preSelected]);

    const [selectedFilters, setSelectedFilters] =
      useState<SelectedItem[]>(memoizedFilters);

    const [categoryFilters, setCategoryFilters] = useState(
      preSelected?.categoryFilters || [CATEGORY_IDS.PLAYLISTS]
    );
    const [selFilters, setSelFilters] = useState<string[]>(
      preSelected?.selFilters || []
    );
    const [durationFilters, setDurationFilters] = useState<string[]>(
      preSelected?.durationFilters || []
    );
    const [themeFilters, setThemeFilters] = useState<string[]>(
      preSelected?.themeFilters || []
    );

    const updateSelectedFilterForSelection = (
      selection: string[],
      filterOptions: string[],
      removePlaylistFilter: boolean
    ) => {
      return selectedFilters.filter((item) => {
        if (item.name === CATEGORY_IDS.PLAYLISTS && removePlaylistFilter) {
          return;
        }
        if (
          selectedFilters.includes(item) &&
          !filterOptions.includes(item.name)
        ) {
          // Item from another filter, keep it.
          return item;
        }
      });
    };

    /**
     * Update filter states from user input
     * @param selection - array of selected ids from a given filter
     * @param type - filter id
     */
    const onFilterUpdated = (selection: string[], type: FilterTypes) => {
      let removePlaylistsFilter = false;
      if (
        selectedFilters.find((filter) => filter.name === CATEGORY_IDS.PLAYLISTS)
      ) {
        if (
          selection.find(
            (filter) =>
              Object.values(CATEGORY_IDS).includes(filter) &&
              selection[0] === CATEGORY_IDS.PLAYLISTS
          )
        ) {
          selection.splice(0, 1);
        }
        if (
          selection.find(
            (filter) => !Object.values(CATEGORY_IDS).includes(filter)
          ) &&
          selectedFilters.length === 1
        ) {
          removePlaylistsFilter = true;
        }
      }

      let newSelectedFilters: SelectedItem[] = [];
      switch (type) {
        case "category":
          // Empty category means show all
          if (selection.length === 0) {
            selection = [CATEGORY_IDS.ALL];
          } else {
            // Remove All category when something else is selected
            const allCategoryIndex = selection.indexOf(CATEGORY_IDS.ALL);
            if (allCategoryIndex > -1) {
              selection.splice(allCategoryIndex, 1);
            }
          }
          setCategoryFilters(selection);
          newSelectedFilters = updateSelectedFilterForSelection(
            selection,
            categories,
            removePlaylistsFilter
          );
          break;
        case "sel":
          setSelFilters(selection);
          newSelectedFilters = updateSelectedFilterForSelection(
            selection,
            selTags,
            removePlaylistsFilter
          );
          break;
        case "duration":
          setDurationFilters(selection);
          newSelectedFilters = updateSelectedFilterForSelection(
            selection,
            durationFilters,
            removePlaylistsFilter
          );
          break;
        case "theme":
          setThemeFilters(selection);
          newSelectedFilters = updateSelectedFilterForSelection(
            selection,
            themes,
            removePlaylistsFilter
          );
          break;
        default:
          break;
      }

      setSelectedFilters([
        ...newSelectedFilters,
        ...selection.reduce((acc: SelectedItem[], value) => {
          acc.push({ name: value, type });
          return acc;
        }, []),
      ]);
    };

    /**
     * update menu with new filter object when any individual filter updates
     */
    useEffect(() => {
      const newCategoryFilters = selectedFilters.reduce(
        (accumulator: string[], selectedItem) => {
          if (selectedItem.type === "category") {
            accumulator.push(selectedItem.name);
          }
          return accumulator;
        },
        []
      );
      setCategoryFilters(newCategoryFilters);

      onChange({
        categoryFilters: newCategoryFilters,
        selFilters,
        durationFilters,
        themeFilters,
      });
    }, [onChange, selectedFilters, selFilters, durationFilters, themeFilters]);

    const removeFilterFromFiltersArray = (
      filter: SelectedItem,
      filterDataCopy: string[]
    ) => {
      filterDataCopy.splice(filterDataCopy.indexOf(filter.name), 1);
      return filterDataCopy;
    };

    const onRemoveFilter = (filter: SelectedItem) => {
      switch (filter.type) {
        case "category":
          onFilterUpdated(
            removeFilterFromFiltersArray(filter, [...categoryFilters]),
            filter.type
          );
          break;
        case "sel":
          onFilterUpdated(
            removeFilterFromFiltersArray(filter, [...selFilters]),
            filter.type
          );
          break;
        case "duration":
          onFilterUpdated(
            removeFilterFromFiltersArray(filter, [...durationFilters]),
            filter.type
          );
          break;
        case "theme":
          onFilterUpdated(
            removeFilterFromFiltersArray(filter, [...themeFilters]),
            filter.type
          );
          break;
        default:
          break;
      }
    };

    const onClearFilters = () => {
      setSelectedFilters([{ name: CATEGORY_IDS.ALL, type: "category" }]);
      setCategoryFilters([]);
      setSelFilters([]);
      setDurationFilters([]);
      setThemeFilters([]);
      onClear();
    };

    const clearFilters = () => {
      onClearFilters();
    };

    const clearPlaylistFilter = () => {
      const newSelectedFilters = selectedFilters.filter(
        (filter) => filter.name !== CATEGORY_IDS.PLAYLISTS
      );
      if (newSelectedFilters.length === 0) {
        setSelectedFilters([{ name: CATEGORY_IDS.ALL, type: "category" }]);
      } else {
        setSelectedFilters(newSelectedFilters);
      }
    };

    useImperativeHandle(ref, () => {
      return { clearFilters, clearPlaylistFilter };
    });

    const categoryOptions = categories.map((category) => ({
      label: category,
      id: category,
    }));
    const selTagOptions = Array.from(selTags).map((selTag) => ({
      label: selTag,
      id: selTag,
    }));
    const themeOptions = Array.from(themes).map((theme) => ({
      label: theme,
      id: theme,
    }));
    const container = useRef<HTMLDivElement>(null);

    return (
      <div className="content-filter">
        <div className="filter-selection">
          <DropDownMenu
            title={"Type"}
            options={categoryOptions.filter(
              (category) => category.id !== CATEGORY_IDS.ALL
            )}
            onSelect={
              onFilterUpdated as (selection: string[], type?: string) => void
            }
            selectionType={"category"}
            selectedOptions={categoryFilters}
          />
          <DropDownMenu
            title={"SEL Category"}
            options={selTagOptions}
            onSelect={
              onFilterUpdated as (selection: string[], type?: string) => void
            }
            selectionType={"sel"}
            selectedOptions={selFilters}
          />
          <DropDownMenu
            title={"Duration"}
            options={durationOptions}
            onSelect={
              onFilterUpdated as (selection: string[], type?: string) => void
            }
            selectionType={"duration"}
            selectedOptions={durationFilters}
          />
          <DropDownMenu
            title={"Theme"}
            options={themeOptions}
            onSelect={
              onFilterUpdated as (selection: string[], type?: string) => void
            }
            selectionType={"theme"}
            selectedOptions={themeFilters}
          />
        </div>
        <div className="applied-filters" ref={container}>
          {categoryFilters.length === 0 && (
            <AppliedFilter
              key={"all"}
              filter={{ type: "category", name: CATEGORY_IDS.ALL }}
              onFilterClicked={onRemoveFilter}
            />
          )}
          {selectedFilters.map((filter) => (
            <AppliedFilter
              key={filter.name}
              filter={filter}
              onFilterClicked={onRemoveFilter}
            />
          ))}
          {selectedFilters.length > 0 && (
            <div className="clear-all" onClick={onClearFilters}>
              clear all
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ContentFilter;
