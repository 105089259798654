import { StoryData } from "../types/clients";

export function loadSelTagsFromStories(storyData: StoryData[]): string[] {
  const selTags = new Set<string>();

  for (const story of storyData) {
    if (story.selTag) {
      selTags.add(story.selTag);
    }
  }
  return Array.from(selTags);
}
