import { toast } from "react-toastify";

import { captureBreadcrumb, captureException } from "clients/helpers/logger";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { AuthActionsTypes } from "shared/store/auth";

import { ResourceGoneError } from "../../../shared/helpers/errors";
import { clearUserCacheData } from "../../helpers/cache/cache";
import { getUserProfile } from "../../helpers/userProfiles/api";

import { getAuthFromStore } from "../auth/selectors";
import { contentApiDataSetAction } from "../content";
import { versionFetchAction } from "../version";
import {
  ProfileDeviceDelete,
  ProfileUpdate,
  profileDevicesFetchAction,
  profileFetchAction,
  profileSetAction,
} from "./actions";
import { deleteDevice, listDevices } from "./api/devices";
import ProfileApi from "./api/user";
import { getProfileFromStore } from "./selectors";
import { ProfileActionsTypes as actions } from "./types";

function* profileFetch() {
  yield put(profileSetAction({ isLoading: true }));
  try {
    const profileData = yield call(ProfileApi.getCurrentUser);
    try {
      if (profileData.UserData.schoolUserProfileId) {
        // get userProfile and store
        const userProfileData = yield call(
          getUserProfile,
          profileData.UserData.schoolUserProfileId
        );
        yield put(
          profileSetAction({
            isLoading: false,
            ...profileData,
            UserProfile: userProfileData,
            lastUpdateMillis: Date.now(),
          })
        );
      } else {
        // not a school user, store UserData
        yield put(
          profileSetAction({
            isLoading: false,
            ...profileData,
            lastUpdateMillis: Date.now(),
          })
        );
      }
    } catch (e) {
      // school user profile doesn't exist, revert to just UserData
      if (e instanceof ResourceGoneError) {
        captureException(e, {
          category: "user-profile",
          message: e.message,
          extra: { userProfileId: profileData.schoolUserProfileId },
        });
      }
      profileData.schoolUserProfileId = undefined;
      yield put(
        profileSetAction({
          isLoading: false,
          ...profileData,
          lastUpdateMillis: Date.now(),
        })
      );
    }
  } catch (e) {
    yield put(profileSetAction({ isLoading: false }));
  }
}

function* profileUpdate(action: ProfileUpdate) {
  yield put(profileSetAction({ isLoading: true }));

  try {
    const profileData = yield call(ProfileApi.putCurrentUser, action.payload);
    yield put(profileSetAction({ isLoading: false, ...profileData }));
  } catch (e) {
    captureException(e, { category: "profile-update" });
    toast.error("Something went wrong, refresh the page and try again!");
  }
}

function* deviceDelete(action: ProfileDeviceDelete) {
  try {
    yield deleteDevice(action.payload);
    toast.success("Device deleted");
    yield put(profileDevicesFetchAction());
  } catch (e) {
    if (!e.code || e.code !== "ResourceNotFoundException") {
      captureException(e, { category: "delete-device" });
    } else {
      captureBreadcrumb({
        category: "delete-device",
        data: {
          exception: e,
        },
      });
    }
    if (e.message) {
      toast.error(e.message);
    } else {
      toast.error(
        "Something went wrong, please try again deleting your device."
      );
    }
  }
}

function* devicesFetch() {
  try {
    const profileState = yield call(listDevices);
    yield put(profileSetAction({ DeviceList: profileState.DeviceList }));
  } catch (e) {
    captureException(e, { category: "fetch-device" });
    yield put(profileSetAction({ DeviceList: undefined }));
  }
}

/**
 * Run when auth updates
 * Fetch Profile if we can
 */
function* onAuthUpdate() {
  const { user, isLoggedIn, isLoaded } = yield select(getAuthFromStore);
  if (user && isLoggedIn) {
    const profile = yield select(getProfileFromStore);
    yield put(versionFetchAction());
    if (
      !profile.profileData ||
      (profile.userSub && profile.userSub !== user.sub)
    ) {
      yield put(profileFetchAction());
    }
  } else if (isLoaded && !isLoggedIn) {
    yield call(clearUserCacheData);

    yield put(
      profileSetAction({
        lastUpdateMillis: Date.now(),
        DeviceList: undefined,
        SubscriptionData: undefined,
        UserProfile: undefined,
        UserData: undefined,
        userSub: undefined,
      })
    );
    // clear Api data that requires user profile
    yield put(
      contentApiDataSetAction({
        data: {
          dynamic: undefined,
          favorites: undefined,
        },
      })
    );
  }
}

export function* profileSagas() {
  yield takeLatest(actions.PROFILE_FETCH, profileFetch);
  yield takeLatest(actions.PROFILE_UPDATE, profileUpdate);
  yield takeLatest(actions.PROFILE_DEVICES_FETCH, devicesFetch);
  yield takeLatest(actions.PROFILE_DEVICE_DELETE, deviceDelete);
  // update profile when auth gets updated
  yield takeLatest(AuthActionsTypes.AUTH_SET, onAuthUpdate);
}
