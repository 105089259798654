import { LabelizedSubscription } from "../../../clients/store/promotion";

import { DateFormat, timeToFormat } from "../date";
import {
  ApprovalStatus,
  ClientSubscriptionData,
  SchoolDetails,
  SubscriptionPlatform,
} from "./types";

/**
 * Figure out if the user can upgrade, so we show him the promo page and the upgrade button
 */
export function canUpgrade(
  subscriptionData: ClientSubscriptionData,
  schoolDetails?: SchoolDetails
) {
  if (!subscriptionData) {
    return true;
  }
  if (
    subscriptionData.isSchoolSubscription &&
    (subscriptionData.subscriptionValid ||
      schoolDetails?.approvalStatus === ApprovalStatus.pending)
  ) {
    return false;
  }
  // show the Upgrade button if subscription is no longer valid
  if (!subscriptionData.subscriptionValid) {
    return true;
  }
  // show the Upgrade button if subscription type is Promo
  return subscriptionData.platform === SubscriptionPlatform.Web;
}

/**
 * Make an object with nicely labeled data for frontend use
 */
export function labelize(
  subscriptionData: ClientSubscriptionData,
  schoolDetails?: SchoolDetails
): LabelizedSubscription {
  let platform: string;
  let promotionDetails = "";
  let storeLabel = null;
  let storeLink = null;
  let showCancel = false;
  let showUpdate = false;
  let renewalLabel = "Renewal date";
  let codeDetails = null;

  if (subscriptionData.isCancelled) {
    renewalLabel = "End date";
  }

  const startTimeDate = subscriptionData.startTimeMillis
    ? timeToFormat(subscriptionData.startTimeMillis, DateFormat.ShortDate)
    : null;
  const startTimeDateTitle = subscriptionData.startTimeMillis
    ? timeToFormat(subscriptionData.startTimeMillis, DateFormat.NiceDateTime)
    : null;
  const renewalTimeDate = subscriptionData.renewalTimeMillis
    ? timeToFormat(subscriptionData.renewalTimeMillis, DateFormat.ShortDate)
    : null;
  const renewalTimeDateTitle = subscriptionData.renewalTimeMillis
    ? timeToFormat(subscriptionData.renewalTimeMillis, DateFormat.NiceDateTime)
    : null;
  switch (subscriptionData.platform) {
    case SubscriptionPlatform.Google:
      platform = "Google";
      storeLabel = "Go to Play Store";
      promotionDetails = subscriptionData.subscriptionId;
      storeLink =
        "https://play.google.com/store/account/subscriptions?package=com.mindcandy.sleepstories";
      if (!subscriptionData.isCancelled) {
        showCancel = true;
      }
      break;
    case SubscriptionPlatform.Apple: {
      const subscriptionIdParts = subscriptionData.subscriptionId.split(".");
      const subscriptionId = subscriptionIdParts.slice(2);
      platform = "Apple";
      storeLabel = "Go to AppStore";
      promotionDetails = subscriptionId.join(".");
      storeLink = "https://apple.co/2Th4vqI";
      if (!subscriptionData.isCancelled) {
        showCancel = true;
      }
      break;
    }
    case SubscriptionPlatform.Amazon:
      promotionDetails = subscriptionData.subscriptionId;
      platform = "Amazon";
      storeLabel = "Go to Amazon";
      storeLink =
        "https://www.amazon.com/gp/mas/your-account/myapps/yoursubscriptions/";
      if (!subscriptionData.isCancelled) {
        showCancel = true;
      }
      break;
    case SubscriptionPlatform.Braintree:
      platform = "Moshi Sleep";
      promotionDetails = subscriptionData.subscriptionId;
      if (!subscriptionData.isCancelled) {
        showCancel = true;
        showUpdate = true;
      }
      break;
    case SubscriptionPlatform.Web:
      platform = "Moshi Sleep";
      promotionDetails = "Promo code";
      codeDetails = subscriptionData.codeRedeemed;
      renewalLabel = "End date:";
      break;
    default:
      // something new ?
      platform = subscriptionData.platform;
  }

  if (subscriptionData.promotionDetails) {
    promotionDetails = subscriptionData.promotionDetails;
  }

  const labels: LabelizedSubscription = {
    subscriptionStatusDetails: subscriptionData.subscriptionStatusDetails,
    codeDetails,

    renewalDetails: subscriptionData.renewalDetails,
    startTimeDate,
    startTimeDateTitle,
    renewalTimeDate,
    renewalTimeDateTitle,
    renewalLabel,

    platform,
    storeLabel,
    storeLink,

    paymentDetails: subscriptionData.paymentDetails,
    promotionDetails,

    showUpgrade: canUpgrade(subscriptionData, schoolDetails),
    showCancel,
    showUpdate,
  };

  // interpolate
  for (const [id, value] of Object.entries(labels)) {
    if (value && typeof value === "string" && value.includes("<")) {
      try {
        labels[id] = value.replace(
          /<([a-zA-Z]+)>/g,
          (_: string, contents: string | number | boolean) => {
            if (
              contents &&
              typeof contents === "string" &&
              contents in labels
            ) {
              if (contents + "Title" in labels) {
                const title = labels[contents + "Title"]?.toString();
                const label = labels[contents]?.toString();

                return `<span title="${title}">${label}</span>`;
              }

              return labels[contents]?.toString() || "N/A";
            }
            return "N/A";
          }
        );
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e, { category: "subscription.messages" });
      }
    }
  }

  return labels;
}

export function hasUsedTrial(
  subscriptionData: ClientSubscriptionData | undefined
): boolean {
  if (subscriptionData) {
    return subscriptionData.trialUsed;
  }
  return false;
}
