export function openDropDown(element: HTMLDivElement) {
  const scrollHeight = element.scrollHeight;
  const elementTransition = element.style.transition;
  element.style.transition = "";
  requestAnimationFrame(() => {
    element.style.height = scrollHeight + "px";
    element.style.transition = elementTransition;
  });
  requestAnimationFrame(() => {
    element.style.height = "0";
  });
}

export function closeDropDown(element: HTMLDivElement) {
  const eventListener = () => {
    element.removeEventListener("transitionend", eventListener);
    delete element.style.height;
  };
  const scrollHeight = element.scrollHeight;
  element.style.height = scrollHeight + "px";
  element.addEventListener("transitionend", eventListener);
}
