export interface CheckListContent {
  id: string;
  content: string;
}

export interface TestimonialsContent {
  id: string;
  content: Testimonial;
}

interface Testimonial {
  testimonial: string;
  name: string;
}

export function getWhyTryMoshiContent(
  showFreeTrial: boolean
): CheckListContent[] {
  const content = [
    {
      id: "go-landing-why-try-unlock",
      content: "400+ pieces of safe content that your kids will love.",
    },
    {
      id: "go-landing-why-try-new-content",
      content: "Tracks for sleep, for anxiety, calming down and focusing.",
    },
    {
      id: "go-landing-why-try-download",
      content: "Over 150 characters with new stories weekly.",
    },
    {
      id: "go-landing-why-try-access",
      content: "Emotional wellbeing that will last a lifetime.",
    },
  ];
  if (showFreeTrial) {
    content.push({
      id: "go-landing-why-try-cancel",
      content: "Cancel at anytime before free trials ends.",
    });
  }
  return content;
}

export interface QuestionAndAnswers {
  question: string;
  answers: string[];
  style?: Record<string, unknown>;
}

const questionsAndAnswers: QuestionAndAnswers[] = [
  {
    question: "What does a subscription include?",
    answers: [
      "Over 400 pieces of original audio stories, sleep sounds, music and meditations.",
      "One or more new tracks added every week.",
      "Relaxing audio stories that follow the natural pattern of sleep to help kids gently drift off.",
      "Guided meditations and deep breathing exercises designed to help children relax, reduce anxiety, and manage negative thoughts.",
      "Access to all  content across 3 devices.",
      "Personalised playlists or Moshi playlists for multiple family members and occasions.",
    ],
    style: { display: "list-item" },
  },
  {
    question: "Is Moshi made for a specific age?",
    answers: [
      "Moshi’s content is suited for children of all ages, from 0-10+.",
      "Moshi Sounds features white and colour noise tracks perfect for babies.",
      "Infants and toddlers are better equipped to calm down from tantrums, deal with big transitions, and learn critical social-emotional skills by listening to Moshi Moments, Music, and Stories.",
      "Older children love falling asleep to Moshi Sleep Meditations or doing homework to focus-inducing Moshi Music.",
      "It even helps some parents drift off to sleep!",
    ],
  },
  {
    question: "When should I listen to Moshi?",
    answers: [
      "Moshi is a safe space for you and your child to explore challenging moments. Whether it’s trouble sleeping, a first day of school or simply creating 5 minutes of calm, Moshi will be there alongside you.",
      "You can use Moshi at any time of the day, from meditations or music in the mornings to Moshi stories for your bedtime routine.",
      "Bite-sized meditations and breathing exercises will help your children relax and calm down whenever they need it the most.",
    ],
  },
  {
    question: "How often is content updated?",
    answers: [
      "We publish at least one new original track on a weekly basis, This could be a story, music, sound or meditation.",
      "We also publish 3 new additional stories once a month under the ‘Series’ section. ",
    ],
  },
];

export function getQuestionsAndAnswers(
  showFreeTrial: boolean
): QuestionAndAnswers[] {
  if (showFreeTrial) {
    return [
      ...questionsAndAnswers,
      {
        question: "Can I try the app before I subscribe?",
        answers: [
          "Click the link above and sign up to start a 7-day free trial to access all of our stories, meditations, music and sounds.",
          "Cancel at anytime,  at least 24 hours before the end of the trial.",
        ],
      },
    ];
  } else {
    return questionsAndAnswers;
  }
}

export function getSpotifyQuestionsAndAnswers(): QuestionAndAnswers[] {
  return [
    {
      question: "How does the Spotify content and the Moshi app differ?",
      answers: [
        "All the Moshi Stories, Meditations, Sounds and Music will be available via 3 different podcasts on Spotify. However, the Series and Breathing exercises won't be available on Spotify. Search for Moshi podcasts to find them all.",
        "Use the Moshi app to find your Moshi Playlists, favorites tracks and recommendations.",
      ],
      style: { display: "list-item" },
    },
    {
      question: "How do I subscribe to Moshi?",
      answers: [
        "You can subscribe to Moshi via the Moshikids app or on moshikids.com.",
        "Any subscriber can access all Moshi content available on all platforms including Spotify after logging in.",
      ],
      style: { display: "list-item" },
    },
    {
      question: "I am a Moshi subscriber, can I access the content on Spotify?",
      answers: [
        "Yes! Login using your Moshi email and password to link your Moshi subscription to Spotify. If you have any trouble contact us at support@Moshikids.com",
      ],
      style: { display: "list-item" },
    },
    {
      question: "How often is the content updated on Spotify?",
      answers: [
        "All new weekly content will automatically be released on Spotify too in each of the according podcast feed. However Monthly Series will only appear in the app.",
      ],
      style: { display: "list-item" },
    },
  ];
}
