export const durationOptions = [
  { label: "1-5 min", id: "1-5 min" },
  { label: "5-10 min", id: "5-10 min" },
  { label: "10-20 min", id: "10-20 min" },
  { label: "20-30 min", id: "20-30 min" },
  { label: "30+ min", id: "30+ min" },
];

export const durationTimes = {
  [durationOptions[0].id]: { min: 0, max: 300000 },
  [durationOptions[1].id]: { min: 300000, max: 600000 },
  [durationOptions[2].id]: { min: 600000, max: 1200000 },
  [durationOptions[3].id]: { min: 1200000, max: 1800000 },
  [durationOptions[4].id]: { min: 1800000, max: -1 },
};
