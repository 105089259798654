import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { events } from "clients/helpers/logger";
import { ApplicationState } from "clients/store";
import { Playlist, StoryData } from "clients/store/content";
import { CATEGORY_IDS } from "clients/store/content/constants";

import * as contentActions from "../../../../store/content/actions";

import PlaylistDetails from "../playlist/details/PlaylistDetails";
import PlaylistTracksList from "../playlist/list/PlaylistTracksList";
import PlaylistAudioPlayer from "../playlist/player/PlaylistAudioPlayer";

const PartnersMultiPlayer = () => {
  const FALLBACK_BACKGROUND_URL = "/_default/background.jpeg";

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const path = location.pathname.split("/").filter((s: string) => s !== "");
  const partnerName = path[1];
  const playlistId = path[2];

  const content = useSelector((state: ApplicationState) => state.content);

  useEffect(() => {
    dispatch(contentActions.partnerPlaylistsFetchAction(partnerName));
  }, [dispatch, partnerName]);

  const [playlistData, setPlaylistData] = useState<Playlist | undefined>();
  const [baseUrl, setBaseUrl] = useState<string>();
  const [currentTrackUrl, setCurrentTrackUrl] = useState<string>();
  useEffect(() => {
    if (content.partnersPlaylistData) {
      setBaseUrl(`${content.partnersPlaylistData[partnerName].storiesBaseUrl}`);
      if (content.partnersPlaylistData[partnerName]) {
        const playlistDataForPath = content.partnersPlaylistData[
          partnerName
        ].playlistData.find((playlist) => playlist.id === playlistId);
        if (playlistDataForPath) {
          setPlaylistData(playlistDataForPath);
        } else {
          toast.warn("We could not find that playlist");
          history.replace("/");
        }
      }
    }
  }, [content, history, partnerName, playlistId]);

  const [currentTrackNumber, setCurrentTrackNumber] = useState(0);
  useEffect(() => {
    if (playlistData) {
      setCurrentTrackUrl(
        `${baseUrl}/${playlistData.storyIds[currentTrackNumber]}/`
      );
    }
  }, [baseUrl, currentTrackNumber, playlistData]);

  const [storyData, setStoryData] = useState<StoryData[]>();
  useEffect(() => {
    const getSortedStoryDataFromPlaylistStoryIds = (storyIds: string[]) => {
      if (content.partnersPlaylistData) {
        return content.partnersPlaylistData[partnerName].storyData
          .reduce((acc: StoryData[], currentValue) => {
            const found = storyIds.includes(currentValue.id);
            if (found) {
              acc.push(currentValue);
            }
            return acc;
          }, [])
          .sort((a, b) => storyIds.indexOf(a.id) - storyIds.indexOf(b.id));
      }
    };
    if (playlistData) {
      setStoryData(
        getSortedStoryDataFromPlaylistStoryIds(playlistData.storyIds)
      );
    }
  }, [content.partnersPlaylistData, partnerName, playlistData]);

  const onNext = () => {
    if (!playlistData) {
      return;
    }
    if (currentTrackNumber + 1 >= playlistData.storyIds.length) {
      setCurrentTrackNumber(0);
    } else {
      setCurrentTrackNumber(currentTrackNumber + 1);
    }
  };
  const onPrevious = () => {
    if (!playlistData) {
      return;
    }
    if (currentTrackNumber - 1 < 0) {
      setCurrentTrackNumber(playlistData.storyIds.length - 1);
    } else {
      setCurrentTrackNumber(currentTrackNumber - 1);
    }
  };
  const onTrackSelected = (storyId: string) => {
    if (playlistData?.storyIds) {
      const trackNumber = playlistData.storyIds.indexOf(storyId);
      setCurrentTrackNumber(trackNumber);
    }
  };

  /**
   * Analytics
   * TODO: parameters for partners... or remove
   */
  useEffect(() => {
    if (playlistData) {
      events.playlists.selected(playlistData.id);
    }
  }, [playlistData]);

  const onSongPlayed = () => {
    if (storyData) {
      events.song.played(
        storyData[currentTrackNumber],
        CATEGORY_IDS.PLAYLISTS,
        undefined,
        playlistData?.id
      );
    }
  };

  const onSongComplete = () => {
    if (storyData) {
      events.song.completed(
        storyData[currentTrackNumber],
        CATEGORY_IDS.PLAYLISTS,
        undefined,
        playlistData?.id
      );
    }
    onNext();
  };

  return (
    <div className="app-body center player-page" id="player-page">
      <div className="component-box with-header">
        <div className="component-box-header" />
        {playlistData && storyData && baseUrl && (
          <div className="container">
            {storyData && (
              <React.Fragment>
                <PlaylistAudioPlayer
                  backgroundUrl={
                    storyData[currentTrackNumber].backgroundUrl
                      ? currentTrackUrl +
                        storyData[currentTrackNumber].backgroundUrl
                      : content.data?.baseUrl + FALLBACK_BACKGROUND_URL
                  }
                  audioUrl={
                    currentTrackUrl + storyData[currentTrackNumber].audioUrl
                  }
                  onPlay={onSongPlayed}
                  onClickNext={onNext}
                  onClickPrevious={onPrevious}
                  onEnded={onSongComplete}
                />
                <PlaylistDetails
                  name={playlistData.name}
                  description={playlistData.description}
                />
                <PlaylistTracksList
                  storyData={storyData}
                  currentTrack={currentTrackNumber}
                  baseUrl={baseUrl}
                  favorites={[]}
                  onTrackSelected={onTrackSelected}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <div className="story-list" />
    </div>
  );
};

export default PartnersMultiPlayer;
