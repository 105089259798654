import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { events, sendOptimizeActivate } from "clients/helpers/logger";
import { Path } from "clients/helpers/path/constants";
import { ApplicationState } from "clients/store";

import { Labels } from "../../../store/promotion";
import { AuthUser, authSetAction } from "shared/store/auth";
import { loginCognitoUser } from "shared/store/auth/api";
import {
  IAuthSignup,
  IAuthSignupSchool,
  signupCognitoUser,
} from "shared/store/auth/api-signup";

import { computeLabels } from "../../../helpers/promotionLabels";
import { LocationState } from "../../../helpers/types";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";

import SignupLinks from "../../blocks/componentAfter/signupLinks";
import FlowIndicator from "../../flow/FlowIndicator";
import { SchoolSignupForm, SignupForm } from "./forms/SignupForm";

import "./Signup.scss";

const Signup = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const trialUsed = useSelector((state: ApplicationState) => {
    if (state.profile.SubscriptionData) {
      return state.profile.SubscriptionData.trialUsed;
    }
    return false;
  });
  const [labels, setLabels] = useState<Labels>();
  useEffect(() => {
    if (promotion.data) {
      sendOptimizeActivate();
      const combinedLabels = {
        ...promotion.data,
      };
      setLabels(
        computeLabels(
          promotion.data.labels,
          promotion.data.withTrial && !trialUsed,
          combinedLabels
        )
      );
    }
  }, [promotion.data, promotion.partner, promotion.referral, trialUsed]);

  const isSchoolSignup = location.pathname === Path.SCHOOL_SIGNUP;

  const signup = async (signupData: IAuthSignup | IAuthSignupSchool) => {
    try {
      const fromLocation = location.state;
      const { user } = await signupCognitoUser(
        signupData,
        promotion.referral?.referralCode,
        fromLocation === Path.LINK_WITH_SPOTIFY ? "spotify" : undefined
      );
      const cognitoUser = await loginCognitoUser({
        username: (user as unknown as { username: string }).username as string,
        password: signupData.password,
      });
      cognitoUser.getUserAttributes((error, cognitoUserAttributes) => {
        if (error) {
          toast.error(error.message);
        } else {
          const authUserAttributes = cognitoUserAttributes?.reduce(
            (
              accumulator: Record<string, unknown>,
              item: CognitoUserAttribute
            ) => {
              return Object.assign(accumulator, {
                [item.getName()]: item.getValue(),
              });
            },
            {}
          );

          const authUser: AuthUser = {
            username: cognitoUser.getUsername(),
            name: authUserAttributes?.name || "",
            lastName: authUserAttributes?.family_name || "",
            email: authUserAttributes?.email || "",
            sub: authUserAttributes?.sub || "",
            groups: [],
          };

          events.signup.success();
          dispatch(
            authSetAction({
              isLoggedIn: true,
              user: authUser,
              targetLocation: isSchoolSignup
                ? "/schools/confirmation"
                : location?.state?.from,
            })
          );
        }
      });
    } catch (e) {
      toast.error(e.message);
      events.signup.error(e.message);
      throw e;
    }
  };

  if (!promotion.data) {
    return (
      <div className="app-body center">
        <LoadingIndicator />
      </div>
    );
  }
  let stepCount = parseInt(labels?.stepCount || "");
  if (stepCount === undefined || isNaN(stepCount)) {
    stepCount = isSchoolSignup ? 2 : 3;
  }

  return (
    <div className="app-body signup-page center">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">
            {labels?.pageSignupHeader || "Create your account"}
          </h1>
          <FlowIndicator stepName={"Sign up"} step={1} stepCount={stepCount} />
        </div>

        <div className="text-center mb-1 regular font-bold">
          Already have an account?
          <NavLink to={Path.LOGIN} className="nav-link small font-bold">
            Log in
          </NavLink>
        </div>
        {isSchoolSignup ? (
          <SchoolSignupForm authSignup={signup} promotion={promotion} />
        ) : (
          <SignupForm authSignup={signup} promotion={promotion} />
        )}
      </div>
      {isSchoolSignup && (
        <div className="component-after">
          <h3 className="terms">*</h3>

          <p className="text-below">
            We will be in touch if we need additional information to verify that
            you are an educator. You will receive access to Moshi immediately
            upon subscribing and the subscription will end if we aren't able to
            verify your status as an educator.
          </p>
        </div>
      )}
      <SignupLinks />
    </div>
  );
};

export default Signup;
