import { call, put, select, takeLatest } from "redux-saga/effects";

import { AuthMissing } from "../../../shared/store/auth/api";

import { getProfileFromStore } from "../profile/selectors";
import { versionSetAction } from "./actions";
import VersionApi from "./api/api";
import { VersionActionTypes, VersionApiResponse } from "./types";

function* versionFetch() {
  const profile = yield select(getProfileFromStore);
  try {
    const getVersionApiResponse: VersionApiResponse = yield call(
      VersionApi.getVersionAuth,
      profile.Subscription?.purchaseId,
      profile.UserData?.schoolUserProfileId
    );
    yield put(
      versionSetAction({
        isLoading: false,
        data: { ...getVersionApiResponse },
      })
    );
  } catch (e) {
    if (!(e instanceof AuthMissing)) {
      const getVersionApiResponse: VersionApiResponse = yield call(
        VersionApi.getVersion,
        profile.Subscription?.purchaseId,
        profile.UserData?.schoolUserProfileId
      );
      yield put(
        versionSetAction({
          isLoading: false,
          data: { ...getVersionApiResponse },
        })
      );
    } else {
      yield put(
        versionSetAction({
          isLoading: false,
        })
      );
    }
  }
}

export function* versionSagas() {
  yield takeLatest(VersionActionTypes.VERSION_FETCH, versionFetch);
}
