export interface Link {
  label: string;
  href: string;
  target?: "_blank";
}

export const searchLink: Link = {
  label: "Search",
  href: process.env.REACT_APP_WEB_URL + "/?s=",
  target: "_blank",
};

export const companyLinks: Link[] = [
  {
    label: "Careers",
    href: process.env.REACT_APP_WEB_URL + "/careers",
    target: "_blank",
  },
];

export const contentLinks: Link[] = [
  {
    label: "Parenting",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/parenting",
    target: "_blank",
  },
  {
    label: "Sleep",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/sleep",
    target: "_blank",
  },
  {
    label: "Anxiety",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/anxiety",
    target: "_blank",
  },
  {
    label: "Mindfulness",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/mindfulness",
    target: "_blank",
  },
  {
    label: "Health",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/health",
    target: "_blank",
  },
  {
    label: "Autism",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/autism",
    target: "_blank",
  },
  {
    label: "Lesson plans",
    href: process.env.REACT_APP_WEB_URL + "/schools/lesson-plans",
  },
  {
    label: "Educators",
    href: process.env.REACT_APP_WEB_URL + "/educators",
    target: "_blank",
  },
];

export const supportLinks: Link[] = [
  {
    label: "Contact us",
    href: process.env.REACT_APP_WEB_URL + "/contact",
    target: "_blank",
  },
  {
    label: "FAQs",
    href: process.env.REACT_APP_WEB_URL + "/faqs",
    target: "_blank",
  },
  {
    label: "Knowledge base",
    href: process.env.REACT_APP_WEB_URL + "/knowledge-base",
    target: "_blank",
  },
  {
    label: "User stories",
    href: process.env.REACT_APP_WEB_URL + "/articles/category/user-stories/",
    target: "_blank",
  },
  {
    label: "Gift Moshi",
    href: "https://giftup.app/place-order/61b214a3-124b-4620-bab9-27558d37a9db",
    target: "_blank",
  },
  {
    label: "Redeem Code",
    href: process.env.REACT_APP_WEB_URL + "/redeem",
  },
];

export const socialLinks: Link[] = [
  {
    label: "Instagram",
    href: "https://www.instagram.com/playmoshikids",
    target: "_blank",
  },
  {
    label: "YouTube",
    href: "https://www.youtube.com/channel/UCkNtx91j9IKnMTpQqH6BQKA",
    target: "_blank",
  },
  {
    label: "Facebook",
    href: "https://www.facebook.com/PlayMoshiKids",
    target: "_blank",
  },
  {
    label: "TikTok",
    href: "https://www.tiktok.com/@playmoshikids",
    target: "_blank",
  },
  {
    label: "Twitter",
    href: "https://twitter.com/playmoshikids",
    target: "_blank",
  },
  {
    label: "Pinterest",
    href: "https://www.pinterest.co.uk/playmoshiKids/",
    target: "_blank",
  },
];
