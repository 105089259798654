import Api from "clients/helpers/api";

import { VersionApiResponse } from "../types";

export async function getVersion(
  purchaseId?: string,
  userProfileId?: string
): Promise<VersionApiResponse> {
  return Api.get("/version", {
    platformOsVersion: process.env.REACT_APP_CLIENT_VERSION || 2,
    purchaseId: purchaseId,
    userProfileId: userProfileId,
  });
}

export async function getVersionAuth(
  purchaseId?: string,
  userProfileId?: string
): Promise<VersionApiResponse> {
  return Api.get("/version/auth", {
    platformOsVersion: process.env.REACT_APP_CLIENT_VERSION || 2,
    purchaseId: purchaseId,
    userProfileId: userProfileId,
  });
}

export default { getVersion, getVersionAuth };
