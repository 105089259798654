import React, { ComponentType, useState } from "react";

function withConfirmAnimation<T>(
  WrappedComponent: ComponentType<T>,
  step: number = 3
) {
  return (props: T) => {
    const [backgroundColor, setBackgroundColor] = useState<string>("#72d7f2");
    const [componentBackgroundColor, setComponentBackgroundColor] =
      useState<string>("#d1f3fe");
    const [emailBackgroundColor, setEmailBackgroundColor] =
      useState<string>("#72d7f2");
    const [flowIndicatorStep, setFlowIndicatorStep] = useState<number>(
      step - 1
    );

    const loadingComplete = () => {
      setBackgroundColor("#00c8b1");
      setComponentBackgroundColor("#91e7dc");
      setEmailBackgroundColor("#e1fff6");
      setFlowIndicatorStep(step);
    };

    return (
      <React.Fragment>
        <WrappedComponent
          {...{
            ...props,
            backgroundColor,
            componentBackgroundColor,
            emailBackgroundColor,
            flowIndicatorStep,
          }}
          loadingComplete={loadingComplete}
        />
      </React.Fragment>
    );
  };
}

export default withConfirmAnimation;
