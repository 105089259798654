import braintree from "braintree-web";

import logger from "../logger";

interface BraintreeErrorsTypes {
  [key: string]: (e: braintree.BraintreeError) => string;
}
interface ThreeDSecureRejectStatus {
  [code: string]: boolean;
}
interface ThreeDSecureStatusMessages {
  [code: string]: () => string;
}

function extractOriginalError(e: braintree.BraintreeError) {
  let originalError = e.details.originalError;
  if (originalError.details && originalError.details.originalError) {
    originalError = extractOriginalError(originalError);
  }
  return originalError;
}

const maskedErrors = {
  NOT_ACCEPTED_ERROR:
    "Credit card type is not accepted by this merchant account.",
  VALIDATION_ERROR:
    "The data passed in `verifyCard` did not pass validation checks. See details for more info",
  AUTHENTICATION_ERROR: "Error Processing PARes",
};

/* Generic Error Strings */
const IncorrectCardDetails = () => {
  return (
    "Your payment was declined. The card details are incorrect.\n" +
    "Please enter your correct credit card details and try again."
  );
};
const RequestDifferentPaymentMethod = () => {
  return (
    "Your payment could not be verified.\n" +
    "Please try a different payment method or contact your bank to resolve the issue."
  );
};
const PaymentMethodNotSupported = () => {
  return (
    "We're sorry but this method of payment is not supported, Try again with a different credit card.\n" +
    "Please read our FAQ to find out which payment methods are supported or contact us for further help."
  );
};
const NetworkError = () => {
  return "Network error occurred, please try again.";
};

/* Hosted Field Errors */
const HostedFieldsEmpty = () => {
  return "All fields are empty! Please fill out the form.";
};
const HostedFieldsTimeout = () => {
  return "There was a problem loading. Please refresh the page.";
};
const HostedFieldsInvalid = () => {
  return IncorrectCardDetails();
};
const CustomHostedFieldsInvalid = () => {
  return "Please enter card holder name.";
};
const HostedFieldsFailedTokenization = () => {
  return IncorrectCardDetails();
};
const HostedFieldsFailedSecureTokenization = () => {
  return RequestDifferentPaymentMethod();
};
const HostedFieldsTokenizationCvvVerificationFailed = () => {
  return IncorrectCardDetails();
};
const HostedFieldsTokenizationNetworkError = () => {
  return NetworkError();
};

/* PayPal Errors */
const PaypalAccountTokenizationFailed = () => {
  return "Paypal can't validate the payment. Please try a different payment method or contact PayPal to resolve the issue.";
};
const PaypalInvalidPaymentOption = () => {
  return "PayPal can't validate the payment. Please check your PayPal account information and try again.";
};

/* Braintree General Errors */
const BraintreePaymentNotSupported = () => {
  return PaymentMethodNotSupported();
};

/* 3DSecure Errors */
const ThreeDSecureRetryPayment = () => {
  return IncorrectCardDetails();
};
const ThreeDSecureUnavailableForCard = () => {
  return RequestDifferentPaymentMethod();
};
const ThreeDSecureCancelledByUser = () => {
  return "You cancelled secure payment verification. Please try again, or contact your bank if you are having trouble.";
};
const ThreeDSecureValidationError = (e: braintree.BraintreeError) => {
  let messageToDisplay: string;

  const originalError = extractOriginalError(e);

  switch (originalError.error.message) {
    case maskedErrors.NOT_ACCEPTED_ERROR:
      messageToDisplay = PaymentMethodNotSupported();
      break;
    case maskedErrors.VALIDATION_ERROR:
      messageToDisplay = RequestDifferentPaymentMethod();
      break;
    case maskedErrors.AUTHENTICATION_ERROR:
      messageToDisplay = RequestDifferentPaymentMethod();
      break;
    default:
      // unknown masked error type, capture so we can add it.
      logger.captureException(e.details, {
        category: "threeDsecure.error.unhandled",
        extra: { braintreeError: e },
        message: "Caught a threeDsecure error we are not handling",
        showReportDialog: false,
      });
      messageToDisplay = RequestDifferentPaymentMethod();
      break;
  }
  return messageToDisplay;
};
const ThreeDSecureCanceledError = () => {
  return RequestDifferentPaymentMethod();
};
const ThreeDSecureCardinalError = () => {
  return IncorrectCardDetails();
};

export const BraintreeErrors: BraintreeErrorsTypes = {
  HOSTED_FIELDS_FIELDS_EMPTY: HostedFieldsEmpty,
  HOSTED_FIELDS_TIMEOUT: HostedFieldsTimeout,
  HOSTED_FIELDS_FIELDS_INVALID: HostedFieldsInvalid,
  CUSTOM_HOSTED_FIELDS_FIELDS_INVALID: CustomHostedFieldsInvalid,
  HOSTED_FIELDS_FAILED_TOKENIZATION: HostedFieldsFailedTokenization,
  HOSTED_FIELDS_FAILED_SECURE_TOKENIZATION:
    HostedFieldsFailedSecureTokenization,
  HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED:
    HostedFieldsTokenizationCvvVerificationFailed,
  HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR:
    HostedFieldsTokenizationNetworkError,
  PAYPAL_ACCOUNT_TOKENIZATION_FAILED: PaypalAccountTokenizationFailed,
  PAYPAL_INVALID_PAYMENT_OPTION: PaypalInvalidPaymentOption,
  PAYMENT_REQUEST_UNSUPPORTED_PAYMENT_METHOD: BraintreePaymentNotSupported,
  THREEDS_LOOKUP_VALIDATION_ERROR: ThreeDSecureValidationError,
  THREEDS_CARDINAL_SDK_ERROR: ThreeDSecureCardinalError,
  THREEDS_VERIFY_CARD_CANCELED_BY_MERCHANT: ThreeDSecureCanceledError,
};

const threeDSecureRejectAtLookup: ThreeDSecureRejectStatus = {
  authenticate_attempt_successful: false,
  authenticate_successful: false,
  lookup_bypassed: false,
  lookup_not_enrolled: false,
  challenge_required: false,
  // errors we can tell user about before contacting BE
  authenticate_error: true,
  authenticate_failed: true,
  authenticate_signature_verification_failed: true,
  authenticate_unable_to_authenticate: true,
  authentication_unavailable: true,
  lookup_enrolled: true,
  lookup_error: true,
  unsupported_card: true,
  authenticate_rejected: true,
};

const threeDSecureRejectAfterLookup: ThreeDSecureRejectStatus = {
  authenticate_attempt_successful: false,
  authenticate_successful: false,
  lookup_bypassed: false,
  lookup_not_enrolled: false,
  // errors we can tell user about before contacting BE
  challenge_required: true,
  authenticate_error: true,
  authenticate_failed: true,
  authenticate_signature_verification_failed: true,
  authenticate_unable_to_authenticate: true,
  authentication_unavailable: true,
  lookup_enrolled: true,
  lookup_error: true,
  unsupported_card: true,
  authenticate_rejected: true,
};

/**
 * We know that some 3DS status codes can never succeed.
 * this allows us to not make BE calls we know will fail
 * @param statusCode
 */
export const rejectAtLookup = (statusCode: string): boolean => {
  return threeDSecureRejectAtLookup[statusCode] || false;
};

export const rejectAfterLookup = (statusCode: string): boolean => {
  return threeDSecureRejectAfterLookup[statusCode] || false;
};

export const ThreeDSecureErrors: ThreeDSecureStatusMessages = {
  challenge_required: RequestDifferentPaymentMethod,
  authenticate_error: ThreeDSecureRetryPayment,
  authenticate_failed: ThreeDSecureRetryPayment,
  authenticate_signature_verification_failed: ThreeDSecureRetryPayment,
  authenticate_unable_to_authenticate: ThreeDSecureRetryPayment,
  authentication_unavailable: ThreeDSecureUnavailableForCard,
  lookup_enrolled: ThreeDSecureCancelledByUser,
  lookup_error: ThreeDSecureRetryPayment,
  unsupported_card: PaymentMethodNotSupported,
  authenticate_rejected: RequestDifferentPaymentMethod,
};
