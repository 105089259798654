import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicationState } from "../store";
import { Labels } from "../store/promotion";

import { computeLabels } from "../helpers/promotionLabels";

export const usePromotionLabels = () => {
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const trialUsed = useSelector((state: ApplicationState) => {
    if (state.profile.SubscriptionData) {
      return state.profile.SubscriptionData.trialUsed;
    }
    return false;
  });

  const [showFreeTrial, setShowFreeTrial] = useState(true);
  useEffect(() => {
    if (promotion.data) {
      setShowFreeTrial(promotion.data.withTrial && !trialUsed);
    }
  }, [promotion, trialUsed, showFreeTrial]);

  const [labels, setLabels] = useState<Labels>();
  useEffect(() => {
    if (promotion.data) {
      setLabels(
        computeLabels(promotion.data.labels, showFreeTrial, promotion.referral)
      );
    }
  }, [promotion, showFreeTrial]);

  return { labels, showFreeTrial };
};
