export const CATEGORY_IDS = {
  MOMENTS: "moment",
  MEDITATIONS: "meditations",
  STORIES: "stories",
  MUSIC: "music",
  SOUNDS: "sound",
  SERIES: "series",
  BREATHE: "breathe",
  LULLABY: "lullaby",
  SONG: "song",
  // special
  ALL: "all",
  FAVORITES: "favorites",
  PLAYLISTS: "playlists",
};

export const CONTENT_TYPES = {
  MOMENTS: "moment",
  MEDITATIONS: "meditation",
  STORIES: "story",
  MUSIC: "music",
  SOUNDS: "sound",
  SERIES: "series",
  BREATHE: "breathe",
  PLAYLIST: "playlist",
};

export const SCHOOL_CONTENT_TYPES = { SCHOOLS_MOMENT: "schools_moment" };
export const CATEGORY_MAPPINGS = {
  [CATEGORY_IDS.MOMENTS]: SCHOOL_CONTENT_TYPES.SCHOOLS_MOMENT,
};

export const contentAccessErrorCodes = [
  "not-logged-in",
  "school-subscription-expired",
  "not-a-school-subscription",
];

export const NARRATOR_TEXT_BY_CATEGORY_ID = {
  [CATEGORY_IDS.MOMENTS]: "Told by",
  [CATEGORY_IDS.MEDITATIONS]: "Told by",
  [CATEGORY_IDS.STORIES]: "Told by",
  [CATEGORY_IDS.MUSIC]: "Performed by",
  [CATEGORY_IDS.SOUNDS]: " Performed by",
  [CATEGORY_IDS.BREATHE]: "Told by",
  [CATEGORY_IDS.LULLABY]: "Sung by",
  [CATEGORY_IDS.SONG]: "Sung by",
};
