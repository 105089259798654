import React from "react";

interface Props {
  backgroundColor: string;
  textColor: string;
  featureText: string;
}
const TryMoshiFeatureCard = ({
  backgroundColor,
  textColor,
  featureText,
}: Props) => {
  return (
    <div
      className="try-moshi-feature-card"
      style={{ backgroundColor: backgroundColor, color: textColor }}
    >
      <p>{featureText}</p>
    </div>
  );
};

export default TryMoshiFeatureCard;
