import React from "react";

import LandingPage from "./LandingPage";

// redirect signed up users
const SchoolsLanding = () => {
  return <LandingPage />;
};

export default SchoolsLanding;
