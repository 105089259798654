import React, { useEffect, useRef, useState } from "react";

import { closeDropDown, openDropDown } from "../helpers/dropDownHelper";

import { useOutsideAlert } from "../../../../hooks/useOutsideAlert";
import downIcon from "../images/down.svg";
import { DropDownOption } from "../types";
import DropDownItem from "./DropDownItem";

import "../DropDownMenu.scss";

interface Props {
  title: string;
  options: DropDownOption[];
  onSelect: (selection: string[], type?: string) => void;
  selectedOptions?: string[];
  allowClear?: boolean;
  selectionType?: string;
  disabled?: boolean;
}

const DropDownMenu = ({
  title,
  options,
  onSelect,
  selectedOptions,
  selectionType,
  disabled = false,
}: Props) => {
  const [selection, setSelection] = useState<string[]>([]);
  useEffect(() => {
    if (selectedOptions) {
      setSelection(selectedOptions);
    }
  }, [selectedOptions]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const optionsContainer = useRef<HTMLDivElement>(null);
  const toggleMenu = () => {
    if (optionsContainer.current) {
      if (isOpen) {
        openDropDown(optionsContainer.current);
      } else {
        closeDropDown(optionsContainer.current);
      }
    }
    setIsOpen(!isOpen);
  };

  /**
   * Automatically close dropdown if user clicks anywhere outside
   */
  const ref = useRef(null);
  useOutsideAlert(ref, () => {
    if (optionsContainer.current && isOpen) {
      toggleMenu();
    }
  });

  const onChange = (id: string) => {
    if (selection?.includes(id)) {
      const newSelection = selection?.filter((item) => item !== id);
      setSelection(newSelection);
      onSelect(newSelection, selectionType);
    } else {
      const newSelection = [...selection, id];
      setSelection(newSelection);
      onSelect(newSelection, selectionType);
    }
  };

  let dropDownClass = "drop-down";
  if (isOpen) {
    dropDownClass += " open";
  } else if (selection) {
    dropDownClass += " with-selection";
  }

  return (
    <div
      ref={ref}
      className={dropDownClass}
      id={selectionType + "-drop-down-toggle"}
      style={disabled ? { pointerEvents: "none" } : {}}
    >
      <div className="drop-down-title" onClick={toggleMenu}>
        <p className="title-text">{title}</p>
        <img className="drop-down-arrow" src={downIcon} alt="down arrow" />
      </div>
      <div className="options multi" ref={optionsContainer}>
        {options.map((option) => (
          <DropDownItem
            option={option}
            isSelected={selection?.includes(option.id || "") || false}
            onClick={onChange}
            key={option.id || option.value}
          />
        ))}
      </div>
    </div>
  );
};

export default DropDownMenu;
