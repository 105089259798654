import BedtimeImage from "./images/sleep.jpg";
import SummerImage from "./images/summer.jpg";

export const Bedtime = {
  title: "The magical power of a bedtime story",
  body: "All children need the best sleep they can get in order to be the best person they can be. A good night’s sleep is crucial for children’s physical and cognitive development. Stories provide a smooth transition to bedtime, and a critical routine that kids can rely on. A little bit of magic goes a long way when it comes to improving kids’ sleep, so what better place to start than in the enchanting world of Moshi.",
  image: BedtimeImage,
};

export const Summer = {
  title: "How Moshi empowers parents at bedtime",
  body: "The Moshi app aims to provide sleep help to children all around the world so they can lead happier and healthier lives. Our slow, melodic stories will transform bedtime into something enjoyable and exciting for your kid. With over 200 million bedtime stories played globally, we are definitely taking a step in the right direction for kids’ sleep",
  image: SummerImage,
};
