import React from "react";

import { Link } from "../data/links";

interface Props {
  header: string;
  links: Link[];
}
const LinkGroup = ({ header, links }: Props) => {
  return (
    <div className="footer-link-group">
      <h4 className="header">{header}</h4>
      {links.map((link) => (
        <a
          key={link.label}
          className="link"
          href={link.href}
          target={link.target}
        >
          {link.label}
        </a>
      ))}
    </div>
  );
};
export default LinkGroup;
