import { StoryData } from "../types/clients";

export function loadThemesFromStories(storyData: StoryData[]): string[] {
  const themes = new Set<string>();

  for (const story of storyData) {
    if (story.themeName) {
      themes.add(story.themeName);
    }
  }
  return Array.from(themes);
}
