import { PagesActionTypes, PagesState } from "./types";

export interface PagesSet {
  type: PagesActionTypes.PAGES_DATA_SET;
  payload: PagesState;
}

export function pagesSetAction(pages: PagesState): PagesSet {
  return {
    type: PagesActionTypes.PAGES_DATA_SET,
    payload: pages,
  };
}

export type PagesActions = PagesSet;
