import { ContentActionTypes, ContentApiDataState, ContentState } from "./types";

export interface ContentApiDataSet {
  type: ContentActionTypes.CONTENT_API_SET;
  payload: ContentApiDataState;
}

export interface ContentApiDataFetch {
  type: ContentActionTypes.CONTENT_API_FETCH;
}

export interface ContentSet {
  type: ContentActionTypes.CONTENT_SET;
  payload: Partial<ContentState>;
}

export interface ContentFetch {
  type: ContentActionTypes.CONTENT_FETCH;
}

export interface MarketingContentFetch {
  type: ContentActionTypes.MARKETING_CONTENT_FETCH;
}

export interface PartnerPlaylistsFetch {
  type: ContentActionTypes.PARTNER_PLAYLISTS_FETCH;
  partnerName: string;
}

export function contentApiDataSetAction(
  content: ContentApiDataState
): ContentApiDataSet {
  return {
    type: ContentActionTypes.CONTENT_API_SET,
    payload: content,
  };
}

export function contentApiDataFetchAction(): ContentApiDataFetch {
  return {
    type: ContentActionTypes.CONTENT_API_FETCH,
  };
}

export function contentSetAction(content: ContentState): ContentSet {
  return {
    type: ContentActionTypes.CONTENT_SET,
    payload: content,
  };
}

export function contentFetchAction(): ContentFetch {
  return {
    type: ContentActionTypes.CONTENT_FETCH,
  };
}

export function marketingContentFetchAction(): MarketingContentFetch {
  return { type: ContentActionTypes.MARKETING_CONTENT_FETCH };
}

export function partnerPlaylistsFetchAction(
  partnerName: string
): PartnerPlaylistsFetch {
  return {
    type: ContentActionTypes.PARTNER_PLAYLISTS_FETCH,
    partnerName,
  };
}

export type ContentActions =
  | ContentApiDataSet
  | ContentApiDataFetch
  | ContentSet
  | ContentFetch
  | MarketingContentFetch
  | PartnerPlaylistsFetch;
