export const NAME_CANNOT_BE_BLANK = "Name field cannot be blank.";
export const NAME_MUST_BE_LONGER_THAN = "Name must have 2 characters.";
export const NAME_MUST_BE_SHORTER_THAN =
  "Name must be less than 25 characters.";
export const SCHOOL_NAME_CANNOT_BE_BLANK = "School name cannot be blank.";
export const SCHOOL_NAME_MUST_BE_LONGER_THAN =
  "School name must have 3 characters.";
export const SCHOOL_CITY_CANNOT_BE_BLANK = "City cannot be blank.";
export const SCHOOL_TYPE_CANNOT_BE_EMPTY = "Please select a school type.";
export const PASSWORDS_DO_NOT_MATCH = "Passwords do not match.";
export const ENTER_VALID_PASSWORD =
  "Password must be Min. 8 characters and 1 number";

export const ENTER_VALID_EMAIL = "Please enter a valid email address.";

export const EMAIL_OR_PASSWORD_INCORRECT =
  "Your password was incorrect. Please try again or tap 'Forgot password' to reset it.";

export const USER_DELETED =
  "This user account has been deleted. Contact us for help recovering your account";
export const EMAIL_VERIFICATION_RESENT =
  "We've sent you an Email. Please check your inbox and try again.";

export const PASSWORD_RESET_LINK_EXPIRED =
  "Link expired. Please select 'Forgot password' and try again.";

export const PASSWORD_WAS_RESET_FOR_SECURITY =
  "We've reset your password for security reasons. Please set a new one by using 'Forgot Password'.";
export const PASSWORD_WAS_SET = "Your new password is set! Please Log in.";

export const NO_ACCOUNT_MATCHES_DETAILS =
  "We could not find your Moshi account. Please try again.";

export const USERNAME_ALREADY_EXISTS =
  "This email is already taken. Please choose a different one and try again.";

export const ALREADY_SUBSCRIBED =
  "It looks like you are currently subscribed to Moshi.";
export const ALREADY_TAKEN_TRIAL =
  "It looks like you have previously had a Free Trial.</br> If you proceed, your payment may be taken immediately.";
