import { SchoolPlaylist, StoryData } from "../../../../../store/content";

export const isPlaylistData = (data: StoryData | SchoolPlaylist) => {
  return !Object.keys(data).includes("contentType");
};

export const isStoryData = (data: StoryData | SchoolPlaylist) => {
  return Object.keys(data).includes("contentType");
};

export const isAllPlaylistData = (data: (StoryData | SchoolPlaylist)[]) => {
  if (data.length === 0) {
    return false;
  }
  const storyDataFromArray = data.filter((content) => isStoryData(content));
  return storyDataFromArray.length === 0;
};
