import {
  DeepPartial,
  Reducer,
  Store,
  applyMiddleware,
  compose,
  createStore,
} from "redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import getPersistMiddleware from "redux-persist-middleware";
import createSagaMiddleware from "redux-saga";

import { getCache } from "../helpers/cache/cache";
import { actionMap } from "../helpers/cache/constants";

import { ApplicationState } from "./index";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: ({
      trace,
      traceLimit,
    }: {
      trace: boolean;
      traceLimit: number;
    }) => typeof compose;
  }
}

export default function configureStore(
  rootReducer: Reducer<ApplicationState>,
  rootSaga: unknown
): Promise<Store<ApplicationState>> {
  // compose enhancers for redux dev tools
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  // setup saga middleware
  const sagaMiddleware = createSagaMiddleware();

  // setup cache middleware
  const cache = getCache();
  const persistMiddleware = getPersistMiddleware({
    cacheFn: cache.set,
    logger: console.info,
    actionMap,
  });

  const composed =
    process.env.REACT_APP_BRAINTREE_ENVIRONMENT === "production"
      ? applyMiddleware(sagaMiddleware, persistMiddleware)
      : composeEnhancers(applyMiddleware(sagaMiddleware, persistMiddleware));

  return cache
    .getAll()
    .then(async (initialState: DeepPartial<ApplicationState>) => {
      const store = createStore(rootReducer, initialState, composed);
      sagaMiddleware.run(rootSaga as never);
      return store;
    });
}
