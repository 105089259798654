import React from "react";

import { Field } from "formik";

import DropDownMenu from "../../controls/dropDown/DropDownMenu";
import { DropDownOption } from "../../controls/dropDown/types";

interface Props {
  id: string;
  name: string;
  noSelection: string;
  startingValue: string;
  options: DropDownOption[];
  disabled?: boolean;
  reverse?: boolean;
}

const FormDropdown = ({
  id,
  name,
  noSelection,
  startingValue,
  options,
  disabled = false,
  reverse = false,
}: Props) => {
  return (
    <Field id={id} name={name} type="text">
      {({
        field: { value },
        form: { setFieldValue },
      }: {
        field: { value: string };
        form: {
          setFieldValue: (fieldName: string, value: string) => void;
        };
      }) => (
        <DropDownMenu
          noSelectionTitle={noSelection}
          options={options}
          onSelect={(selection) => setFieldValue(name, selection)}
          preSelectedOption={startingValue || value}
          disabled={disabled}
          reverse={reverse}
        />
      )}
    </Field>
  );
};

export default FormDropdown;
