import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { events } from "clients/helpers/logger";
import { Path } from "clients/helpers/path/constants";
import { ApplicationState } from "clients/store";
import { StoryData } from "clients/store/content";
import * as contentActions from "clients/store/content/actions";
import qs from "query-string";

import { putFavorites } from "../../../../store/content/api/api";
import { CONTENT_TYPES } from "../../../../store/content/constants";
import {
  MAX_NUM_SIZE_STORY_ID,
  mapLegacyStoryId,
} from "../../../../store/content/mapping/helpers";
import { getUserProfileFromStore } from "../../../../store/profile/selectors";

import BackButton from "shared/components/controls/BackButton";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";

import withDesktopOnlyView from "../../../higherOrderComponents/layout/withDesktopOnlyView";
import withSchoolOnlyAccess from "../../../higherOrderComponents/schools/withSchoolOnlyAccess";
import BreathingPlayer from "../breathing/BreathingPlayer";
import StoryDetails from "../story/details/StoryDetails";
import StoryAudioPlayer from "../story/player/StoryAudioPlayer";
import LessonPlans from "../story/resources/LessonPlans";

import "../Player.scss";

/**
 * Page containing content players for single piece: story, music, breathing etc.
 */
const SinglePlayer = () => {
  const FALLBACK_BACKGROUND_URL = "/_default/background.jpeg";

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const content = useSelector((state: ApplicationState) => state.content);
  const userProfile = useSelector(getUserProfileFromStore);

  const [storyData, setStoryData] = useState<StoryData>();
  const [baseUrl, setBaseUrl] = useState<string>();

  useEffect(() => {
    const contentNotFound = () => {
      toast.error("We could not find the content you are looking for.");
      history.replace(Path.SONG_LIST_SCHOOLS);
    };

    if (content.data) {
      const storyId = location.pathname.split("/").slice(-1)[0];
      const storyDataForPath = content.data.storyData.find(
        (story) => story.id === storyId
      );
      if (storyDataForPath) {
        setBaseUrl(`${content.data.baseUrl}/${storyId}/`);
        setStoryData(storyDataForPath);
      } else {
        if (storyId.length > MAX_NUM_SIZE_STORY_ID) {
          const mappedStoryId = mapLegacyStoryId(storyId);
          if (mappedStoryId) {
            history.replace(`play/${mappedStoryId}`);
          } else {
            contentNotFound();
          }
        } else {
          contentNotFound();
        }
      }
    }
  }, [content, storyData, baseUrl, location.pathname, history]);

  const isSongInFavoritesList = useCallback(
    (favorites?: string[]) => {
      if (favorites) {
        if (storyData?.id && favorites.includes(storyData.id)) {
          return true;
        }
      } else if (storyData?.id && content.data?.favorites) {
        if (content.data.favorites.includes(storyData.id)) {
          return true;
        }
      }
      return false;
    },
    [content.data?.favorites, storyData?.id]
  );

  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [updatingFavorites, setUpdatingFavorites] = useState<boolean>(false);
  useEffect(() => {
    setIsFavorite(isSongInFavoritesList());
  }, [content, storyData, isSongInFavoritesList]);

  /**
   * Analytics
   */
  useEffect(() => {
    if (storyData) {
      events.song.selected(storyData, storyData.contentType, storyData.selTag);
    }
  }, [storyData]);

  if (!baseUrl || !storyData) {
    return (
      <div className="app-body center player-page" id="player-page">
        <LoadingIndicator />
      </div>
    );
  }

  const onFavorite = async () => {
    if (!content.data) {
      return;
    }
    try {
      if (!updatingFavorites) {
        setUpdatingFavorites(true);
        const favorites = await putFavorites(
          storyData.id,
          userProfile?.userProfileId
        );
        dispatch(
          contentActions.contentSetAction({
            ...content,
            data: {
              ...content.data,
              favorites: favorites.storyIds,
            },
          })
        );
        const favoriteAdded = isSongInFavoritesList(favorites.storyIds);
        setIsFavorite(favoriteAdded);
        setUpdatingFavorites(false);

        if (favoriteAdded) {
          events.favorites.addedToFavorites(storyData);
          toast.success(`${storyData.name} has been added to favorites`);
        } else {
          events.favorites.removedFromFavorites(storyData);
          toast.success(`${storyData.name} has been removed from favorites`);
        }
      }
    } catch (e) {
      toast.error("Failed to add to favorites");
      setUpdatingFavorites(false);
    }
  };

  const sendSongPlayedEvent = () => {
    const query = qs.parse(location.search);
    // yes, qs gives undefined as a string...
    events.song.played(
      storyData,
      query.categoryId as string,
      query.selTagId === "undefined" ? undefined : (query.selTagId as string)
    );
  };
  const sendSongCompleteEvent = () => {
    const query = qs.parse(location.search);
    // yes, qs gives undefined as a string...
    events.song.completed(
      storyData,
      query.categoryId as string,
      query.selTagId === "undefined" ? undefined : (query.selTagId as string)
    );
  };

  return (
    <div className="app-body center player-page" id="player-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <BackButton />
        </div>
        <div className="container">
          {storyData.contentType === CONTENT_TYPES.BREATHE && (
            <BreathingPlayer
              baseUrl={baseUrl}
              storyData={storyData}
              onPlay={sendSongPlayedEvent}
              onEnded={sendSongCompleteEvent}
            />
          )}
          {storyData.contentType !== CONTENT_TYPES.BREATHE && (
            <StoryAudioPlayer
              backgroundUrl={
                storyData.backgroundUrl
                  ? baseUrl + storyData.backgroundUrl
                  : content.data?.baseUrl + FALLBACK_BACKGROUND_URL
              }
              audioUrl={baseUrl + storyData.audioUrl}
              onPlay={sendSongPlayedEvent}
              onEnded={sendSongCompleteEvent}
            />
          )}

          <StoryDetails
            categoryName={storyData.contentTypeName}
            categoryId={storyData.contentType}
            name={storyData.name}
            narrator={storyData.narrator}
            description={storyData.description}
            artist={storyData.artist}
            isFavorite={isFavorite}
            onFavorite={onFavorite}
          />
        </div>

        {storyData.lessons && storyData.lessons.length > 0 && (
          <LessonPlans lessons={storyData.lessons} />
        )}
      </div>
    </div>
  );
};

export default withSchoolOnlyAccess(withDesktopOnlyView(SinglePlayer));
