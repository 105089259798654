import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Numpad from "../../../shared/components/controls/numpad/Numpad";

import AlexaLinks from "../blocks/componentAfter/AlexaLinks";
import withNoFooter from "../higherOrderComponents/layout/withNoFooter";
import withNoHeader from "../higherOrderComponents/layout/withNoHeader";
import {
  ALEXA_ACCOUNT_LINKING_REDIRECT_BASE_URL,
  MAXIMUM_VALID_PARENTAL_GATE_YEAR,
  MINIMUM_ALLOWED_PARENTAL_GATE_YEAR,
} from "./data/constants";
import backspace from "./images/backspace-svgrepo-com.svg";

import "./AlexaLandingPage.scss";

const AlexaLandingPage = () => {
  const location = useLocation();
  const redirect = useRef("");

  useEffect(() => {
    redirect.current =
      ALEXA_ACCOUNT_LINKING_REDIRECT_BASE_URL + location.search;
  }, [location.search]);

  const [input, setInput] = useState<number[]>([]);
  const onInput = (value: number) => {
    if (input.length < 4) {
      setInput([...input, value]);
    }
  };
  const onDelete = () => {
    const newInput = [...input];
    newInput.pop();
    setInput(newInput);
  };
  const submitDate = () => {
    const year = new Date(input.join("")).getFullYear();
    if (
      !year ||
      year < MINIMUM_ALLOWED_PARENTAL_GATE_YEAR ||
      year > MAXIMUM_VALID_PARENTAL_GATE_YEAR
    ) {
      toast.error("Sorry, that year is not valid.");
    } else {
      window.location.href = redirect.current;
    }
  };

  return (
    <div className="app-body center alexa">
      <div className="component-box with-header">
        <div className="component-box-header">
          <h3 className="font-sub-heading">
            Account linking is only available for adults. Please enter the year
            you were born.
          </h3>
        </div>
        <Numpad onInput={onInput} />
        <div className="container">
          <div className="input">
            {input &&
              input.map((value, i) => (
                <div key={i} className="value">
                  <p>{value}</p>
                </div>
              ))}
          </div>
          <img
            width="24px"
            src={backspace}
            alt="backspace"
            onClick={onDelete}
          />
        </div>

        <a className="btn action filled" onClick={submitDate}>
          Continue
        </a>
      </div>
      <AlexaLinks />
    </div>
  );
};

export default withNoHeader(withNoFooter(AlexaLandingPage));
