import React from "react";

import { SchoolPlaylist } from "../../../../store/content";

import { millisecondsToFormattedText } from "../../../../../shared/helpers/time";

interface Props {
  playlistData?: SchoolPlaylist;
  onSelect: (playlistId: string) => void;
}
const PlaylistTile = ({ playlistData, onSelect }: Props) => {
  // Shouldn't happen, but if the group has an id that doesnt exist
  if (!playlistData) {
    return <></>;
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onSelect(playlistData.id);
  };

  const { name, duration, storyIds } = playlistData;

  return (
    <div className="content-cell" onClick={handleClick}>
      <img
        className="cell-image"
        src={playlistData.miniHomeTileUrl}
        alt={name}
      />
      <div className="cell-info">
        <p>Playlist</p>
        <h5>{name}</h5>
        <p>
          {storyIds.length} tracks • {millisecondsToFormattedText(duration)}
        </p>
      </div>
    </div>
  );
};

export default PlaylistTile;
