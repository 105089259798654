import React from "react";

import "./Numpad.scss";

const Numpad = ({ onInput }: { onInput: (value: number) => void }) => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const value = parseInt(event.currentTarget.id);
    onInput(value);
  };

  return (
    <div className="numpad">
      {numbers.map((i) => (
        <button
          key={i}
          id={i.toString()}
          className={`key ${i === 0 ? "zero" : ""}`}
          onClick={onClick}
        >
          <p>{i}</p>
        </button>
      ))}
    </div>
  );
};

export default Numpad;
