import React from "react";

import { useCountdown, zeroPad } from "../../../../helpers/date/countdown";

import "./RefreshTimer.scss";

const RefreshTimer = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const targetTime = tomorrow.setUTCHours(8, 0, 0);
  const [hours, minutes] = useCountdown(targetTime);
  return (
    <h3 className="timer">
      Next story starts in{" "}
      <b>
        {zeroPad(hours)}:{zeroPad(minutes)}
      </b>
    </h3>
  );
};

export default RefreshTimer;
