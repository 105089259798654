import React from "react";

import { Privacy, Terms } from "./Links";

const AlexaLinks = () => {
  return (
    <div className="component-after">
      <p className="small text-center pr-2 pl-2">
        By linking your Moshi account you are agreeing to our
        <br />
        <Terms /> {" & "}
        <Privacy />
      </p>
    </div>
  );
};

export default AlexaLinks;
