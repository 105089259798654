import { Severity } from "@sentry/types";
import { captureException, captureMessage } from "clients/helpers/logger";
import { call, put, select, take, takeLatest } from "redux-saga/effects";

import { requestApiDataOrCache } from "./helpers/apiGenerators";
import { getOrderedContentCategories } from "./helpers/categories";
import { mapFeaturedContent } from "./helpers/featured";
import { loadSelTagsFromStories } from "./helpers/sel";
import { loadThemesFromStories } from "./helpers/themes";

import { getProfileFromStore } from "../profile/selectors";
import { VersionActionTypes } from "../version";
import { getVersionFromStore } from "../version/selectors";
import {
  PartnerPlaylistsFetch,
  contentApiDataSetAction,
  contentSetAction,
} from "./actions";
import ContentApi, {
  getMarketingContent,
  getPartnerPlaylistsData,
} from "./api/api";
import { contentAccessErrorCodes } from "./constants";
import {
  getContentApiDataFromStore,
  getPartnerPlaylistsDataFromStore,
} from "./selectors";
import {
  ContentActionTypes,
  ContentApiDataState,
  ContentState,
  DynamicStoryData,
  SchoolPlaylist,
  StaticStoryData,
  StoryData,
  ContentActionTypes as actions,
} from "./types";

/**
 * Combine static storyData with dynamic storyData
 * @param {StaticStoryData} staticStoryData
 * @param {DynamicStoryData} dynamicStoryData
 * @returns {StoryData[]}
 */
function combineContentApiStoryData(
  staticStoryData: StaticStoryData[],
  dynamicStoryData: DynamicStoryData[]
) {
  return dynamicStoryData.reduce((accumulator: StoryData[], currentValue) => {
    const storyData = staticStoryData.find(
      (item) => item.id === currentValue.id
    );
    if (storyData) {
      const clientStoryData = { ...storyData, ...currentValue };
      accumulator.push(clientStoryData);
    }
    return accumulator;
  }, []);
}

function* contentFetch() {
  yield put(contentSetAction({ isLoading: false }));
  try {
    const contentApiDataState: ContentApiDataState = yield select(
      getContentApiDataFromStore
    );
    if (contentApiDataState.data) {
      const {
        static: staticContentApiData,
        dynamic: dynamicContentApiData,
        favorites: favoritesContentApiData,
        schoolPlaylists: schoolPlaylistsContentApiData,
      } = contentApiDataState.data;

      if (
        staticContentApiData &&
        dynamicContentApiData &&
        favoritesContentApiData &&
        schoolPlaylistsContentApiData
      ) {
        const storyData = combineContentApiStoryData(
          staticContentApiData.storyData,
          dynamicContentApiData.storyData
        );

        const categoryData = getOrderedContentCategories(
          staticContentApiData,
          dynamicContentApiData,
          storyData,
          schoolPlaylistsContentApiData.schoolsPlaylists
        );

        let featuredContentData: (StoryData | SchoolPlaylist)[] = [];
        if (dynamicContentApiData.featuredContentData) {
          featuredContentData = mapFeaturedContent(
            dynamicContentApiData.featuredContentData,
            storyData,
            schoolPlaylistsContentApiData.schoolsPlaylists
          );
        }

        const selTags = loadSelTagsFromStories(storyData);
        const themes = loadThemesFromStories(storyData);

        const data: ContentState = {
          data: {
            storyData,
            categoryData,
            featuredContentData,
            selTags,
            themes,
            baseUrl: staticContentApiData.storiesBaseUrl,
            homeTileData: dynamicContentApiData.homeTileData,
            seriesData: staticContentApiData.seriesData,
            schoolPlaylists: schoolPlaylistsContentApiData.schoolsPlaylists,
            playlistGroups: schoolPlaylistsContentApiData.playlistGroups,
            favorites: Array.from(new Set(favoritesContentApiData.storyIds)),
          },
        };

        yield put(
          contentSetAction({
            isLoading: false,
            ...data,
            lastUpdatedMillis: Date.now(),
          })
        );
      } else {
        captureMessage("No api data in store.", {
          level: Severity.Debug,
          category: "content-fetch",
        });
        yield put(contentSetAction({ isLoading: false }));
      }
    } else {
      captureMessage("No api state in store.", {
        level: Severity.Debug,
        category: "content-fetch",
      });
      yield put(contentSetAction({ isLoading: false }));
    }
  } catch (e) {
    if (contentAccessErrorCodes.includes(e.code)) {
      captureMessage(e.message, {
        level: Severity.Debug,
        category: "content-fetch",
        extra: { code: e.code },
      });
    } else {
      captureException(e, {
        extra: { code: e.code },
        category: "content-fetch",
      });
    }
  }
}

function* contentApiDataFetch() {
  try {
    yield put(contentApiDataSetAction({ isLoading: true }));
    // Select version from store
    let versionState = yield select(getVersionFromStore);
    if (!versionState.data) {
      // If version has not been set, wait for action to complete
      const versionSetTask = yield take(VersionActionTypes.VERSION_SET);
      versionState = versionSetTask.payload;
    }
    const { data: versionData } = versionState;
    const { UserData } = yield select(getProfileFromStore);
    const { schoolUserProfileId } = UserData;
    const cachedContentApiState = yield select(getContentApiDataFromStore);
    if (!cachedContentApiState.data) {
      cachedContentApiState.data = {};
    }
    let {
      data: {
        staticContentApiData = undefined,
        dynamicContentApiData = undefined,
        favoritesContentApiData = undefined,
        schoolPlaylistsContentApiData = undefined,
      },
    } = cachedContentApiState;
    const {
      lastUpdatedDynamicContentMillis = 0,
      lastUpdatedStaticContentMillis = 0,
      lastUpdatedFavoritesMillis = 0,
      lastUpdatedSchoolPlaylistsMillis = 0,
    } = cachedContentApiState;

    staticContentApiData = yield call(
      requestApiDataOrCache,
      versionData.lastUpdatedStaticContentMillis,
      lastUpdatedStaticContentMillis,
      staticContentApiData,
      ContentApi.getContentStatic
    );
    dynamicContentApiData = yield call(
      requestApiDataOrCache,
      versionData.lastUpdatedDynamicContentMillis,
      lastUpdatedDynamicContentMillis,
      dynamicContentApiData,
      ContentApi.getContentDynamic,
      schoolUserProfileId
    );

    favoritesContentApiData = yield call(
      requestApiDataOrCache,
      versionData.lastUpdatedFavoritesMillis,
      lastUpdatedFavoritesMillis,
      favoritesContentApiData,
      ContentApi.getFavorites,
      schoolUserProfileId
    );

    schoolPlaylistsContentApiData = yield call(
      requestApiDataOrCache,
      versionData.lastUpdatedSchoolPlaylistsMillis,
      lastUpdatedSchoolPlaylistsMillis,
      schoolPlaylistsContentApiData,
      ContentApi.getSchoolPlaylists
    );

    yield put(
      contentApiDataSetAction({
        isLoading: false,
        lastUpdatedStaticContentMillis: Date.now(),
        lastUpdatedDynamicContentMillis: Date.now(),
        lastUpdatedFavoritesMillis: Date.now(),
        lastUpdatedSchoolPlaylistsMillis: Date.now(),
        data: {
          static: staticContentApiData,
          dynamic: dynamicContentApiData,
          favorites: favoritesContentApiData,
          schoolPlaylists: schoolPlaylistsContentApiData,
        },
      })
    );

    yield call(contentFetch);
    yield take(ContentActionTypes.CONTENT_FETCH);
  } catch (e) {
    if (contentAccessErrorCodes.includes(e.code)) {
      captureMessage(e.message, {
        level: Severity.Debug,
        category: "content-api-fetch",
        extra: { code: e.code },
      });
    } else {
      captureException(e, {
        extra: { code: e.code },
        category: "content-api-fetch",
      });
    }
  }
}

function* marketingContentFetch() {
  yield put(contentApiDataSetAction({ isLoading: true }));
  try {
    const marketingContentData = yield call(getMarketingContent);
    yield put(
      contentSetAction({
        isLoading: false,
        marketingData: {
          baseUrl: marketingContentData.storiesBaseUrl,
          storyData: marketingContentData.storyData,
        },
      })
    );
  } catch (e) {
    captureException(e, { category: "marketing-content-fetch" });
  }
}

function* partnerPlaylistsFetch(action: PartnerPlaylistsFetch) {
  const partnerName = action.partnerName;
  try {
    let cachedPartnerPlaylistState = yield select(
      getPartnerPlaylistsDataFromStore
    );

    if (cachedPartnerPlaylistState) {
      yield put(
        contentSetAction({
          isLoading: false,
          partnersPlaylistData: cachedPartnerPlaylistState,
        })
      );
    } else {
      cachedPartnerPlaylistState = yield call(
        getPartnerPlaylistsData,
        partnerName
      );
      yield put(
        contentSetAction({
          isLoading: false,
          partnersPlaylistData: {
            [partnerName]: cachedPartnerPlaylistState,
          },
        })
      );
    }
  } catch (e) {
    captureException(e, { category: "partner-playlists-fetch" });
  }
}
export function* contentSagas() {
  yield takeLatest(actions.CONTENT_API_FETCH, contentApiDataFetch);
  yield takeLatest(actions.CONTENT_FETCH, contentFetch);
  yield takeLatest(actions.MARKETING_CONTENT_FETCH, marketingContentFetch);
  yield takeLatest(actions.PARTNER_PLAYLISTS_FETCH, partnerPlaylistsFetch);
}
