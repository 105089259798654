import { AnalyticsEventParameters, EventName } from "./types/types";

declare global {
  interface Window {
    _iaq: any;
  }
}

class Iterable {
  private static instance: Iterable;

  private constructor() {
    this.init();
  }

  public static getInstance() {
    if (!Iterable.instance) {
      Iterable.instance = new Iterable();
    }
    return Iterable.instance;
  }

  private _iaq: any;

  private email = "";

  private paramterName: any;

  private init() {
    this._iaq = window._iaq;
    if (this._iaq) {
      this._iaq.push(["account", process.env.REACT_APP_ITERABLE_API_KEY]);
    }
  }

  private isLoaded() {
    return this._iaq !== undefined;
  }

  private isLinkedToAccount() {
    return this.isLoaded() && this._iaq.apiKey;
  }

  private isUserIdentified() {
    return this.isLinkedToAccount() && this._iaq.user;
  }

  public identifyUser(email: string) {
    this.email = email;
    try {
      if (this.isLinkedToAccount()) {
        this._iaq.identify(email);
      } else {
        this.init();
      }
    } catch (e) {
      //
    }
  }

  public setProfileParameter(
    parameterName: string,
    parameter: string | number | Date
  ) {
    if (this.isUserIdentified()) {
      this._iaq.push(["identify", this.email, { [parameterName]: parameter }]);
    }
  }

  public trackEvent(
    eventName: EventName,
    params: AnalyticsEventParameters,
    client_platform = "web"
  ) {
    if (this.isUserIdentified()) {
      this._iaq.track(eventName, { ...params, client_platform });
    } else if (this.isLoaded()) {
      // ensure user is identified
      this.identifyUser(this.email);
    } else {
      // ensure _iaq was loaded properly
      this.init();
      // send this event again
      setTimeout(
        () => this.trackEvent(eventName, params, client_platform),
        500
      );
    }
  }

  public pushBrowserToken(
    browserToken: string,
    email?: string,
    userId?: string
  ) {
    if (this.isUserIdentified()) {
      const request = new XMLHttpRequest();
      request.open(
        "POST",
        this._iaq.baseUrl + "/api/users/registerBrowserToken",
        true
      );
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("api-key", this._iaq.apiKey);
      request.send(
        JSON.stringify({
          email: email,
          browserToken: browserToken,
        })
      );
    } else if (this.isLoaded()) {
      this.identifyUser(this.email);
      setTimeout(() => this.pushBrowserToken(browserToken, email, userId), 500);
    } else {
      this.init();
      setTimeout(() => this.pushBrowserToken(browserToken, email, userId), 500);
    }
  }
}

export default Iterable;
