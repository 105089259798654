import React from "react";

import "./ProgressBar.scss";

const ProgressBar = ({
  step,
  totalSteps,
}: {
  step: number;
  totalSteps: number;
}) => {
  const fillWidthStyle = {
    width: `${(step / totalSteps) * 100}%`,
  };

  return (
    <div className="progress-bar">
      <div className="progress-bar-fill" style={fillWidthStyle} />
    </div>
  );
};

export default ProgressBar;
