import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet";

function withDesktopOnlyView<T>(
  WrappedComponent: ComponentType<T>
): FunctionComponent<any> {
  return (props: T) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    useEffect(() => {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
        const metaTags = document.querySelectorAll(`meta[name="viewport"]`);
        const metaTag = metaTags[metaTags.length - 1];
        if (metaTag) {
          // replace helmet tag content with default value - this removes the tag as it matches the original one
          metaTag.setAttribute("content", "width=device-width");
        }
      };
    }, []);

    const isNotFullDesktop: boolean = width <= 1024;
    return (
      <React.Fragment>
        <Helmet>
          {isNotFullDesktop && <meta name="viewport" content="width=1024" />}
        </Helmet>
        <WrappedComponent {...{ ...props }} />
      </React.Fragment>
    );
  };
}

export default withDesktopOnlyView;
