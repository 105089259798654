import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ApplicationState } from "../../../store";
import { profileFetchAction } from "../../../store/profile";
import {
  braintreeCancelSubscription,
  braintreeRefundSubscription,
} from "../../../store/profile/api/braintree";
import { LabelizedSubscription } from "../../../store/promotion";

import { captureException } from "../../../helpers/logger";
import { Path } from "../../../helpers/path/constants";
import {
  getCancelSubscriptionInstructions,
  getRefundSubscriptionInstructions,
} from "../../../helpers/subscriptions/api";
import { labelize } from "shared/helpers/user/helpers";
import {
  ClientSubscriptionData,
  SubscriptionPlatform,
  isMobileSubscription,
} from "shared/helpers/user/types";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";
import ModalConfirm from "shared/components/modal/ModalConfirm";
import SubscriptionSectionShared from "shared/components/users/sections/SubscriptionSection";

const SubscriptionSection = ({
  redirect,
}: {
  redirect: (path: string) => void;
}) => {
  const webPrefix = process.env.REACT_APP_WEB_URL || "";

  const dispatch = useDispatch();

  const profile = useSelector((state: ApplicationState) => state.profile);

  const [subscriptionData, setSubscriptionData] =
    useState<ClientSubscriptionData>();
  useEffect(() => {
    if (profile.SubscriptionData) {
      setSubscriptionData(profile.SubscriptionData);
    }
  }, [profile]);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [modalBodyHTML, setModalBodyHTML] = useState<string | null>(null);
  const [modalConfirmText, setModalConfirmText] = useState<string | undefined>(
    "Yes"
  );
  const [modalCancelText, setModalCancelText] = useState<string | null>(null);

  const closeModal = () => setShowModal(false);
  const [modalOnConfirm, setModalOnConfirm] = useState<() => void>(
    () => closeModal
  );

  const [labels, setLabels] = useState<LabelizedSubscription>();
  useEffect(() => {
    if (subscriptionData) {
      setLabels(labelize(subscriptionData, profile.UserData?.schoolDetails));
    }
  }, [profile, subscriptionData]);

  const upgradeBraintreeSubscription = () => {
    redirect(Path.PAYMENT + "?promo=standard");
  };

  const getCancelInstructionsForMobile = async () => {
    try {
      const subscriptionCancelInstructions =
        await getCancelSubscriptionInstructions();
      setModalTitle(subscriptionCancelInstructions.title);

      // We can update links on the BE, but have a default baked in.g
      const storeLink =
        subscriptionCancelInstructions.link || labels?.storeLink;
      let bodyHTML = "<React.Fragment><ol class='mlr-a mb-3'>";
      for (const step of subscriptionCancelInstructions.steps) {
        bodyHTML += `<li>${step}</li>`;
      }
      bodyHTML += `</ol><a class="btn action filled wide" href="${storeLink}">${labels?.storeLabel}</a></React.Fragment>`;
      setModalBodyHTML(bodyHTML);

      setModalConfirmText("Ok");
      setModalCancelText(null);
      setModalOnConfirm(() => closeModal);
      setShowModal(true);
    } catch (e) {
      captureException(e, {
        category: "subscription.cancel",
        extra: {
          subscriptionPlatform: subscriptionData?.platform,
        },
      });
    }
  };

  const confirmCancelBraintreeSubscription = async () => {
    setIsLoading(true);
    setShowModal(false);
    try {
      const response = await braintreeCancelSubscription();
      if (response.SubscriptionData) {
        toast.success("Subscription renewal has been cancelled");
      }
      dispatch(profileFetchAction());
    } catch (e) {
      if (e.message) {
        toast.error(e.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const cancelBraintreeSubscription = () => {
    setModalTitle(
      "Are you sure you want to cancel your subscription auto-renew?"
    );
    setModalConfirmText("Yes");
    setModalCancelText("No");
    setModalOnConfirm(() => confirmCancelBraintreeSubscription);
    setShowModal(true);
  };

  const tryCancelSubscription = async () => {
    if (
      !subscriptionData ||
      subscriptionData.platform !== SubscriptionPlatform.Braintree
    ) {
      await getCancelInstructionsForMobile();
    } else {
      cancelBraintreeSubscription();
    }
  };

  const confirmRequestRefund = async () => {
    setIsLoading(true);
    setShowModal(false);
    try {
      const response = await braintreeRefundSubscription();
      if (response.SubscriptionData) {
        toast.success("Subscription has been refunded.");
        // TODO: send iterate install
        dispatch(profileFetchAction());
      }
    } catch (e) {
      if (e.message) {
        toast.error(e.message);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRequestMobileRefund = async () => {
    setShowModal(false);
  };

  const requestRefund = async () => {
    if (isMobileSubscription(subscriptionData?.platform || "")) {
      try {
        const refundInstructions = await getRefundSubscriptionInstructions();
        setModalTitle(refundInstructions.title);
        setModalBodyHTML(
          `<p>${refundInstructions.body}</p><a href="${refundInstructions.linkUrl}">${refundInstructions.linkText}</a>`
        );
        setModalConfirmText(undefined);
        setModalOnConfirm(() => confirmRequestMobileRefund);
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      setModalTitle(
        "Are you sure? You will be refunded your full amount & lose access to Moshi immediately"
      );
      setModalConfirmText("Yes");
      setModalOnConfirm(() => confirmRequestRefund);
    }

    setModalCancelText("Close");
    setShowModal(true);
  };

  const updatePaymentDetails = () => {
    redirect(Path.PAYMENT_UPDATE);
  };

  if (
    !subscriptionData ||
    subscriptionData.subscriptionType === "neverSubscribed"
  ) {
    return (
      <div className="component-box" id="subscription">
        <div className="section subscription-section ">
          <div className="content subscription">
            <h1>Subscription</h1>
            {labels && (
              <p
                className="font-bold small"
                dangerouslySetInnerHTML={{
                  __html: labels.subscriptionStatusDetails,
                }}
              />
            )}
            {labels?.showUpgrade && (
              <div className="actions with-loading-block">
                <button
                  className="btn action filled wide"
                  onClick={upgradeBraintreeSubscription}
                  disabled={!upgradeBraintreeSubscription}
                >
                  Upgrade
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="component-box">
        <div className={"section subscription-section with-loading-block"}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className="content subscription">
              <SubscriptionSectionShared
                subscriptionData={subscriptionData}
                labels={labels}
              />
              <div className="actions mt-3 mb-3">
                {labels?.showCancel && (
                  <button
                    type="button"
                    onClick={tryCancelSubscription}
                    className="btn action filled wide"
                    disabled={!tryCancelSubscription}
                  >
                    Cancel subscription
                  </button>
                )}
                {subscriptionData.canRequestRefund && (
                  <button
                    type="button"
                    onClick={requestRefund}
                    className="btn action filled wide"
                    disabled={!requestRefund}
                  >
                    Request refund
                  </button>
                )}
                {updatePaymentDetails && labels?.showUpdate && (
                  <button
                    type="button"
                    onClick={updatePaymentDetails}
                    className="btn action filled wide"
                  >
                    Update payment
                  </button>
                )}
                {labels?.showUpgrade && (
                  <button
                    type="button"
                    onClick={upgradeBraintreeSubscription}
                    className="btn action filled wide"
                    disabled={!upgradeBraintreeSubscription}
                  >
                    Upgrade
                  </button>
                )}
                {subscriptionData.isSchoolSubscription && (
                  <a
                    className="btn action filled wide"
                    style={{ margin: "auto" }}
                    href={webPrefix + Path.CONTACT}
                  >
                    Contact us
                  </a>
                )}
              </div>
              {isMobileSubscription(subscriptionData.platform) &&
                subscriptionData.subscriptionValid && (
                  <button
                    type="button"
                    onClick={requestRefund}
                    className="btn secondary wide"
                    disabled={!requestRefund}
                  >
                    Request refund
                  </button>
                )}
            </div>
          )}
        </div>
      </div>
      <ModalConfirm
        question={showModal ? modalTitle : null}
        bodyHTML={modalBodyHTML}
        confirmText={modalConfirmText}
        cancelText={modalCancelText}
        loading={isLoading}
        close={closeModal}
        onConfirm={modalOnConfirm}
      />
    </React.Fragment>
  );
};

export default SubscriptionSection;
