import React from "react";
import { useHistory } from "react-router-dom";

import backIcon from "./icons/Back@1.5x.svg";

const BackButton = ({ onClick }: { onClick?: () => void }) => {
  const history = useHistory();
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.goBack();
    }
  };
  return (
    <img
      className="back"
      src={backIcon}
      alt="back"
      width="13px"
      height="24px"
      onClick={handleClick}
    />
  );
};
export default BackButton;
