import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Severity } from "@sentry/types";

import { ApplicationState } from "../../../../store";
import {
  LessonPlanData,
  SchoolPlaylist,
  StoryData,
} from "../../../../store/content";
import * as contentActions from "../../../../store/content/actions";
import { putFavorites } from "../../../../store/content/api/api";
import { CATEGORY_IDS } from "../../../../store/content/constants";
import { getUserProfileFromStore } from "../../../../store/profile/selectors";

import { captureMessage, events } from "../../../../helpers/logger";
import { Path } from "../../../../helpers/path/constants";

import BackButton from "shared/components/controls/BackButton";

import withSchoolOnlyAccess from "../../../higherOrderComponents/schools/withSchoolOnlyAccess";
import PlaylistDetails from "../playlist/details/PlaylistDetails";
import PlaylistTracksList from "../playlist/list/PlaylistTracksList";
import PlaylistAudioPlayer from "../playlist/player/PlaylistAudioPlayer";
import PlaylistSchoolResources from "../playlist/resources/PlaylistSchoolResources";

import "../Player.scss";

/**
 * Page containing content players for multiple grouped content: playlist, collection etc.
 */
const MultiPlayer = () => {
  const FALLBACK_BACKGROUND_URL = "/_default/background.jpeg";

  const history = useHistory();

  const path = location.pathname.split("/").filter((s: string) => s !== "");
  const playlistId = path[2];

  const dispatch = useDispatch();
  const content = useSelector((state: ApplicationState) => state.content);
  const userProfile = useSelector(getUserProfileFromStore);

  const [playlistData, setPlaylistData] = useState<
    SchoolPlaylist | undefined
  >();
  const [baseUrl, setBaseUrl] = useState<string>();
  const [currentTrackUrl, setCurrentTrackUrl] = useState<string>();
  useEffect(() => {
    if (content.data) {
      setBaseUrl(`${content.data.baseUrl}`);
      if (content.data.schoolPlaylists) {
        const playlistDataForPath = content.data.schoolPlaylists.find(
          (playlist) => playlist.id === playlistId
        );
        if (playlistDataForPath) {
          setPlaylistData(playlistDataForPath);
        } else {
          toast.error("We could not find the playlist you are looking for.");
          history.replace(Path.SONG_LIST_SCHOOLS);
        }
      }
    }
  }, [content, history, playlistId]);

  const [currentTrackNumber, setCurrentTrackNumber] = useState(0);
  useEffect(() => {
    if (playlistData) {
      setCurrentTrackUrl(
        `${baseUrl}/${playlistData.storyIds[currentTrackNumber]}/`
      );
    }
  }, [playlistData, currentTrackNumber, baseUrl]);

  const [storyData, setStoryData] = useState<StoryData[]>();
  useEffect(() => {
    const getSortedStoryDataFromPlaylistStoryIds = (storyIds: string[]) => {
      return content.data?.storyData
        .reduce((acc: StoryData[], currentValue) => {
          const found = storyIds.includes(currentValue.id);
          if (found) {
            acc.push(currentValue);
          }
          return acc;
        }, [])
        .sort((a, b) => storyIds.indexOf(a.id) - storyIds.indexOf(b.id));
    };

    if (playlistData) {
      setStoryData(
        getSortedStoryDataFromPlaylistStoryIds(playlistData.storyIds)
      );
    }
  }, [playlistData, currentTrackNumber, content.data]);

  const [selTags, setSelTags] = useState<string[] | undefined>();
  useEffect(() => {
    if (playlistData) {
      setSelTags(
        playlistData.storyIds.reduce((acc: string[], currentValue) => {
          const playlistStoryData = content.data?.storyData.find(
            (story) => story.id === currentValue
          );
          if (playlistStoryData && playlistStoryData.selTag) {
            acc.push(playlistStoryData.selTag);
          }
          return acc;
        }, [])
      );
    }
  }, [playlistData, content.data]);

  const [resources, setResources] = useState<LessonPlanData[]>();
  useEffect(() => {
    if (playlistData?.lessonPlansData) {
      setResources(playlistData.lessonPlansData);
    }
  }, [playlistData, storyData]);

  const onNext = () => {
    if (!playlistData) {
      return;
    }
    if (currentTrackNumber + 1 >= playlistData.storyIds.length) {
      setCurrentTrackNumber(0);
    } else {
      setCurrentTrackNumber(currentTrackNumber + 1);
    }
  };
  const onPrevious = () => {
    if (!playlistData) {
      return;
    }
    if (currentTrackNumber - 1 < 0) {
      setCurrentTrackNumber(playlistData.storyIds.length - 1);
    } else {
      setCurrentTrackNumber(currentTrackNumber - 1);
    }
  };
  const onTrackSelected = (storyId: string) => {
    if (playlistData?.storyIds) {
      const trackNumber = playlistData.storyIds.indexOf(storyId);
      setCurrentTrackNumber(trackNumber);
    }
  };

  const onFavorite = async (storyId: string) => {
    if (!content.data) {
      return;
    }
    try {
      const favorites = await putFavorites(storyId, userProfile?.userProfileId);
      dispatch(
        contentActions.contentSetAction({
          ...content,
          data: {
            ...content.data,
            favorites: favorites.storyIds,
          },
        })
      );
    } catch (e) {
      captureMessage("Failed to add / remove favorite", {
        level: Severity.Debug,
      });
    }
  };

  /**
   * Analytics
   */
  useEffect(() => {
    if (playlistData) {
      events.playlists.selected(playlistData.id);
    }
  }, [playlistData]);

  const onSongPlayed = () => {
    if (storyData) {
      events.song.played(
        storyData[currentTrackNumber],
        CATEGORY_IDS.PLAYLISTS,
        undefined,
        playlistData?.id
      );
    }
  };

  const onSongComplete = () => {
    if (storyData) {
      events.song.completed(
        storyData[currentTrackNumber],
        CATEGORY_IDS.PLAYLISTS,
        undefined,
        playlistData?.id
      );
    }
    onNext();
  };

  return (
    <div className="app-body center player-page" id="player-page">
      <div className="component-box with-header">
        <div className="component-box-header">
          <BackButton />
        </div>
        {playlistData && storyData && baseUrl && (
          <div className="container">
            {storyData && (
              <React.Fragment>
                <PlaylistAudioPlayer
                  backgroundUrl={
                    storyData[currentTrackNumber].backgroundUrl
                      ? currentTrackUrl +
                        storyData[currentTrackNumber].backgroundUrl
                      : content.data?.baseUrl + FALLBACK_BACKGROUND_URL
                  }
                  audioUrl={
                    currentTrackUrl + storyData[currentTrackNumber].audioUrl
                  }
                  onPlay={onSongPlayed}
                  onClickNext={onNext}
                  onClickPrevious={onPrevious}
                  onEnded={onSongComplete}
                />
                <PlaylistDetails
                  name={playlistData.name}
                  description={playlistData.description}
                  tags={new Set(selTags)}
                />
                <PlaylistSchoolResources resources={resources} />
                <PlaylistTracksList
                  storyData={storyData}
                  currentTrack={currentTrackNumber}
                  baseUrl={baseUrl}
                  favorites={
                    content.data?.favorites
                      ? Array.from(content.data?.favorites)
                      : []
                  }
                  onTrackSelected={onTrackSelected}
                  onFavoriteToggle={onFavorite}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <div className="story-list" />
    </div>
  );
};

export default withSchoolOnlyAccess(MultiPlayer);
