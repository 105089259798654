import React from "react";

import "./ExpandButton.scss";

interface Props {
  expanded: boolean;
  onExpandToggle: () => void;
}
const ExpandButton = ({ expanded, onExpandToggle }: Props) => {
  return (
    <div
      className={`plus ${expanded ? "minus" : ""}`}
      onClick={onExpandToggle}
    />
  );
};

export default ExpandButton;
