export const AllowIndexURIs = ["/subscribe", "/"];

export const ALEXA_ACCOUNT_LINKING_REDIRECT_BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://auth.moshikids.com/login"
    : "https://sta-auth.moshikids.com/login";
const MINIMUM_PARENTAL_GATE_AGE = 18;
export const MINIMUM_ALLOWED_PARENTAL_GATE_YEAR =
  new Date().getFullYear() - 100;
export const MAXIMUM_VALID_PARENTAL_GATE_YEAR =
  new Date().getFullYear() - MINIMUM_PARENTAL_GATE_AGE;
