import { Reducer } from "redux";

import { VersionActions } from "./actions";
import { VersionActionTypes, VersionState } from "./types";

const reducer: Reducer<VersionState, VersionActions> = (state = {}, action) => {
  if (action.type === VersionActionTypes.VERSION_SET) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};

export { reducer as versionReducer };
