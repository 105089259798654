import { Severity } from "@sentry/types";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  MessagePayload,
  Messaging,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

import Iterable from "../analytics/iterable";
import { captureMessage } from "../logger";

class Firebase {
  private static instance: Firebase;

  private constructor() {
    isSupported().then((supported) => {
      if (supported) {
        this.init().then();
        this.initMessaging().then();
      } else {
        captureMessage("Firebase messaging: browser not supported", {
          level: Severity.Debug,
          category: "firebase",
        });
      }
    });
  }

  private app: FirebaseApp | undefined;

  private messaging: Messaging | undefined;

  private token = "";

  private async init() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.REACT_APP_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    if (await isSupported()) {
      this.app = initializeApp(firebaseConfig);
    }
  }

  private async initMessaging() {
    if (!this.app) {
      await this.init();
    }
    this.messaging = getMessaging(this.app);
  }

  public static getInstance() {
    if (!Firebase.instance) {
      Firebase.instance = new Firebase();
    }
    return Firebase.instance;
  }

  public async getMessagingToken(email?: string) {
    if (this.messaging) {
      getToken(this.messaging, {
        vapidKey: process.env.REACT_APP_VAPID_ID,
      })
        .then((currentToken) => {
          if (currentToken) {
            this.token = currentToken;
            const iterable = Iterable.getInstance();
            iterable.pushBrowserToken(currentToken, email);
            if (
              process.env.REACT_APP_ENVIRONMENT === "staging" ||
              process.env.REACT_APP_ENVIRONMENT === "development"
            ) {
              console.log(this.token);
            }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((e) => {
          console.log("An error occurred while retrieving token. ", e);
          return;
        });
    } else {
      await this.initMessaging();
      await this.getMessagingToken(email);
    }
  }

  public onMessageListener() {
    return new Promise<MessagePayload>((resolve) => {
      if (this.messaging) {
        onMessage(this.messaging, (payload) => {
          resolve(payload);
        });
      }
    });
  }
}

export default Firebase;
