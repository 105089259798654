import React, { useEffect, useRef, useState } from "react";

import { PlaylistGroup, SchoolPlaylist } from "../../../../store/content";

import ExpandButton from "../../../../../shared/components/controls/buttons/ExpandButton";

import PlaylistTile from "../grid/PlaylistTile";

import "./PlaylistGroupRow.scss";

interface Props {
  playlistGroup: PlaylistGroup;
  playlistData: SchoolPlaylist[];
  onPlaylistSelected: (playlistId: string) => void;
}

const PlaylistGroupRow = ({
  playlistGroup,
  playlistData,
  onPlaylistSelected,
}: Props) => {
  const contentGroupRow = useRef<HTMLDivElement>(null);
  const [canExpand, setCanExpand] = useState(false);
  useEffect(() => {
    const isOverflown = (element: HTMLDivElement) => {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    };

    if (contentGroupRow.current) {
      setCanExpand(isOverflown(contentGroupRow.current));
    }
  }, [contentGroupRow, canExpand, playlistData]);

  const [expanded, setExpanded] = useState(false);
  const onExpandToggle = () => {
    setExpanded(!expanded);
  };

  if (playlistData.length === 0) {
    return <></>;
  }
  return (
    <React.Fragment>
      <div className="content-row-header">
        <h3>{playlistGroup.name}</h3>
        {canExpand && (
          <ExpandButton expanded={expanded} onExpandToggle={onExpandToggle} />
        )}
      </div>
      <div
        className={`content-grid group ${expanded ? "expanded" : ""}`}
        ref={contentGroupRow}
      >
        {playlistGroup.playlistIds.map((playlistId) => (
          <PlaylistTile
            key={playlistId}
            playlistData={playlistData.find(
              (playlist) => playlist.id === playlistId
            )}
            onSelect={onPlaylistSelected}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default PlaylistGroupRow;
