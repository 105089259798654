import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Severity } from "@sentry/types";
import { captureMessage } from "clients/helpers/logger";
import { Handle } from "clients/helpers/types";
import { ApplicationState } from "clients/store";
import { SchoolPlaylist, StoryData } from "clients/store/content";
import * as contentActions from "clients/store/content/actions";

import { Filter } from "../../../../shared/store/pages";
import { putFavorites } from "../../../store/content/api/api";
import { CATEGORY_IDS } from "../../../store/content/constants";
import { getContentFromStore } from "../../../store/content/selectors";
import { getUserProfileFromStore } from "../../../store/profile/selectors";
import * as pagesActions from "shared/store/pages/actions";

import {
  filterPlaylistsByFilteredStories,
  filterStoriesByCategory,
  filterStoriesOrPlaylistsByDuration,
  filterStoryDataByThemeAndSEL,
  handleCategoryFilterChangesBasedOnSelection,
} from "../../../helpers/content/filtersHelper";
import { isAllPlaylistData } from "./filter/helpers/helpers";
import { SearchResults } from "./search/helpers/types";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";

import withDesktopOnlyView from "../../higherOrderComponents/layout/withDesktopOnlyView";
import withSchoolOnlyAccess from "../../higherOrderComponents/schools/withSchoolOnlyAccess";
import FeaturedSection from "./featured/FeaturedSection";
import ContentFilter from "./filter/ContentFilter";
import ContentResults from "./results/ContentResults";
import FallbackResults from "./results/FallbackResults";
import GroupsResults from "./results/GroupsResults";
import ContentSearch from "./search/ContentSearch";

import "./Menu.scss";

const Menu = () => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(contentActions.contentApiDataFetchAction());
  }, [dispatch]);

  const content = useSelector(getContentFromStore);
  const pagesState = useSelector((state: ApplicationState) => state.pages);
  const userProfile = useSelector(getUserProfileFromStore);

  const [filteredContentData, setFilteredContentData] = useState<
    (StoryData | SchoolPlaylist)[]
  >([]);
  const [fallbackStoryData, setFallbackStoryData] = useState<StoryData[]>([]);
  const [fallbackSearchResults, setFallbackSearchResults] = useState<
    StoryData[]
  >([]);
  const [fallbackMessage, setFallbackMessage] = useState<string>(
    "Sorry we can’t find what you’re looking for. Here are some tracks we recommend."
  );

  useEffect(() => {
    if (content?.data) {
      const homeTileGroups = content.data.homeTileData.homeTilesGroups;
      let recommended = homeTileGroups.find(
        (group) => group.label === "Just for you"
      );
      if (!recommended) {
        recommended = homeTileGroups.find((group) => group.label === "New");
      }
      if (recommended) {
        setFallbackStoryData(
          recommended.homeTiles.reduce((accumulator: StoryData[], value) => {
            const storyData = content.data?.storyData.find(
              (story) => story.id === value.storyId
            );
            if (storyData) {
              accumulator.push(storyData);
            }
            return accumulator;
          }, [])
        );
      }
    }
  }, [content]);

  const contentFilterHandle = useRef<Handle<typeof ContentFilter>>();
  const searchHandle = useRef<Handle<typeof ContentSearch>>(null);

  const [searchResults, setSearchResults] = useState<SearchResults>();
  const contentSearched = (storyIds: string[], playlistIds: string[]) => {
    if (contentFilterHandle) {
      contentFilterHandle.current.clearPlaylistFilter();
    }
    setSearchResults({ storyIds, playlistIds });
    setFallbackSearchResults([]);
  };

  const searchFailed = (storyIds: string[], message: string) => {
    const storyDataFromSearch = content.data?.storyData.filter((storyData) =>
      storyIds.includes(storyData.id)
    );
    setFilteredContentData([]);

    if (message) {
      setFallbackMessage(message);
    }
    setFallbackSearchResults(storyDataFromSearch || fallbackStoryData);
  };

  const clearSearchResults = () => {
    setSearchResults(undefined);
  };

  const filterUpdated = useCallback(
    (filter: Filter) => {
      if (content?.data) {
        filter = handleCategoryFilterChangesBasedOnSelection(filter);
        const allCategory = content.data.categoryData.find(
          (categoryData) => categoryData.id === CATEGORY_IDS.ALL
        );
        if (allCategory) {
          let filteredStoryDataAccumulator: (StoryData | SchoolPlaylist)[] =
            filterStoryDataByThemeAndSEL(allCategory, content, filter);

          filteredStoryDataAccumulator = filterStoriesByCategory(
            filter,
            content,
            filteredStoryDataAccumulator
          );
          filteredStoryDataAccumulator = filterPlaylistsByFilteredStories(
            filteredStoryDataAccumulator,
            content,
            filter
          );
          filteredStoryDataAccumulator = filterStoriesOrPlaylistsByDuration(
            filteredStoryDataAccumulator,
            filter
          );

          if (searchResults) {
            filteredStoryDataAccumulator = filteredStoryDataAccumulator.filter(
              (contentData) =>
                searchResults?.storyIds.includes(contentData.id) ||
                searchResults?.playlistIds.includes(contentData.id)
            );
          }
          setFilteredContentData(
            Array.from(new Set(filteredStoryDataAccumulator))
          );
        }
      }
    },
    [content, searchResults]
  );

  const savePageState = useCallback(
    (filter?: Filter) => {
      const pageState = {
        [location.pathname]: {
          scrollData: {
            yPosition: window.pageYOffset,
            xPosition: window.pageXOffset,
          },
          filterData: pagesState[location.pathname]?.filterData,
        },
      };
      if (filter) {
        pageState[location.pathname].filterData = filter;
      }
      dispatch(pagesActions.pagesSetAction({ ...pagesState, ...pageState }));
    },
    [dispatch, location.pathname, pagesState]
  );

  const onFilterUpdated = useCallback(
    (filter: Filter) => {
      filterUpdated(filter);
    },
    [filterUpdated]
  );

  const onFilterCleared = useCallback(() => {
    setSearchResults(undefined);

    if (searchHandle) {
      searchHandle.current.clearInputValue();
    }
  }, [searchHandle]);

  const onSongSelected = (storyId: string) => {
    savePageState();
    history.push(`play/${storyId}`);
  };

  const onPlaylistSelected = (playlistId: string) => {
    savePageState();
    history.push(`playlist/${playlistId}`);
  };

  const onFavoriteClicked = async (storyId: string) => {
    if (!content.data) {
      return;
    }
    try {
      const favorites = await putFavorites(storyId, userProfile?.userProfileId);

      dispatch(
        contentActions.contentSetAction({
          ...content,
          data: {
            ...content.data,
            favorites: favorites.storyIds,
          },
        })
      );
      toast.success(
        favorites.storyIds.includes(storyId)
          ? "Added to favorites"
          : "Removed from favorites"
      );
    } catch (e) {
      captureMessage("Failed to add / remove favorite", {
        level: Severity.Debug,
      });
    }
  };

  if (content.isLoading || !content.data) {
    return (
      <div className="app-body center player-menu">
        <LoadingIndicator />
      </div>
    );
  }

  const allContentDataIsPlaylists = isAllPlaylistData(filteredContentData);
  const favorites = content.data.favorites;

  return (
    <div className={"app-body center player-menu"}>
      <FeaturedSection
        baseUrl={content.data.baseUrl}
        featuredContent={content.data.featuredContentData}
        favorites={content.data.storyData.filter((story) =>
          favorites.includes(story.id)
        )}
        onSongSelected={onSongSelected}
        onPlaylistSelected={onPlaylistSelected}
        onFavoriteClicked={onFavoriteClicked}
      />

      <div className="component-box with-header">
        <div className="component-box-header">
          <ContentSearch
            ref={(c) => (searchHandle.current = c)}
            onSearchSuccessful={contentSearched}
            onSearchUnsuccessful={searchFailed}
            onSearchEmpty={clearSearchResults}
          />
          <ContentFilter
            ref={(c) => (contentFilterHandle.current = c)}
            selTags={content.data.selTags}
            themes={content.data.themes}
            categories={content.data.categoryData.map((item) => item.id)}
            onChange={onFilterUpdated}
            onClear={onFilterCleared}
            preSelected={pagesState[location.pathname]?.filterData}
          />
        </div>
        <div className="content">
          <h1>{allContentDataIsPlaylists ? "Playlists" : "Results"}</h1>
          {filteredContentData.length <= 0 && (
            <React.Fragment>
              <h3 className="content-info">{fallbackMessage}</h3>
              <h1>Recommended for you</h1>
              <FallbackResults
                baseUrl={content.data.baseUrl}
                fallbackContent={
                  fallbackSearchResults.length > 0
                    ? fallbackSearchResults
                    : fallbackStoryData
                }
                favorites={favorites}
                onSongSelected={onSongSelected}
                onFavoriteClicked={onFavoriteClicked}
              />
            </React.Fragment>
          )}
          {allContentDataIsPlaylists ? (
            <GroupsResults
              playlistGroups={content.data.playlistGroups}
              playlistContentData={filteredContentData as SchoolPlaylist[]}
              onPlaylistSelected={onPlaylistSelected}
            />
          ) : (
            <ContentResults
              baseUrl={content.data.baseUrl}
              filteredContentData={filteredContentData}
              onSongSelected={onSongSelected}
              onPlaylistSelected={onPlaylistSelected}
              onFavoriteClicked={onFavoriteClicked}
              favorites={favorites}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withSchoolOnlyAccess(withDesktopOnlyView(Menu));
