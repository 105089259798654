import { ClientFullUserProfile } from "../../helpers/userProfiles/types";
import {
  ClientCognitoDevice,
  ClientDynamoUser,
  ClientReferralData,
  ClientSubscriptionData,
} from "shared/helpers/user/types";

export interface GetCurrentUserApiResponse {
  readonly SubscriptionData: ClientSubscriptionData;
  readonly UserData: ClientDynamoUser;
  readonly ReferralData: ClientReferralData;
}

export interface ProfileState {
  isLoading?: boolean;
  userSub?: string | undefined;
  lastUpdateMillis?: number;
  readonly UserData?: ClientDynamoUser | undefined;
  readonly SubscriptionData?: ClientSubscriptionData | undefined;
  readonly DeviceList?: ClientCognitoDevice[] | undefined;
  readonly UserProfile?: ClientFullUserProfile;
}

export enum ProfileActionsTypes {
  PROFILE_SET = "@@profile/PROFILE_SET",
  PROFILE_FETCH = "@@profile/PROFILE_FETCH",
  PROFILE_UPDATE = "@@profile/PROFILE_UPDATE",
  PROFILE_DEVICES_FETCH = "@@profile/PROFILE_DEVICES_FETCH",
  PROFILE_DEVICE_DELETE = "@@profile/PROFILE_DEVICE_DELETE",
}
