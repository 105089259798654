import { FooterItem } from "./types";

export const footerItems: FooterItem[] = [
  {
    id: "privacy",
    label: "Privacy policy",
    href: process.env.REACT_APP_WEB_URL + "/privacy-policy",
    target: "_blank",
  },
  {
    id: "cookies",
    label: "Cookies",
    href: process.env.REACT_APP_WEB_URL + "/cookies",
    target: "_blank",
  },
  {
    id: "terms",
    label: "T&Cs",
    href: process.env.REACT_APP_WEB_URL + "/terms-conditions",
    target: "_blank",
  },
];
