export * from "./types/static";
export * from "./types/dynamic";
export * from "./types/clients";
export * from "./types/partners";
export * from "./types/playlists";
export * from "./types/favorites";

export enum ContentActionTypes {
  CONTENT_API_FETCH = "@@content/CONTENT_API_FETCH",
  CONTENT_API_SET = "@@content/CONTENT_API_SET",
  CONTENT_SET = "@@content/CONTENT_SET",
  CONTENT_FETCH = "@@content/CONTENT_FETCH",
  MARKETING_CONTENT_FETCH = "@@content/MARKETING_CONTENT_FETCH",
  PARTNER_PLAYLISTS_FETCH = "@@content/PARTNER_PLAYLISTS_FETCH",
}
