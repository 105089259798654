import { AWSKinesisFirehoseProvider, Amplify, Analytics } from "aws-amplify";

import { awsconfig } from "./../../../shared/store/auth/api";

import { KinesisEventData } from "./types/kinesis";
import { EventName } from "./types/types";

class KinesisController {
  constructor() {
    Amplify.configure(awsconfig);
    Analytics.addPluggable(
      new AWSKinesisFirehoseProvider({
        region: "eu-west-1",
        streamName: awsconfig.Analytics.AWSKinesisFirehose.streamName,
      })
    );

    Analytics.configure(awsconfig);
    Analytics.enable();
  }

  async updateEndpoint(info: Record<string, unknown>) {
    await Analytics.updateEndpoint(
      {
        address: info.email,
        channelType: "EMAIL",
        userId: info.sub,
        userAttributes: info.attributes,
      },
      "AWSKinesis"
    );

    await Analytics.startSession("AWSKinesis");
    await Analytics.startSession("AWSKinesisFirehose");
  }

  async trackEvent(eventName: EventName, params?: KinesisEventData) {
    Analytics.record(
      {
        data: { eventName: eventName, ...(params || {}) },
        streamName: "moshi-analytics",
        partitionKey: "",
      },
      "AWSKinesisFirehose"
    );
  }
}

export default KinesisController;
