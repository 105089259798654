import { CONTENT_TYPES } from "../constants";
import { FeaturedContent, SchoolPlaylist, StoryData } from "../types";

export function mapFeaturedContent(
  featuredContent: FeaturedContent[],
  storyData: StoryData[],
  playlistData: SchoolPlaylist[]
): (StoryData | SchoolPlaylist)[] {
  if (!featuredContent) {
    return [];
  }
  // Featured ids for ordering
  const featuredContentIds = featuredContent.map((item) => item.id);
  // Split playlists and other content to avoid duplications
  const featuredContentStoryIds = featuredContent
    .filter((item) => {
      if (item.type !== CONTENT_TYPES.PLAYLIST) return item;
    })
    .map((item) => item.id);
  const featuredContentPlaylistIds = featuredContent
    .filter((item) => {
      if (item.type === CONTENT_TYPES.PLAYLIST) return item;
    })
    .map((item) => item.id);

  const featuredStoryData = storyData.filter((story) =>
    featuredContentStoryIds.includes(story.id)
  );
  const featuredPlaylistData = playlistData.filter((playlist) =>
    featuredContentPlaylistIds.includes(playlist.id)
  );

  const combinedFeaturedData = [...featuredStoryData, ...featuredPlaylistData];
  return combinedFeaturedData.sort(
    (a, b) =>
      featuredContentIds.indexOf(a.id) - featuredContentIds.indexOf(b.id)
  );
}
