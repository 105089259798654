import React from "react";
import AudioPlayer from "react-h5-audio-player";

import { customIcons } from "../../customIcons";

import "../../AudioPlayer.scss";

interface Props {
  backgroundUrl: string;
  audioUrl: string;
  onPlay?: () => void;
  onEnded?: () => void;
}

const StoryAudioPlayer = ({
  backgroundUrl,
  audioUrl,
  onPlay,
  onEnded,
}: Props) => {
  return (
    <div className="player">
      <img
        className="background"
        src={backgroundUrl}
        alt="Moshi background"
        width={500}
      />
      <AudioPlayer
        autoPlay={false}
        showFilledVolume={true}
        customIcons={customIcons}
        progressJumpStep={15000}
        src={audioUrl}
        onPlay={onPlay}
        onEnded={onEnded}
      />
    </div>
  );
};

export default StoryAudioPlayer;
