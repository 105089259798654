import { Labels } from "../store/promotion";

const createTrialLabels = (labelsFromPromotion: Labels): Labels => {
  for (const key of Object.keys(labelsFromPromotion)) {
    if (key + "NoTrial" in labelsFromPromotion) {
      labelsFromPromotion[key] = labelsFromPromotion[key + "NoTrial"];
    }
  }
  return labelsFromPromotion;
};

const fillPlaceholderValues = (
  labelsFromPromotion: Labels,
  values: { [key: string]: string }
): Labels => {
  for (const [key, value] of Object.entries(labelsFromPromotion)) {
    if (value && value.includes("[") && value.includes("]")) {
      const replacementKey = value.substring(
        value.lastIndexOf("[") + 1,
        value.lastIndexOf("]")
      );
      const replacementValue = values[replacementKey];
      labelsFromPromotion[key] = value.replace(/\[(.*?)]/, replacementValue);
    }
  }
  return labelsFromPromotion;
};

export const computeLabels = (
  labelsFromPromotion: Labels,
  showFreeTrial: boolean,
  values?: any
): Labels => {
  if (!showFreeTrial) {
    labelsFromPromotion = createTrialLabels(labelsFromPromotion);
  }

  if (values && values.subscriptionPlanCopyOverrides) {
    for (const key of Object.keys(values.subscriptionPlanCopyOverrides)) {
      labelsFromPromotion[key] = values.subscriptionPlanCopyOverrides[key];
    }
  }

  labelsFromPromotion = fillPlaceholderValues(labelsFromPromotion, values);
  return values
    ? fillPlaceholderValues(labelsFromPromotion, values)
    : labelsFromPromotion;
};

export const updateImagePathWithExternalData = (
  labelsFromPromotion: Labels,
  pathKey: string,
  replacementPath: string
): Labels => {
  labelsFromPromotion = computeLabels(labelsFromPromotion, true);
  labelsFromPromotion[pathKey] = replacementPath;
  return labelsFromPromotion;
};
