import React from "react";
import { NavLink } from "react-router-dom";

import { Labels } from "../../../../store/promotion";

import { Path } from "../../../../helpers/path/constants";

import { getWhyTryMoshiContent } from "../../data/content";
import checkIcon from "../../images/check.svg";
import TryMoshiPhoneImage from "./TryMoshiPhoneImage";

import "./TryMoshi.scss";

const TryMoshi = ({
  labels,
  showFreeTrial,
  onClick,
}: {
  labels: Labels | undefined;
  showFreeTrial: boolean;
  onClick: (s: string) => void;
}) => {
  const checkListContent = getWhyTryMoshiContent(showFreeTrial);

  const ctaClick = () => {
    onClick("try-moshi");
  };

  return (
    <div className="try-moshi">
      <NavLink
        to={Path.PAYMENT}
        className="btn action filled mobile"
        onClick={ctaClick}
      >
        {(showFreeTrial
          ? labels?.pageSubscribeMainCtaButton
          : labels?.pageSubscribeMainCtaButtonNoTrial) || "Sign up"}
      </NavLink>
      <TryMoshiPhoneImage />
      <div className="try-moshi-features-wrapper">
        <h2 className="try-moshi-features-title">Why try?</h2>
        {checkListContent.map((item) => (
          <div className="try-moshi-feature" key={item.id + "-wrapper"}>
            <img
              className="try-moshi-feature-check"
              key={item.id + "-image"}
              src={checkIcon}
              alt="Check mark"
            />
            <p className="try-moshi-feature-text" key={item.id}>
              {item.content}
            </p>
          </div>
        ))}
        <NavLink
          to={Path.PAYMENT}
          className="btn action filled desktop"
          onClick={ctaClick}
        >
          {(showFreeTrial
            ? labels?.pageSubscribeMainCtaButton
            : labels?.pageSubscribeMainCtaButtonNoTrial) || "Sign up"}
        </NavLink>
      </div>
    </div>
  );
};

export default TryMoshi;
