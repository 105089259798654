import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { ApplicationState } from "./store";

import { canUpgrade } from "../shared/helpers/user/helpers";
import { freeGeoCountries } from "./helpers/constants";
import { Path } from "./helpers/path/constants";

import Login from "./components/auth/login/Login";
import ConfirmResetPassword from "./components/auth/resetPassword/ConfirmResetPassword";
import RequestPasswordReset from "./components/auth/resetPassword/ReqestResetPassword";
import Signup from "./components/auth/signup/Signup";
import LinkWithSpotify from "./components/auth/spotify/LinkWithSpotify";
import withRedirectToSchoolContent from "./components/higherOrderComponents/schools/withRedirectToSchoolContent";
import AlexaLandingPage from "./components/landing/AlexaLandingPage";
import LandingPage from "./components/landing/LandingPage";
import SchoolsLanding from "./components/landing/SchoolsLanding";
import Payment from "./components/payment/Payment";
import PaymentConfirmation from "./components/payment/PaymentConfirmation";
import { PaymentUpdatePage } from "./components/payment/PaymentUpdate";
import Menu from "./components/player/menu/Menu";
import BedtimePlayer from "./components/player/players/bedtime/BedtimePlayer";
import MultiPlayer from "./components/player/players/multi/MultiPlayer";
import PartnersMultiPlayer from "./components/player/players/multi/PartnersMultiPlayer";
import SinglePlayer from "./components/player/players/single/SinglePlayer";
import Profile from "./components/profile/Profile";
import CodeConfirmation from "./components/promotion/codes/CodeConfirmation";
import RedeemCode from "./components/promotion/codes/RedeemCode";
import SchoolSignupConfirmation from "./components/schools/SchoolSignupConfirmation";
import ErrorPage from "shared/components/error/ErrorPage";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";

const ProtectedRoute = ({
  isAllowed,
  redirectTo = "/",
  hash,
  ...props
}: {
  isAllowed?: boolean;
  redirectTo?: string;
  hash?: string;
  path: string;
  [key: string]: unknown;
}) => {
  return isAllowed ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{ pathname: redirectTo, hash: hash, state: { from: props.path } }}
      from={props.path}
    />
  );
};

const Router = () => {
  const { auth, profile, promotion } = useSelector(
    (state: ApplicationState) => state
  );

  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  const hasAuth = !!auth.user; // logged in or temp user
  const isLoggedIn = auth.isLoggedIn;
  const hasPromotion = !!promotion.data;
  const hasProfile = !!profile; // logged in and api returned profile
  const hasSubscription = profile.SubscriptionData?.subscriptionValid;
  const userCanUpgrade = profile.SubscriptionData
    ? canUpgrade(profile.SubscriptionData)
    : false;
  const isGrantedFreeSubscription = profile.UserData?.country
    ? Object.values(freeGeoCountries).includes(profile.UserData.country)
    : false;

  const signupRedirect = () => {
    if (auth.targetLocation) {
      return auth.targetLocation;
    } else if (hasPromotion) {
      return Path.PAYMENT;
    } else {
      return Path.LANDING;
    }
  };
  const paymentRedirect = () => {
    if (isGrantedFreeSubscription) {
      return Path.PAYMENT_CONFIRMATION;
    } else if (hasSubscription && profile.SubscriptionData) {
      return Path.PROFILE;
    } else if (promotion.data?.showLogin) {
      return Path.LOGIN;
    }
    return Path.SIGNUP;
  };

  if (!auth.isLoaded) {
    return (
      <div className="app-body" title="Loading data..">
        <LoadingIndicator />
        <span className="text-center">Loading...</span>
      </div>
    );
  }
  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <Switch>
        <ProtectedRoute
          path={Path.SIGNUP}
          exact={true}
          isAllowed={!hasAuth}
          redirectTo={signupRedirect()}
          component={Signup}
        />

        <ProtectedRoute
          path={Path.RESET_PASSWORD_SET_NEW}
          isAllowed={!isLoggedIn}
          exact={true}
          redirectTo={hasPromotion ? Path.PAYMENT : Path.LANDING}
          component={ConfirmResetPassword}
        />
        <ProtectedRoute
          path={Path.RESET_PASSWORD}
          isAllowed={!isLoggedIn}
          exact={true}
          redirectTo={hasPromotion ? Path.PAYMENT : Path.LANDING}
          component={RequestPasswordReset}
        />
        <Route path={Path.LOGIN} component={Login} />

        <ProtectedRoute
          path={Path.PAYMENT}
          exact={true}
          isAllowed={
            !isGrantedFreeSubscription &&
            hasAuth &&
            (!hasSubscription || userCanUpgrade)
          }
          redirectTo={paymentRedirect()}
          hash={"#subscription"}
          component={Payment}
        />
        <ProtectedRoute
          path={Path.PAYMENT_UPDATE}
          exact={true}
          isAllowed={isLoggedIn && hasAuth}
          redirectTo={Path.SIGNUP}
          component={PaymentUpdatePage}
        />
        <ProtectedRoute
          path={Path.PAYMENT_CONFIRMATION}
          exact={true}
          isAllowed={hasAuth}
          component={PaymentConfirmation}
        />

        <ProtectedRoute
          path={Path.PROFILE}
          isAllowed={isLoggedIn && hasProfile}
          redirectTo={Path.LOGIN}
          exact={true}
          component={Profile}
        />

        <Route exact={true} path={Path.REDEEM_CODE_LANDING_LEGACY}>
          <Redirect to={Path.REDEEM_CODE_LANDING} />
        </Route>
        <Route
          path={Path.REDEEM_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />

        <ProtectedRoute
          path={Path.REDEEM_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />
        <Route
          path={Path.REDEEM_CODE_CONFIRMATION}
          exact={true}
          component={CodeConfirmation}
        />

        <Route
          path={Path.PLUSH_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <ProtectedRoute
          path={Path.PLUSH_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />
        <ProtectedRoute
          path={Path.PLUSH_CODE_PAYMENT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={Payment}
        />
        <Route
          path={Path.PLUSH_CODE_CONFIRMATION}
          exact={true}
          component={CodeConfirmation}
        />

        <Route path={Path.REFERRAL_CODE_LANDING} component={LandingPage} />

        <ProtectedRoute
          path={Path.PARTNER_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />
        <ProtectedRoute
          path={Path.PARTNER_CODE_PAYMENT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={Payment}
        />

        <ProtectedRoute
          path={Path.LIBRARY_CODE_INPUT}
          exact={true}
          isAllowed={hasAuth}
          redirectTo={Path.SIGNUP}
          component={RedeemCode}
        />

        <Route path={Path.PARTNER_PLAYLIST} component={PartnersMultiPlayer} />
        <Route
          path={Path.PARTNER_CODE_LANDING + "/:partner"}
          component={LandingPage}
        />
        <Route
          path={Path.TRACKING_CODE_LANDING + "/:partner"}
          component={LandingPage}
        />
        <Route
          path={Path.LIBRARY_CODE_LANDING + "/:partner"}
          component={LandingPage}
        />

        <Route path={Path.LEGACY_SCHOOL_SIGNUP}>
          <Redirect to={Path.SCHOOL_SIGNUP} />
        </Route>
        <ProtectedRoute
          path={Path.SCHOOL_SIGNUP}
          exact={true}
          isAllowed={!hasAuth}
          redirectTo={auth.targetLocation ? auth.targetLocation : Path.PROFILE}
          component={Signup}
        />

        <Route
          path={Path.SCHOOL_CONFIRMATION}
          component={SchoolSignupConfirmation}
        />

        <Route path={Path.LEGACY_SONG_LIST_SCHOOLS}>
          <Redirect to={Path.SONG_LIST_SCHOOLS} />
        </Route>
        <Route path={Path.SONG_LIST_SCHOOLS} component={Menu} />

        <Route path={Path.LEGACY_SONG_SCHOOLS + "/:songId"}>
          <Redirect to={Path.SONG_SCHOOLS + "/:songId"} />
        </Route>
        <Route path={Path.SONG_SCHOOLS + "/:songId"} component={SinglePlayer} />
        <Route path={Path.LEGACY_PLAYLIST_SCHOOLS + "/:playlistId"}>
          <Redirect to={Path.PLAYLIST_SCHOOLS + "/:playlistId"} />
        </Route>
        <Route
          path={Path.PLAYLIST_SCHOOLS + "/:playlistId"}
          component={MultiPlayer}
        />
        <Route
          path={Path.REDEEM_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <Route
          path={Path.PLUSH_CODE_LANDING}
          component={LandingPage}
          exact={true}
        />
        <Route path={Path.REFERRAL_CODE_LANDING} component={LandingPage} />
        <Route
          path={Path.PARTNER_CODE_LANDING + "/:partner"}
          component={LandingPage}
        />

        <Route path={Path.LEGACY_SCHOOL_LANDING}>
          <Redirect to={Path.SCHOOL_LANDING} />
        </Route>
        <Route
          exact={true}
          path={Path.SCHOOL_LANDING}
          component={withRedirectToSchoolContent(SchoolsLanding)}
        />

        <Route
          exact={true}
          path={Path.ALEXA_PARENTAL_GATE}
          component={AlexaLandingPage}
        />

        <Route
          exact={true}
          path={Path.LINK_WITH_SPOTIFY}
          component={LinkWithSpotify}
        />

        <Route
          exact={true}
          path={Path.BEDTIME_STORIES}
          component={BedtimePlayer}
        />
        <Route exact={true} path={[Path.BEDTIME_STORY, Path.BEDTIME]}>
          <Redirect to={Path.BEDTIME_STORIES} />
        </Route>

        <Route path={Path.ERROR} component={ErrorPage} />
        <Route path={Path.LANDING} component={LandingPage} />
        <Redirect to={Path.LANDING} from={""} />
      </Switch>
    </React.Suspense>
  );
};

export default Router;
