import { MenuItem } from "./types";

export const navigationMenuItems: MenuItem[] = [
  {
    id: "play",
    label: "Play",
    href: process.env.REACT_APP_WEB_URL + "/moshi-play",
  },
  {
    id: "relax",
    label: "Relax",
    href: process.env.REACT_APP_WEB_URL + "/relax",
  },
  {
    id: "listen",
    label: "Listen",
    href: process.env.REACT_APP_WEB_URL + "/listen",
  },
  {
    id: "sleep",
    label: "Sleep",
    href: process.env.REACT_APP_WEB_URL + "/moshi-sleep",
  },
  {
    id: "science",
    label: "Research",
    href: process.env.REACT_APP_WEB_URL + "/research",
  },
  {
    id: "articles",
    label: "Blog",
    href: process.env.REACT_APP_WEB_URL + "/articles-groups",
  },
];

export const mobileNavigationMenuItems: MenuItem[] = [
  {
    id: "home",
    label: "Home",
    href: process.env.PUBLIC_URL,
  },
  {
    id: "play",
    label: "Play",
    href: process.env.REACT_APP_WEB_URL + "/moshi-play",
  },
  {
    id: "relax",
    label: "Relax",
    href: process.env.REACT_APP_WEB_URL + "/relax",
  },
  {
    id: "listen",
    label: "Listen",
    href: process.env.REACT_APP_WEB_URL + "/listen",
  },
  {
    id: "sleep",
    label: "Sleep",
    href: process.env.REACT_APP_WEB_URL + "/moshi-sleep",
  },
  {
    id: "science",
    label: "Research",
    href: process.env.REACT_APP_WEB_URL + "/research",
  },
  {
    id: "articles",
    label: "Blog",
    href: process.env.REACT_APP_WEB_URL + "/articles-groups",
  },
];
