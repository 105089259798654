import { Action, AnyAction, Dispatch, combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";

import { AuthState, authSagas } from "shared/store/auth";
import { PagesState, pagesReducer } from "shared/store/pages";

import {
  ContentApiDataState,
  ContentState,
  contentApiDataReducer,
  contentReducer,
  contentSagas,
} from "./content";
import {
  ProfileState,
  authReducer,
  profileReducer,
  profileSagas,
} from "./profile";
import { PromotionState, promotionReducer } from "./promotion";
import { VersionState, versionReducer, versionSagas } from "./version";

// The top-level state object
export interface ApplicationState {
  auth: AuthState;
  profile: ProfileState;
  promotion: PromotionState;
  content: ContentState;
  contentApiData: ContentApiDataState;
  pages: PagesState;
  version: VersionState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = combineReducers<ApplicationState>({
  auth: authReducer,
  profile: profileReducer,
  promotion: promotionReducer,
  content: contentReducer,
  contentApiData: contentApiDataReducer,
  pages: pagesReducer,
  version: versionReducer,
});

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(profileSagas),
    fork(contentSagas),
    fork(versionSagas),
  ]);
}

export const SITE_KEY: string = process.env.REACT_APP_SITE_KEY!;
