import * as Sentry from "@sentry/browser";
import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { ContentApiDataState } from "../../../clients/store/content";
import { getContentApiDataFromStore } from "../../../clients/store/content/selectors";

import { setContentApiData } from "../../../clients/helpers/cache/cache";

import { authSetAction } from "./actions";
import {
  AuthMissing,
  cognitoUserToAuthState,
  getCognitoUser,
  logoutCognitoUser,
} from "./api";
import { AuthState, AuthActionsTypes as actions } from "./types";

function* authFetchSaga() {
  try {
    const cognitoUser = yield call(getCognitoUser);
    const authState: AuthState = yield call(
      cognitoUserToAuthState,
      cognitoUser
    );

    yield put(authSetAction({ ...authState, isLoaded: true }));
  } catch (e) {
    if (e instanceof AuthMissing) {
      yield put(
        authSetAction({
          isLoggedIn: false,
          user: undefined,
          isLoaded: true,
        })
      );
    } else {
      Sentry.captureException(e);
      yield put(
        authSetAction({
          isLoaded: true,
          isLoggedIn: false,
          user: undefined,
        })
      );
    }
  }
}

function* authLogoutSaga() {
  try {
    yield call(logoutCognitoUser);
    // clear user specific caches
    const contentApiData: ContentApiDataState = yield select(
      getContentApiDataFromStore
    );
    const loggedOutContentApiData: Partial<ContentApiDataState> = {
      isLoading: false,
      lastUpdatedStaticContentMillis:
        contentApiData.lastUpdatedStaticContentMillis,
      lastUpdatedDynamicContentMillis: 0,
      lastUpdatedFavoritesMillis: 0,
      lastUpdatedSchoolPlaylistsMillis: 0,
      data: {
        static: contentApiData.data?.static,
      },
    };
    yield call(setContentApiData, loggedOutContentApiData);
  } catch (e) {
    Sentry.captureException(e);
  }

  delete axios.defaults.headers.common.Authorization;

  // cognito will do a logout at this point
  // so the next assign is not really helpful
  yield put(
    authSetAction({
      isLoggedIn: false,
      isLoaded: true,
      user: undefined,
    })
  );
}

export function* authSagas() {
  yield takeLatest(actions.AUTH_FETCH, authFetchSaga);
  yield takeLatest(actions.AUTH_LOGOUT, authLogoutSaga);
}
