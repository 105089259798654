import React from "react";
import { NavLink } from "react-router-dom";

import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import * as Yup from "yup";

import { Path } from "../../../../helpers/path/constants";

import FieldButton from "../../../../../shared/components/forms/elements/FieldButton";
import FieldError from "../../../../../shared/components/forms/elements/fieldError";

interface RequestResetPasswordFormProps {
  sendRequest: (v: IRequestResetPassword) => Promise<void>;
}

export interface IRequestResetPassword {
  email: string;
}

const RequestPasswordResetFormTemplate = (
  props: FormikProps<IRequestResetPassword>
) => {
  const { errors, touched, values, handleBlur, handleChange, isSubmitting } =
    props;

  return (
    <Form>
      <div className="form-group">
        <Field
          name="email"
          className={
            errors.email && touched.email
              ? "form-control  with-error"
              : "form-control "
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          type="email"
          placeholder="Email"
        />
        {errors.email && touched.email && <FieldError message={errors.email} />}
      </div>
      <div className="text-center font-bold mb-3 mt-2">
        No account?
        <NavLink to={Path.SIGNUP} className="nav-link small font-bold">
          Sign up
        </NavLink>
      </div>
      <FieldButton
        text={"Submit"}
        withLoading={isSubmitting}
        extra={
          <div className="component-after text-center regular font-bold mb-3">
            No account?
            <NavLink to={Path.SIGNUP} className="nav-link small font-bold">
              Sign up
            </NavLink>
          </div>
        }
      />
    </Form>
  );
};

const RequestResetPasswordForm = withFormik<
  RequestResetPasswordFormProps,
  IRequestResetPassword
>({
  displayName: "ForgotPassword",
  handleSubmit: (
    values: IRequestResetPassword,
    formikBag: FormikBag<RequestResetPasswordFormProps, IRequestResetPassword>
  ) => {
    formikBag.props
      .sendRequest({ ...values } as IRequestResetPassword)
      .catch((e) => {
        formikBag.setErrors({ email: e.message ? e.message : e.toString() });
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: () => {
    return {
      email: "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required("Please enter a valid email address."),
  }),
})(RequestPasswordResetFormTemplate);

export default RequestResetPasswordForm;
