import React from "react";

interface Props {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const Tab = ({ title, activeIcon, inactiveIcon, selected, onClick }: Props) => {
  return (
    <div
      className={`featured-section-tab ${selected ? "selected" : ""}`}
      id={title}
      onClick={onClick}
    >
      <div className="featured-section-text">
        <img src={selected ? activeIcon : inactiveIcon} alt={title} />
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default Tab;
