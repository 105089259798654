import * as Sentry from "@sentry/browser";
import { Severity } from "@sentry/browser";
import * as SentryIntegrations from "@sentry/integrations";
import { Auth } from "aws-amplify";
import md5 from "crypto-js/md5";

import { StoryData } from "../store/content";
import { CATEGORY_IDS } from "../store/content/constants";
import { ProfileState } from "../store/profile";
import { PromotionState } from "../store/promotion";
import { AuthState } from "shared/store/auth";

import { storePromise } from "../../clients";
import { getDeviceId } from "./analytics/device";
import IterableController from "./analytics/iterable";
import { identifyUser } from "./analytics/iterate";
import KinesisController from "./analytics/kinesis";
//import MixpanelController from "./analytics/mixpanel";
import { hasMarketingConsent } from "./cookies/cookies";

declare global {
  interface Window {
    logger: any;
    dataLayer: any;
  }
}

const dataLayer = window.dataLayer;
let Iterable: IterableController;
//let Mixpanel: MixpanelController;
const Kinesis = new KinesisController();

export function reportDialog(options: Sentry.ReportDialogOptions) {
  storePromise.then((store) => {
    const { profile } = store.getState();
    Sentry.showReportDialog({
      user: {
        name: profile.UserData ? profile.UserData.name : "",
        email: profile.UserData ? profile.UserData.email : "",
      },
      ...options,
    });
  });
}

interface CaptureExceptionParams {
  readonly message?: string;
  readonly category?: string;
  readonly extra?: object;
  readonly report?: boolean;
  readonly showReportDialog?: boolean;
}

export function captureException(
  e: Error,
  { category, extra, message, showReportDialog }: CaptureExceptionParams
) {
  Sentry.withScope((scope) => {
    if (extra) {
      scope.setExtra("extra", extra);
    }
    if (message) {
      scope.setExtra("message", message);
    }
    if (category) {
      scope.setTag("category", category);
    }
    const eventId = Sentry.captureException(e);

    console.info(
      "&#1F997; Looks like you've caught a bug: " + category,
      message
    );

    if (showReportDialog) {
      reportDialog({
        eventId,
      });
    }
  });
}

interface CaptureMessageParams {
  readonly level: Sentry.Severity;
  readonly category?: string;
  readonly extra?: object;
}

export function captureMessage(
  message: string,
  { level, category, extra }: CaptureMessageParams
) {
  Sentry.withScope((scope) => {
    if (extra) {
      scope.setExtra("extra", extra);
    }
    if (category) {
      scope.setTag("category", category);
    }
    Sentry.captureMessage(message, level);
  });
  return null;
}

interface CaptureBreadcrumbParams {
  readonly message?: string;
  readonly level?: Sentry.Severity;
  readonly category: string;
  readonly data?: object;
}

export function captureBreadcrumb({
  level,
  category,
  data,
  message,
}: CaptureBreadcrumbParams) {
  Sentry.withScope(() => {
    Sentry.addBreadcrumb({
      category,
      data,
      level,
      message,
    });
  });
}

/**
 *  Convert username to md5
 * @param {string} username
 * @return {string}
 */
function anonymize(username: string) {
  return md5(username);
}

/**
 * Iterable load is unreliable.
 * Need to call again with a slight delay in cases where the script has not loaded into the window
 * techdebt: move to a class / singleton that handles this behaviour as retry, caching needing data values
 */
export function init() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "Request aborted",
      "prerender_initiate_payment_reject", // from paypal buttons iframe
    ],
    beforeSend(event) {
      if (event.exception?.values) {
        // Chrome browser extensions sometimes throw and cause sentry errors to send.
        // In this case, it is not interesting to us. Do not send.
        if (
          event.exception.values.find((value) => {
            value.value?.includes("Extension context invalidated");
          })
        ) {
          return null;
        }
        // Messages with level Debug are not intended for Sentry, log if develop or stage and discard here
        if (event.level === Severity.Debug) {
          if (
            process.env.REACT_APP_ENVIRONMENT === "staging" ||
            process.env.REACT_APP_ENVIRONMENT === "development"
          ) {
            return null;
          }
        }
      }
      return event;
    },
  });
  if (hasMarketingConsent()) {
    Iterable = IterableController.getInstance();
    //Mixpanel = MixpanelController.getInstance();
  }
}

export function setSentryTag(name: string, value: string) {
  Sentry.configureScope((scope) => {
    scope.setTag(name, value);
  });
}

export function setSentryTags(data: { [key: string]: string }) {
  Sentry.configureScope((scope) => {
    scope.setTags(data);
  });
}

function analyticsContentEventDataFromStoryData(storyData: StoryData) {
  return {
    song_id: storyData.id,
    song_name: storyData.name,
    song_type: storyData.contentType,
    SEL_tag: storyData.selTag,
    total_track_duration: storyData.duration,
  };
}

/**
 * Tools for QA and debugging,
 * use these in the browser console/inspector.
 */
window.logger = {
  // manually report a error with the report dialog
  report() {
    storePromise.then((store) => {
      const { auth } = store.getState();
      Sentry.configureScope((scope) => {
        // prevent grouping
        scope.setFingerprint(["manual-report-" + Date.now()]);

        captureException(
          new Error(
            "Manual Report from " +
              (auth && auth.user ? auth.user.username : "anonymous")
          ),
          {
            category: "manual-report",
          }
        );
      });
    });
  },
  // trigger DevTools debugger instead of using console.log
  debugger() {
    Sentry.getCurrentHub().bindClient(
      new Sentry.BrowserClient({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          new SentryIntegrations.Debug({
            debugger: true,
          }),
        ],
      })
    );
  },
};

export const events = {
  login: {
    slug: "moshi.login",
    view() {
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "View",
      });
    },
    success() {
      dataLayer.push({
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: { step: 1, option: "Login" },
          },
        },
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "Log in",
      });
    },
    error(message: string) {
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": message,
      });
    },
    async auth(auth: AuthState) {
      dataLayer.push({
        // we cant use the email per Google
        "moshi.userId": auth.user ? anonymize(auth.user.username) : undefined,
        "moshi.isLoggedIn": auth.isLoggedIn,
      });
      if (auth.user) {
        Iterable?.identifyUser(auth.user.email);
        //Mixpanel?.identifyUser(auth.user.email);
        Kinesis.updateEndpoint(await Auth.currentUserInfo());
        identifyUser(auth.user.email);
      }
      Sentry.configureScope((scope) => {
        scope.setUser({
          // not sent unless there is a issue to report, we need the email to find the user
          username: auth.user ? auth.user.username : undefined,
        });
      });
    },
    profile(profile: ProfileState) {
      if (profile.UserData && profile.UserData.schoolDetails) {
        // Mixpanel?.identifySchool(
        //   profile.UserData.email,
        //   profile.UserData.schoolDetails.schoolName
        // );
      }
      const tags = profile.SubscriptionData
        ? {
            "moshi.subscriptionValid":
              profile.SubscriptionData.subscriptionValid,
            "moshi.isCancelled": profile.SubscriptionData.isCancelled,
            "moshi.trialActive": profile.SubscriptionData.trialActive,
            "moshi.platform": profile.SubscriptionData.platform,
          }
        : {
            "moshi.subscriptionValid": undefined,
            "moshi.isCancelled": undefined,
            "moshi.trialActive": undefined,
            "moshi.platform": undefined,
          };

      Object.entries(tags).forEach(([k, v]) => {
        setSentryTag(k, v ? v.toString() : "");
      });
    },
  },

  signup: {
    slug: "moshi.signup",
    view() {
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "View",
      });
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "StartRegistration",
        },
      });
    },
    success() {
      captureBreadcrumb({
        message: "Success",
        category: this.slug,
        level: Sentry.Severity.Info,
      });
      // facebook
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "CompleteRegistration",
        },
      });
      // enhanced ecommerce
      dataLayer.push({
        event: "checkoutOption",
        ecommerce: {
          checkout_option: {
            actionField: { step: 1, option: "Signup" },
          },
        },
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "Sign up",
      });
    },
    error(message: string) {
      captureBreadcrumb({
        message,
        category: this.slug,
        level: Sentry.Severity.Warning,
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": message,
      });
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "FailRegistration",
        },
      });
    },
  },
  logout: {
    success() {
      //Mixpanel?.resetUser();
    },
  },
  promotion: {
    view(promotion: PromotionState) {
      if (!promotion.data) {
        captureMessage("No promotion data.", {
          level: Sentry.Severity.Critical,
        });
        return;
      }
      // facebook
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "ViewContent",
          data: {
            content_name: promotion.data.promotionSlug,
          },
        },
      });
      dataLayer.push({
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: promotion.data.promotionSlug,
                name: promotion.data.labels.name,
              },
            ],
          },
        },
      });
      setSentryTag(
        "promotion",
        promotion.data ? promotion.data.promotionSlug : ""
      );
    },
    click(promotion: PromotionState, position: string) {
      if (!promotion.data) {
        captureMessage("No promotion data.", {
          level: Sentry.Severity.Critical,
        });
        return;
      }
      // facebook
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "Lead",
          data: {
            content_name: promotion.data.promotionSlug,
          },
        },
      });
      dataLayer.push({
        event: "promotionClick",
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: promotion.data.promotionSlug,
                name: promotion.data.labels.name,
                position,
              },
            ],
          },
        },
      });
    },
  },
  subscribe: {
    slug: "moshi.subscribe",
    // the subscription page was opened
    view(promotion: PromotionState, hasTrial: boolean) {
      if (!promotion.data) {
        captureMessage("No promotion data.", {
          level: Sentry.Severity.Critical,
        });
        return;
      }
      // facebook
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "InitiateCheckout",
          data: {
            content_name: promotion.data.promotionSlug,
          },
        },
      });
      dataLayer.push({
        event: "checkout",
        ecommerce: {
          currencyCode: promotion.data.currencyIsoCode,
          checkout: {
            actionField: {
              step: 2,
              option: hasTrial ? "Trial" : "NoTrial",
            },
            products: [
              {
                name: promotion.data.labels.name,
                id: promotion.data.promotionSlug,
                price: promotion.data.discountPrice,
                quantity: 1,
              },
            ],
          },
        },
      });
    },
    authorized(promotion: PromotionState) {
      if (!promotion.data) {
        captureMessage("No promotion data.", {
          level: Sentry.Severity.Critical,
        });
        return;
      }
      captureBreadcrumb({
        message: "Authorized",
        category: "subscription",
        level: Sentry.Severity.Info,
      });
      dataLayer.push({
        event: "checkout",
        ecommerce: {
          currencyCode: promotion.data.currencyIsoCode,
          checkout: {
            actionField: { step: 3 },
            products: [
              {
                name: promotion.data.labels.name,
                id: promotion.data.promotionSlug,
                price: promotion.data.discountPrice,
                quantity: 1,
              },
            ],
          },
        },
      });
    },
    activated(promotion: PromotionState, hasTrial: boolean) {
      if (!promotion.data) {
        captureMessage("No promotion data.", {
          level: Sentry.Severity.Critical,
        });
        return;
      }
      captureBreadcrumb({
        message: "Activated",
        category: "subscription",
        level: Sentry.Severity.Info,
      });
      dataLayer.push({
        ecommerce: {
          currencyCode: promotion.data.currencyIsoCode,
          purchase: {
            actionField: {
              id: promotion.data.promotionSlug,
              affiliation: "Online Store",
              revenue: promotion.data.discountPrice,
            },
          },
        },
      });
      if (hasTrial) {
        // facebook
        dataLayer.push({
          event: "facebook",
          facebook: {
            track: "StartTrial",
            data: {
              value: parseFloat(promotion.data.discountPrice),
              currency: promotion.data.currencyIsoCode,
              content_name: promotion.data.promotionSlug,
            },
          },
        });
      } else {
        // facebook
        dataLayer.push({
          event: "facebook",
          facebook: {
            track: "Subscribe",
            data: {
              value: parseFloat(promotion.data.discountPrice),
              currency: promotion.data.currencyIsoCode,
              content_name: promotion.data.promotionSlug,
            },
          },
        });
      }
    },
    error(exception: Error) {
      captureException(exception, {
        category: this.slug,
        extra: { exception },
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": exception.message,
      });
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "PaymentFailed",
        },
      });
    },
  },
  updatePayment: {
    slug: "moshi.updatePayment",
    success() {
      captureBreadcrumb({
        category: this.slug,
        message: "Payment method updated",
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "Updated",
      });
    },
    error(exception: Error) {
      captureException(exception, {
        category: this.slug,
        extra: { exception },
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": exception.message,
      });
    },
    authorized() {
      captureBreadcrumb({
        message: "Authorized",
        category: "subscription",
        level: Sentry.Severity.Info,
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "Authorized",
      });
    },
  },

  authorization: {
    slug: "moshi.authorization",
    submitted() {
      captureBreadcrumb({
        message: "Submitted",
        category: "authorization",
        level: Sentry.Severity.Info,
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": "Submitted",
      });
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "AddPaymentInfo",
        },
      });
    },
    success(message: string, paymentType: string) {
      captureBreadcrumb({
        message,
        category: "authorization",
        level: Sentry.Severity.Info,
      });
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": message,
      });
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "AuthorizationSuccess",
          data: {
            payment_type: paymentType,
          },
        },
      });
    },
    error(message: string) {
      dataLayer.push({
        event: this.slug,
        "moshi.eventMessage": message,
      });
      dataLayer.push({
        event: "facebook",
        facebook: {
          track: "AuthorizationFailure",
          data: {
            reason: message,
          },
        },
      });
    },
  },
  download: {
    clicked(platform: string) {
      dataLayer.push({
        event: "moshi.appDownloadClicked",
        "moshi.eventMessage": platform,
      });
    },
  },
  bedtime: {
    played(storyId: string) {
      dataLayer.push({
        event: "moshi.songPlayed",
        "moshi.eventMessage": storyId,
      });
    },
    completed(storyId: string) {
      dataLayer.push({
        event: "moshi.songCompleted",
        "moshi.eventMessage": storyId,
      });
    },
  },
  song: {
    selected(storyData: StoryData, categoryId: string, selTagId?: string) {
      dataLayer.push({
        event: "moshi.songSelected",
        "moshi.eventMessage": storyData.id,
      });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.trackEvent("song_selected", {
          userProfileId,
          ...analyticsContentEventDataFromStoryData(storyData),
          from_menu: categoryId + "_web_menu",
          from_sub_menu: selTagId
            ? selTagId.toLowerCase().replace(" ", "_") + "_category"
            : undefined,
        });
        // Mixpanel?.trackEvent("song_selected", {
        //   userProfileId,
        //   ...analyticsContentEventDataFromStoryData(storyData),
        //   from_menu: categoryId + "_web_menu",
        //   from_sub_menu: selTagId
        //     ? selTagId.toLowerCase().replace(" ", "_") + "_category"
        //     : undefined,
        // });
      });
    },
    played(
      storyData: StoryData,
      categoryId: string,
      selTagId?: string,
      playlistId?: string
    ) {
      dataLayer.push({
        event: "moshi.songPlayed",
        "moshi.eventMessage": storyData.id,
      });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.setProfileParameter("last_played_story_date_v2", new Date());
        Iterable?.trackEvent("song_played", {
          userProfileId,
          ...analyticsContentEventDataFromStoryData(storyData),
          from_menu: categoryId + "_web_menu",
          from_sub_menu: selTagId
            ? selTagId.toLowerCase().replace(" ", "_") + "_category"
            : undefined,
          playlist_id: playlistId,
        });
        // Mixpanel?.trackEvent("song_played", {
        //   userProfileId,
        //   ...analyticsContentEventDataFromStoryData(storyData),
        //   from_menu: categoryId + "_web_menu",
        //   from_sub_menu: selTagId
        //     ? selTagId.toLowerCase().replace(" ", "_") + "_category"
        //     : undefined,
        //   playlist_id: playlistId,
        // });
        Kinesis.trackEvent("song_played", {
          name: "song_played",
          timestamp: Date.now(),
          globalParameters: {
            os: window.navigator.platform,
            profile_id: profile.UserProfile?.userProfileId,
            account_id: profile.UserData?.username,
            ccpa_opt_out: profile.UserData?.optOutOfSale || false,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            country: profile.UserData?.country || "",
            device_id: getDeviceId(),
            client_platform: "web",
          },
          customParameters: {
            story_id: storyData.id,
            song_name: storyData.name,
            song_type: storyData.contentType,
            is_free: false, // TODO: need this from static content
            SEL_tag: storyData.selTag,
            total_track_duration: storyData.duration,
            from_menu: (categoryId || "all") + "_web_menu",
            from_sub_menu: selTagId
              ? selTagId.toLowerCase().replace(" ", "_") + "_category"
              : undefined,
            playlist_id: playlistId,
          },
        });
      });
    },

    completed(
      storyData: StoryData,
      categoryId: string,
      selTagId?: string,
      playlistId?: string
    ) {
      dataLayer.push({
        event: "moshi.songCompleted",
        "moshi.eventMessage": storyData.id,
      });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.trackEvent("song_completed", {
          userProfileId,
          ...analyticsContentEventDataFromStoryData(storyData),
          from_menu: categoryId + "_web_menu",
          from_sub_menu: selTagId
            ? selTagId.toLowerCase().replace(" ", "_") + "_category"
            : undefined,
          playlist_id: playlistId,
        });
        // Mixpanel?.trackEvent("song_completed", {
        //   userProfileId,
        //   ...analyticsContentEventDataFromStoryData(storyData),
        //   from_menu: categoryId + "_web_menu",
        //   from_sub_menu: selTagId
        //     ? selTagId.toLowerCase().replace(" ", "_") + "_category"
        //     : undefined,
        //   playlist_id: playlistId,
        // });
      });
    },
  },
  favorites: {
    addedToFavorites(storyData: StoryData) {
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        const params = analyticsContentEventDataFromStoryData(storyData);
        if (params.total_track_duration) {
          delete params.total_track_duration;
        }
        Iterable?.trackEvent("added_to_favorites_mobile", {
          ...params,
          userProfileId,
        });
        // Mixpanel?.trackEvent("added_to_favorites_mobile", {
        //   ...params,
        //   userProfileId,
        // });
      });
    },
    removedFromFavorites(storyData: StoryData) {
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        const params = analyticsContentEventDataFromStoryData(storyData);
        if (params.total_track_duration) {
          delete params.total_track_duration;
        }
        Iterable?.trackEvent("removed_from_favorites_mobile", {
          ...params,
          userProfileId,
        });
        // Mixpanel?.trackEvent("removed_from_favorites_mobile", {
        //   ...params,
        //   userProfileId,
        // });
      });
    },
  },
  lesson: {
    viewed(
      lessonPlanId: string,
      title: string,
      ageGroup: string,
      type: string
    ) {
      dataLayer.push({
        event: "moshi.lessonViewed",
        "moshi.eventMessage": lessonPlanId,
      });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.trackEvent("lesson_plan_clicked", {
          userProfileId,
          plan_id: lessonPlanId,
          plan_name: title,
          age_group: ageGroup,
          plan_type: type,
          from_action: "viewed",
        });
        // Mixpanel?.trackEvent("lesson_plan_clicked", {
        //   userProfileId,
        //   plan_id: lessonPlanId,
        //   plan_name: title,
        //   age_group: ageGroup,
        //   plan_type: type,
        //   from_action: "viewed",
        // });
      });
    },
    downloaded(
      lessonPlanId: string,
      title: string,
      ageGroup: string,
      type: string
    ) {
      dataLayer.push({
        event: "moshi.lessonDownloaded",
        "moshi.eventMessage": lessonPlanId,
      });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.trackEvent("lesson_plan_clicked", {
          userProfileId,
          plan_id: lessonPlanId,
          plan_name: title,
          age_group: ageGroup,
          plan_type: type,
          from_action: "downloaded",
        });
        // Mixpanel?.trackEvent("lesson_plan_clicked", {
        //   userProfileId,
        //   plan_id: lessonPlanId,
        //   plan_name: title,
        //   age_group: ageGroup,
        //   plan_type: type,
        //   from_action: "downloaded",
        // });
      });
    },
  },
  playlists: {
    selected(playlistId: string) {
      dataLayer.push({
        event: "moshi.playlistSelected",
        "moshi.eventMessage": playlistId,
      });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.trackEvent("playlist_selected", {
          userProfileId,
          playlistId,
          from_menu: CATEGORY_IDS.PLAYLISTS + "_web_menu",
        });
        // Mixpanel?.trackEvent("playlist_selected", {
        //   userProfileId,
        //   playlistId,
        //   from_menu: CATEGORY_IDS.PLAYLISTS + "_web_menu",
        // });
      });
    },
  },
  notification: {
    click(url: string) {
      dataLayer.push({ event: "web_notification_tapped" });
      storePromise.then((store) => {
        const { profile } = store.getState();
        const userProfileId = profile.UserProfile?.userProfileId;
        Iterable?.trackEvent("web_notification_tapped", {
          userProfileId,
          action_url: url,
        });
        // Mixpanel?.trackEvent("web_notification_tapped", {
        //   userProfileId,
        //   action_url: url,
        // });
      });
    },
    shown() {
      //Mixpanel?.trackEvent("web_notification_shown");
    },
  },
};

export function sendOptimizeActivate() {
  dataLayer.push({
    event: "optimize.activate",
  });
}

/**
 * Send a signal to Google Optimize that delayed form rendering is complete and apply changes
 */
export function formReady() {
  dataLayer.push({ event: "optimize.formActive" });
}

export default {
  captureException,
  init,
};
