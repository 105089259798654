interface LegacyStoryIdMap {
  readonly legacyStoryId: string;
  readonly storyId: string;
}
export const MAX_NUM_SIZE_STORY_ID = 4;

export const mapLegacyStoryId = (storyId: string): string | undefined => {
  const map: LegacyStoryIdMap[] =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? require("./storyProdMapping.json")
      : require("./storyStagingMapping.json");
  const mappedStoryIds = map.find(
    (mapping) => mapping.legacyStoryId === storyId
  );
  return mappedStoryIds?.storyId;
};
