import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { sendOptimizeActivate } from "clients/helpers/logger";
import { Path } from "clients/helpers/path/constants";
import { ApplicationState } from "clients/store";

import { IAuthLogin } from "shared/store/auth/api";

import { loginUser } from "../../../helpers/auth/login";
import { LocationState } from "../../../helpers/types";
import { PromotionSlug } from "shared/helpers/campaigns/types";

import LoginForm from "shared/components/forms/auth/LoginForm";
import LoadingIndicator from "shared/components/loading/LoadingIndicator";

import LoginLinks from "../../blocks/componentAfter/LoginLinks";

import "./Login.scss";

const Login = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  const auth = useSelector((state: ApplicationState) => state.auth);
  const profile = useSelector((state: ApplicationState) => state.profile);
  const promotion = useSelector((state: ApplicationState) => state.promotion);

  useEffect(() => {
    if (promotion.data) {
      sendOptimizeActivate();
    }
  }, [promotion.data]);

  const [redirectLocation, setRedirectLocation] = useState("");
  useEffect(() => {
    if (location.state && location.state.from) {
      setRedirectLocation(location.state.from);
    }
    if (profile.SubscriptionData) {
      if (profile.SubscriptionData.isSchoolSubscription) {
        setRedirectLocation(Path.SONG_LIST_SCHOOLS);
      } else if (profile.SubscriptionData.subscriptionValid) {
        setRedirectLocation(Path.PROFILE_SUBSCRIPTION);
      } else if (promotion.data) {
        const promotionSlug = promotion.data.promotionSlug;
        if (promotionSlug === PromotionSlug.REDEEM_CODE) {
          setRedirectLocation(Path.REDEEM_CODE_INPUT);
        } else if (promotionSlug === PromotionSlug.PLUSH_CODE) {
          setRedirectLocation(Path.PLUSH_CODE_INPUT);
        } else if (promotionSlug.includes(PromotionSlug.LIBRARY_CODE_PREFIX)) {
          setRedirectLocation(Path.LIBRARY_CODE_INPUT);
        } else {
          setRedirectLocation(Path.PAYMENT);
        }
      } else {
        setRedirectLocation(Path.PAYMENT);
      }
    }
  }, [profile.SubscriptionData, promotion, location.state]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      if (profile.SubscriptionData && redirectLocation) {
        history.push(redirectLocation);
      }
    }
  }, [auth.isLoggedIn, profile.SubscriptionData, redirectLocation, history]);

  const login = async (loginData: IAuthLogin) => {
    await loginUser(loginData, dispatch, history);
  };

  if (auth.isLoggedIn) {
    if (!profile.SubscriptionData || !redirectLocation) {
      return (
        <div className="app-body">
          <LoadingIndicator />
        </div>
      );
    }
  }

  return (
    <div className={"app-body box login-page center"}>
      <div className="component-box with-header">
        <div className="component-box-header">
          <h1 className="font-heading">Login</h1>
          <p className="subtitle">Login to your Moshi account</p>
        </div>
        <div className="mt-1">
          <LoginForm fieldType="email" login={login} />
        </div>
      </div>
      {!isMobile && <LoginLinks />}
    </div>
  );
};

export default Login;
