import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ApplicationState } from "../../../../store";
import { StoryData } from "../../../../store/content";
import * as contentActions from "../../../../store/content/actions";

import { getStandardLinks } from "../../../../helpers/downloads/constants";
import { events } from "../../../../helpers/logger";

import LoadingIndicator from "../../../../../shared/components/loading/LoadingIndicator";
import Reviews from "../../../landing/components/reviews/Reviews";
import TryMoshiPhoneImage from "../../../landing/components/tryMoshi/TryMoshiPhoneImage";

import { usePromotionLabels } from "../../../../hooks/usePromotionLabels";
import DownloadButton from "../../../download/DownloadButton";
import MarketingItem from "../../../marketing/MarketingItem";
import { Bedtime, Summer } from "../../../marketing/content";
import amazonStoreButton from "../../../payment/images/amazon.svg";
import appleStoreButton from "../../../payment/images/apple.svg";
import googleStoreButton from "../../../payment/images/google.svg";
import StoryDetails from "../story/details/StoryDetails";
import StoryAudioPlayer from "../story/player/StoryAudioPlayer";
import RefreshTimer from "./RefreshTimer";

import "../Player.scss";

const BedtimePlayer = () => {
  const dispatch = useDispatch();

  const { labels, showFreeTrial } = usePromotionLabels();

  useEffect(() => {
    dispatch(contentActions.marketingContentFetchAction());
  }, [dispatch]);

  const content = useSelector((state: ApplicationState) => state.content);
  const downloadLinks = getStandardLinks();
  const [storyData, setStoryData] = useState<StoryData>();
  const [baseUrl, setBaseUrl] = useState<string>();

  useEffect(() => {
    if (content.marketingData) {
      setStoryData(content.marketingData.storyData);
      setBaseUrl(
        `${content.marketingData.baseUrl}/${content.marketingData.storyData.id}/`
      );
    }
  }, [content.marketingData]);

  const sendSongPlayedEvent = () => {
    if (content.marketingData?.storyData) {
      events.bedtime.played(content.marketingData.storyData.id);
    }
  };
  const sendSongCompleteEvent = () => {
    if (content.marketingData?.storyData) {
      events.bedtime.completed(content.marketingData.storyData.id);
    }
  };
  const promotionClick = () => {};

  if (!storyData || !baseUrl) {
    return (
      <div className="app-body">
        <LoadingIndicator />
      </div>
    );
  }

  // fallback when backgroundUrl is not set
  const backgroundUrl = storyData.backgroundUrl || storyData.tileUrl;

  return (
    <div className="center">
      <div className="app-body center player-page bedtime" id="player-page">
        <div className="bedtime-story-title">
          <h1>Moshi's daily bedtime story</h1>
          <p>Come back every evening for a new free bedtime story</p>
        </div>

        <div className="component-box with-header">
          <div className="component-box-header" />
          <div className="container">
            <StoryAudioPlayer
              backgroundUrl={baseUrl + backgroundUrl}
              audioUrl={baseUrl + storyData.audioUrl}
              onPlay={sendSongPlayedEvent}
              onEnded={sendSongCompleteEvent}
            />
            <StoryDetails
              categoryName={storyData.contentTypeName}
              categoryId={storyData.contentType}
              name={storyData.name}
              narrator={storyData.narrator}
              description={storyData.description}
              artist={storyData.artist}
              showShare
            />
          </div>
        </div>
        <RefreshTimer />
      </div>
      <div className="below-player-page">
        <h2>
          Download Moshi to get all of our
          <br /> stories, songs & so much more!
        </h2>
        <div className="download-widget">
          <DownloadButton
            link={downloadLinks.iosLink}
            image={appleStoreButton}
            id="ga-download-apple"
            platform="apple"
            imageAltText="Apple"
          />
          <DownloadButton
            link={downloadLinks.googleLink}
            image={googleStoreButton}
            id="ga-download-google-play"
            platform="google"
            imageAltText="Google Play"
          />
          <DownloadButton
            className="amazon"
            link={downloadLinks.amazonLink}
            id="ga-download-amazon"
            platform="amazon"
            image={amazonStoreButton}
            imageAltText="Amazon"
          />
        </div>
        <TryMoshiPhoneImage />
        <Reviews
          labels={labels}
          showFreeTrial={showFreeTrial}
          onClick={promotionClick}
        />
        <MarketingItem
          title={Bedtime.title}
          body={Bedtime.body}
          image={Bedtime.image}
        />
        <MarketingItem
          title={Summer.title}
          body={Summer.body}
          image={Summer.image}
          reverse
        />
      </div>
    </div>
  );
};

export default BedtimePlayer;
