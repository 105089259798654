import React from "react";

import TryMoshiFeatureCard from "./TryMoshiFeatureCard";

import "./TryMoshiFeatureCards.scss";

const TryMoshiFeatureCards = () => {
  return (
    <div className="try-moshi-feature-card-wrapper">
      <TryMoshiFeatureCard
        backgroundColor="#dff3c9"
        textColor="#11B753"
        featureText="Safe for kids - no ads"
      />
      <TryMoshiFeatureCard
        backgroundColor="#fde9c5"
        textColor="#FF8600"
        featureText="Content for ages 0-10"
      />
      <TryMoshiFeatureCard
        backgroundColor="#c8f4ff"
        textColor="#0F8EB3"
        featureText="100+ characters"
      />
    </div>
  );
};

export default TryMoshiFeatureCards;
