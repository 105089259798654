import React from "react";

import { events } from "../../helpers/logger";

const DownloadButton = ({
  className,
  link,
  id,
  platform,
  image,
  imageAltText = "",
}: {
  className?: string;
  link: string;
  id: string;
  platform: string;
  image?: string;
  imageAltText: string;
}) => {
  const content = () => {
    if (image) {
      return <img src={image} alt={imageAltText} />;
    } else {
      return imageAltText;
    }
  };
  return (
    <a
      className={"ga-event " + className}
      target="_blank"
      id={id}
      rel="noopener noreferrer"
      href={link}
      onClick={() => {
        events.download.clicked(platform);
      }}
    >
      {content()}
    </a>
  );
};

export default DownloadButton;
