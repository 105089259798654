import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { footerItems } from "clients/components/footer/constants";

import { authLogoutAction } from "../../../../shared/store/auth";
import { ApplicationState } from "../../../store";

import { events } from "../../../helpers/logger";

import { mobileNavigationMenuItems } from "../constants";
import NavigationActionsMobile from "./NavigationActionsMobile";
import NavigationButtonMobile from "./NavigationButtonMobile";
import NavigationFooterMobile from "./NavigationFooterMobile";
import NavigationMenuMobile from "./NavigationMenuMobile";

import "./NavigationMobile.scss";

interface Props {
  onMenuToggle: () => void;
  menuOpen: boolean;
}

const NavigationMobile = ({ onMenuToggle, menuOpen }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state: ApplicationState) => state.auth);
  const callLogout = () => {
    dispatch(authLogoutAction());
    // redirect user back to home page to clear promotion
    history.push("/");

    events.logout.success();
    if (menuOpen) {
      onMenuToggle();
    }
  };

  return (
    <React.Fragment>
      <NavigationActionsMobile
        isLoggedIn={auth.isLoggedIn}
        callLogout={callLogout}
        closeMenu={onMenuToggle}
        menuOpen={menuOpen}
      />
      <NavigationButtonMobile onMenuToggle={onMenuToggle} menuOpen={menuOpen} />
      <div className={"navigation-mobile-wrapper" + (menuOpen ? " open" : "")}>
        <NavigationMenuMobile
          menuItems={mobileNavigationMenuItems}
          isLoggedIn={auth.isLoggedIn}
          closeMenu={onMenuToggle}
        />
        <NavigationFooterMobile menuItems={footerItems} />
      </div>
    </React.Fragment>
  );
};

export default NavigationMobile;
