import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { ApplicationState } from "../../store";
import { Labels } from "../../store/promotion";

import { events, sendOptimizeActivate } from "../../helpers/logger";
import { computeLabels } from "../../helpers/promotionLabels";

import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";

import Awards from "../awards/Awards";
import withRenderOnResize from "../higherOrderComponents/layout/withRenderOnResize";
import placeholderImage from "./images/placeholder.png";

import "./Promotion.scss";

interface Props {
  showFreeTrial: boolean;
  shouldUseMobileLayout?: boolean;
}

const Promotion = ({ showFreeTrial, shouldUseMobileLayout }: Props) => {
  const location = useLocation();
  const promotion = useSelector((state: ApplicationState) => state.promotion);

  const [className, setClassName] = useState("#");
  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.split("/").filter((s: string) => s !== "");
      if (path[0] === "subscribe" && path.length > 1) {
        setClassName(path[1]);
      } else {
        setClassName(path[0]);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (promotion.data) {
      sendOptimizeActivate();
    }
  }, [promotion]);

  const [labels, setLabels] = useState<Labels>();
  useEffect(() => {
    if (promotion.data) {
      const combinedValues = {
        ...promotion.partner,
        ...promotion.referral,
        ...promotion.data,
      };

      setLabels(
        computeLabels(promotion.data.labels, showFreeTrial, combinedValues)
      );
    }
  }, [promotion, showFreeTrial]);

  const [impressionLogged, setImpressionLogged] = useState(false);
  useEffect(() => {
    if (!impressionLogged && promotion.data) {
      events.promotion.view(promotion);
      setImpressionLogged(true);
    }
  }, [promotion, impressionLogged]);

  return (
    <div className={`hero-module ${className} center`}>
      {
        <React.Fragment>
          <div className="hero-module-text">
            {labels ? (
              <>
                <h1>{labels.pageSubscribeTitle}</h1>

                <h4>{labels.pageSubscribeParagraphText}</h4>
                {!shouldUseMobileLayout && (
                  <div className="hero-module-cta">
                    <NavLink
                      className="btn action filled"
                      to={labels.pageSubscribeMainCtaButtonHref}
                    >
                      {labels.pageSubscribeMainCtaButton}
                    </NavLink>
                    {labels.pageSubscribeSecondaryCtaButton && (
                      <NavLink
                        className="btn filled cta-dark"
                        to={labels.pageSubscribeSecondaryCtaButtonHref}
                      >
                        {labels.pageSubscribeSecondaryCtaButton}
                      </NavLink>
                    )}
                    {promotion.partner?.partnerLogoImagePath && (
                      <img
                        className="hero-module-text-partner-logo"
                        src={promotion.partner.partnerLogoImagePath}
                        alt={promotion.partner.partnerDisplayName}
                      />
                    )}
                  </div>
                )}
                {!promotion.partner?.partnerLogoImagePath && <Awards />}
              </>
            ) : (
              <LoadingIndicator />
            )}
          </div>
          <div className="hero-module-image">
            <img
              src={
                labels && labels.pageSubscribeHeaderImage
                  ? process.env.REACT_APP_CDN + labels.pageSubscribeHeaderImage
                  : placeholderImage
              }
              alt="Home 1"
            />
          </div>
          {shouldUseMobileLayout && labels && (
            <div className="hero-module-mobile-cta">
              <NavLink
                className="btn action filled"
                to={labels?.pageSubscribeMainCtaButtonHref}
              >
                {labels.pageSubscribeMainCtaButton}
              </NavLink>
              {labels.pageSubscribeSecondaryCtaButton && (
                <NavLink
                  className="btn filled cta-dark"
                  to={labels.pageSubscribeSecondaryCtaButtonHref}
                >
                  {labels.pageSubscribeSecondaryCtaButton}
                </NavLink>
              )}
            </div>
          )}
        </React.Fragment>
      }
      <div className="hero-module-overlay" />
    </div>
  );
};

export default withRenderOnResize(Promotion);
