import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import qs, { ParsedQuery } from "query-string";

import { Path } from "../../../clients/helpers/path/constants";

import Yawnsy from "./images/yawnsey.png";

import "./ErrorPage.scss";

const ErrorPage = () => {
  const location = useLocation();
  const [message, setMessage] = useState(<>Something went wrong.</>);

  useEffect(() => {
    if (location.search) {
      const query: ParsedQuery = qs.parse(location.search);
      if (query.type) {
        switch (query.type) {
          case "404":
            setMessage(
              <>
                Our Moshlings could not find that page. <br /> Please try again
                while they rest up.
              </>
            );
            break;
          case "spotify-generic":
            setMessage(
              <>
                Something went wrong when linking your account.
                <br /> Use the link below to try again.
                <br />
                <br />
                {query.retryLink && (
                  <a
                    className="btn action filled wide"
                    href={query.retryLink as string}
                  >
                    Link with Spotify
                  </a>
                )}
              </>
            );
            break;
          case "spotify-already-linked":
            setMessage(
              <>
                Your Spotify user is already linked to a Moshi account.
                <br /> Check your Spotify user, or contact us for help.
                <br />
                <br />
                <a
                  className="btn action filled wide"
                  href={process.env.REACT_APP_WEB_URL + Path.CONTACT}
                >
                  Contact us
                </a>
              </>
            );
            break;
        }
      }
    }
  }, [location.search]);

  return (
    <div className="app-body error-page">
      <div className="component-box-wrap text-center">
        <h1 className="font-bold">Oh Dear!</h1>
        <h2 className="font-light">{message}</h2>
        <img className="sleeping-moshi" src={Yawnsy} alt="" />
      </div>
    </div>
  );
};

export default ErrorPage;
