import React, { useEffect, useState } from "react";

import { StoryData } from "../../../../store/content";

import { millisecondsToFormattedText } from "../../../../../shared/helpers/time";

import favoriteInactiveIcon from "../../images/Heart@1_5x.svg";
import favoriteActiveIcon from "../../images/Icon_Active_Heart@1_5x.svg";

import "./ContentTile.scss";

interface Props {
  storyData: StoryData;
  tileUrl: string;
  isFavorite: boolean;
  onSelect: (storyId: string) => void;
  onFavorite: (storyId: string) => void;
}
const ContentTile = ({
  storyData,
  tileUrl,
  isFavorite,
  onSelect,
  onFavorite,
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onSelect(storyData.id);
  };

  const [favoriteToggle, setFavoriteToggle] = useState(isFavorite);
  useEffect(() => {
    setFavoriteToggle(isFavorite);
  }, [isFavorite]);

  const handleClickFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setFavoriteToggle(!favoriteToggle);
    onFavorite(storyData.id);
  };

  const { name, contentTypeName, duration, selTag } = storyData;

  return (
    <div className="content-cell" onClick={handleClick}>
      <img
        className={`favorite-heart ${favoriteToggle ? "active" : ""}`}
        src={favoriteToggle ? favoriteActiveIcon : favoriteInactiveIcon}
        alt={favoriteToggle ? "favorite active" : "favorite inactive"}
        onClick={handleClickFavorite}
      />
      <img className="cell-image" src={tileUrl} alt={name} />
      <div className="cell-info">
        <p>{contentTypeName}</p>
        <h5>{name}</h5>
        <p>
          {millisecondsToFormattedText(duration)} {selTag ? "•" : ""}
          {selTag}
        </p>
      </div>
    </div>
  );
};

export default ContentTile;
