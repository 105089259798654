import React, { ComponentType, useEffect } from "react";

function withNoFooter<T>(WrappedComponent: ComponentType) {
  return (props: T) => {
    useEffect(() => {
      const footer = document.getElementById("footer");
      if (footer) {
        footer.style.display = "none";
      }
      return () => {
        if (footer) {
          footer.style.display = "block";
        }
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
}

export default withNoFooter;
