import React, { useState } from "react";

import SelectButton from "./SelectButton";

import "./PromotionSelect.scss";

interface PaymentPlanButtonLabels {
  pagePaymentPlanButtonTitle: string;
  pagePaymentPlanButtonBadge: string;
  pagePaymentPlanButtonBody: string;
}

const PromotionSelect = ({
  promotionLabels,
  alternativePromotionLabels,
  setAltPromotionSelected,
}: {
  promotionLabels: PaymentPlanButtonLabels;
  alternativePromotionLabels: PaymentPlanButtonLabels;
  setAltPromotionSelected: (selectedAlternative: boolean) => void;
}) => {
  const [altSelected, setAltSelected] = useState(false);
  const onClick = (alternativeSelected: boolean) => {
    setAltSelected(alternativeSelected);
    setAltPromotionSelected(alternativeSelected);
  };

  return (
    <React.Fragment>
      <div className="promotion-select">
        <SelectButton
          title={promotionLabels.pagePaymentPlanButtonTitle}
          badgeText={promotionLabels.pagePaymentPlanButtonBadge}
          body={promotionLabels.pagePaymentPlanButtonBody}
          selected={!altSelected}
          value={false}
          onClick={onClick}
        />
        <SelectButton
          title={alternativePromotionLabels.pagePaymentPlanButtonTitle}
          badgeText={alternativePromotionLabels.pagePaymentPlanButtonBadge}
          body={alternativePromotionLabels.pagePaymentPlanButtonBody}
          selected={altSelected}
          value={true}
          onClick={onClick}
        />
      </div>
    </React.Fragment>
  );
};

export default PromotionSelect;
