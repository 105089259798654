import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as querystring from "querystring";

import { IAuthLogin } from "../../../../shared/store/auth/api";
import { ApplicationState } from "../../../store";

import { loginUser } from "../../../helpers/auth/login";

import LoginForm from "../../../../shared/components/forms/auth/LoginForm";
import LoadingIndicator from "../../../../shared/components/loading/LoadingIndicator";
import Questions from "../../landing/components/questions/Questions";

import LoginLinks from "../../blocks/componentAfter/LoginLinks";
import withNoFooter from "../../higherOrderComponents/layout/withNoFooter";
import withNoHeader from "../../higherOrderComponents/layout/withNoHeader";
import { getSpotifyQuestionsAndAnswers } from "../../landing/data/content";

import "./LinkWithSpotify.scss";

const LinkWithSpotify = () => {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const REDIRECT_URI =
    (process.env.REACT_APP_API_ENDPOINT ||
      process.env.REACT_APP_CLIENTS_API_ENDPOINT) + "/spotify/register-user";

  const history = useHistory();
  const dispatch = useDispatch();

  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    if (auth.isLoggedIn && auth.user) {
      const state = auth.user.username;

      const scope = "user-soa-link";
      window.location.href =
        "https://accounts.spotify.com/authorize?" +
        querystring.stringify({
          response_type: "code",
          client_id: CLIENT_ID,
          scope: scope,
          redirect_uri: REDIRECT_URI,
          state: state,
          show_dialog: false,
        });
    }
  }, [CLIENT_ID, REDIRECT_URI, auth.isLoggedIn, auth.user]);

  const login = async (loginData: IAuthLogin) => {
    await loginUser(loginData, dispatch, history);
  };

  return (
    <div className="app-body login-page center">
      {/*  Wait for Auth to load */}
      {!auth.isLoaded && <LoadingIndicator />}

      {/*  No Auth - Show special login page*/}
      {auth.isLoaded && !auth.isLoggedIn && (
        <React.Fragment>
          <div className="component-box with-header">
            <div className="component-box-header">
              <h1 className="font-heading">Link with Spotify</h1>
              <p className="subtitle">Login to your Moshi account</p>
            </div>
            <div className="mt-1">
              <LoginForm fieldType="email" login={login} />
            </div>
          </div>
          <div className="hide-on-mobile">
            <LoginLinks />
          </div>
          <div className="white">
            <Questions questions={getSpotifyQuestionsAndAnswers()} />
          </div>
        </React.Fragment>
      )}

      {/*  When Auth - Loading and redirect*/}
      {auth.isLoaded && auth.isLoggedIn && <LoadingIndicator />}
    </div>
  );
};

export default withNoHeader(withNoFooter(LinkWithSpotify));
