import React from "react";

import { StoryData } from "../../../../../store/content";

import { millisecondsToFormattedText } from "shared/helpers/time";

import favoriteInactiveIcon from "../../../images/Heart@1_5x.svg";
import favoriteActiveIcon from "../../../images/Icon_Active_Heart@1_5x.svg";

import "./PlaylistTrackCell.scss";

interface Props {
  storyData: StoryData;
  isFavorite: boolean;
  baseUrl: string;
  isCurrentlyPlaying: boolean;
  onSelect: (storyId: string) => void;
  onFavorite?: (storyId: string) => void;
}
const PlaylistTrackCell = ({
  storyData,
  isFavorite,
  baseUrl,
  isCurrentlyPlaying,
  onSelect,
  onFavorite,
}: Props) => {
  const storyAssetsUrl = `${baseUrl}/${storyData.id}/`;

  const onClickRow = () => {
    onSelect(storyData.id);
  };

  const onClickFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onFavorite) {
      onFavorite(storyData.id);
    }
  };

  return (
    <div
      className={`playlist-track-container ${
        isCurrentlyPlaying ? "playing" : ""
      }`}
      onClick={onClickRow}
    >
      <img
        className="image"
        src={storyAssetsUrl + storyData.tileUrl}
        alt={storyData.name}
        height={96}
      />
      <div className="info">
        <p className="type">{storyData.contentTypeName}</p>
        <p className="name">{storyData.name}</p>
        <p className="duration">
          {millisecondsToFormattedText(storyData.duration)}
        </p>
      </div>

      <div className="icons">
        {onFavorite && (
          <div
            className={`favorite ${isFavorite ? "active" : ""}`}
            id="add-to-favorites-button"
            onClick={onClickFavorite}
          >
            <img
              className="favorite-heart"
              src={isFavorite ? favoriteActiveIcon : favoriteInactiveIcon}
              alt={isFavorite ? "favorite active" : "favorite inactive"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaylistTrackCell;
