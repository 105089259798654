import React from "react";

const webPrefix = process.env.REACT_APP_WEB_URL || "";

export const Terms = () => {
  return (
    <a
      href={webPrefix + "/terms-conditions"}
      className="ga-event"
      id="ga-link-twilight-terms"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms
    </a>
  );
};

export const Privacy = () => {
  return (
    <a
      href={webPrefix + "/privacy-policy"}
      className="ga-event"
      id="ga-link-twilight-privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
  );
};
