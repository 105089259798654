import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { ApplicationState } from "../../../store";

import { MenuItem } from "../types";

interface Props {
  menuItems: MenuItem[];
  isLoggedIn: boolean;
  closeMenu: () => void;
}
const NavigationMenuMobile = ({ menuItems, isLoggedIn, closeMenu }: Props) => {
  const isSchoolSubscription = useSelector(
    (state: ApplicationState) =>
      state.profile.SubscriptionData?.isSchoolSubscription
  );

  return (
    <ul className="navigation-menu-mobile">
      {menuItems.map((item) => (
        <li id={item.id} key={item.id} className="navigation-menu-mobile-item">
          <a href={item.href} onClick={closeMenu}>
            {item.label}
          </a>
        </li>
      ))}
      {!isLoggedIn && (
        <li id="login" key="login" className="navigation-menu-mobile-item">
          <NavLink to="/login" onClick={closeMenu}>
            Login
          </NavLink>
        </li>
      )}
      {isSchoolSubscription && (
        <li
          id="web-player"
          key="web-player"
          className="navigation-menu-mobile-item"
        >
          <NavLink to="/schools/menu" onClick={closeMenu}>
            Educator dashboard
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default NavigationMenuMobile;
