export const getStepsByPromotion = (promotionSlug: string): number => {
  switch (promotionSlug) {
    case "sleepies":
    case "redeem":
      return 4;
    case "schools":
      return 2;
    default:
      return 3;
  }
};
