export enum Default {
  WEB_CLIENT_VERSION = "web-3.1.0",
  SUBSCRIPTION_PLAN = "standard",
  PAYPAL_BUTTON_COLOUR = "white",
}

export const freeGeoCountries = {
  UKRAINE: "UA",
  ROMANIA: "RO",
  HUNGARY: "HU",
  SLOVAKIA: "SK",
  POLAND: "PL",
  MOLDOVA: "MD",
};
