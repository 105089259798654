import { Reducer } from "redux";

import { hook } from "../../helpers/hooks";

import { PromotionActions } from "./actions";
import { PromotionActionsTypes, PromotionState } from "./types";

const reducer: Reducer<PromotionState, PromotionActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case PromotionActionsTypes.PROMOTION_SET: {
      /**
       * Google Optimize might want to give the user some other text.
       *  Check out the backend API promotion configuration file for possible
       *   fields but mostly we want to just change the labels account.
       *
       *   Note: changing the name of the promotion will result in a redirect.
       */
      const promotion = hook("promotion-set", action.payload);
      return {
        ...state,
        ...promotion,
        selectedAlternativePromotion: false,
      };
    }
    case PromotionActionsTypes.PROMOTION_SELECT: {
      const alternativeSelected = hook("promotion-select", action.payload);
      return {
        ...state,
        selectedAlternativePromotion: alternativeSelected,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as promotionReducer };
