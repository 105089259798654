import React, { FunctionComponent, useEffect } from "react";

function withNoHeader<T>(WrappedComponent: FunctionComponent) {
  return (props: T) => {
    useEffect(() => {
      const header = document.getElementById("header");
      if (header) {
        header.style.display = "none";
      }
      return () => {
        if (header) {
          header.style.display = "block";
        }
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
}

export default withNoHeader;
