/**
 * Typescript 3.9.8 has no definition for randomUUID on Crypto.
 * Fix until typescript can be updated to > 4
 */
declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

/**
 * Generates a pseudo unique device id using crypto library
 * It is no possible to uniquely identify a browser, however we can generate a unique identifier and store it in the broser storage, recalling it when a user returns
 * Caveats: will not work if browsing privately; will change if a user clears data / cache; could be edited by user, browser plugin etc.
 */
export function getDeviceId(): string {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = crypto.randomUUID();
    localStorage.setItem("deviceId", deviceId);
  }
  return deviceId;
}
