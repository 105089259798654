import api from "../api";
import {
  SubscriptionCancelInstructions,
  SubscriptionRefundInstructions,
} from "./types";

export async function getCancelSubscriptionInstructions(): Promise<SubscriptionCancelInstructions> {
  return api.get("/subscription/cancel-instructions");
}

export async function getRefundSubscriptionInstructions(): Promise<SubscriptionRefundInstructions> {
  return api.get("/subscription/refund-instructions");
}
