import React from "react";

import "./PlaylistDetails.scss";

interface Props {
  name: string;
  description: string;
  tags?: Set<string>;
}

const PlaylistDetails = ({ name, description, tags }: Props) => {
  return (
    <div className="playlist-details">
      <h1 className="title">{name}</h1>
      <p className="description">{description}</p>
      {tags && (
        <div className="tags">
          {Array.from(tags).map((tag) => (
            <p key={tag} className="pill">
              {tag}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlaylistDetails;
