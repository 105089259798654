import React, { useEffect, useRef, useState } from "react";

import { useQRCode } from "next-qrcode";

import { getLoginQrCodeData } from "../../../../store/schools/api";
import { LoginQrCodeData } from "../../../../store/schools/types";

import { millisecondsToFormattedText } from "shared/helpers/time";

import LoadingIndicator from "shared/components/loading/LoadingIndicator";

import LogoDark from "./images/no-img.svg";

import "./LinkedDevices.scss";

const LinkedDevices = () => {
  const { Canvas } = useQRCode();

  const [loginQrCodeData, setLoginQrCodeData] = useState<LoginQrCodeData>();

  useEffect(() => {
    const getQrCodeData = async () => {
      const qrCodeData = await getLoginQrCodeData();

      setLoginQrCodeData(qrCodeData);
    };
    getQrCodeData();
  }, []);

  const timeToExpireRef = useRef<HTMLParagraphElement>(null);
  const [codeNeedsRefresh, setCodeNeedsRefresh] = useState(false);
  useEffect(() => {
    const secondsTimer = setInterval(() => {
      if (timeToExpireRef.current) {
        const msUntilExpire = loginQrCodeData
          ? loginQrCodeData.expiringTimeMillis - Date.now()
          : 0;
        if (msUntilExpire > 0) {
          timeToExpireRef.current.innerText =
            millisecondsToFormattedText(msUntilExpire);
        } else {
          timeToExpireRef.current.innerText = "QR Code expired";
          setCodeNeedsRefresh(true);
        }
      }
    }, 60 * 1000);
    return () => clearInterval(secondsTimer);
  }, [loginQrCodeData]);

  const [isLoading, setIsLoading] = useState(false);
  const resetQrCode = async () => {
    setIsLoading(true);
    const qrCodeData = await getLoginQrCodeData();
    setLoginQrCodeData(qrCodeData);
    setCodeNeedsRefresh(false);
    setIsLoading(false);
  };

  return (
    <div className="qr-login-container">
      <div className="qr-login-instructions">
        <p>Linked devices: {loginQrCodeData?.totalStudents || 0}</p>
        <h3>Steps to link device</h3>
        <ol>
          <li>
            Download the Moshi app on the student device (currently only
            available for iPads).
          </li>
          <li>
            Open the camera on the iPad and point it at the QR code on this
            screen.
          </li>
          <li>Tap the yellow link that appears on the QR Code.</li>
          <li>Moshi app will open to a profile screen on the iPad.</li>
        </ol>
      </div>
      <div className="qr-code">
        {loginQrCodeData && (
          <>
            <Canvas
              text={loginQrCodeData.schoolLoginQrCode}
              logo={
                codeNeedsRefresh
                  ? undefined
                  : { src: LogoDark, options: { width: 80 } }
              }
              options={{
                margin: 1,
                width: 352,
                color: {
                  dark: codeNeedsRefresh ? "#999999" : "#010b3b",
                  light: "#ffffff",
                },
              }}
            />
            <p ref={timeToExpireRef}>
              Time to expire:{" "}
              {millisecondsToFormattedText(
                loginQrCodeData.expiringTimeMillis - Date.now()
              )}
            </p>
            {codeNeedsRefresh && (
              <>
                {!isLoading && (
                  <div className="button-container">
                    <button className="btn action filled" onClick={resetQrCode}>
                      Refesh login
                    </button>
                  </div>
                )}
                {isLoading && (
                  <div className="loading-container">
                    <LoadingIndicator fill="#010b3b" />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {!loginQrCodeData && (
          <svg viewBox="0 0 352 427" width="352px" height="427px">
            <rect x={0} y={0} width={352} height={352} fill="#f1e9ff80" />
            <rect x={0} y={372} width={352} height={31} fill="#f1e9ff80" />
          </svg>
        )}
      </div>
    </div>
  );
};

export default LinkedDevices;
