import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { search } from "./helpers/api";

import SearchIcon from "../../images/search.svg";

import "./ContentSearch.scss";

interface Props {
  onSearchSuccessful: (storyIds: string[], playlistIds: string[]) => void;
  onSearchUnsuccessful: (storyIds: string[], noResultsMessage: string) => void;
  onSearchEmpty: () => void;
}

const ContentSearch = forwardRef(
  (
    { onSearchSuccessful, onSearchUnsuccessful, onSearchEmpty }: Props,
    ref: Ref<{
      clearInputValue: () => void;
    }>
  ) => {
    const [inputValue, setInputValue] = useState<string>("");

    const onSearch = async () => {
      if (inputValue) {
        const response = await search(inputValue);
        if (response.resultsFound) {
          onSearchSuccessful(
            response.storyIdsResults || [],
            response.playlistIdsResults || []
          );
        } else {
          onSearchUnsuccessful(
            response.noResultsSuggestions || [],
            response.noResultMessage
          );
        }
      } else {
        onSearchEmpty();
      }
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => onSearch(), 500);
      return () => {
        clearTimeout(timeoutId);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue /*, onSearch*/]);

    const updateInputValue = (event: React.FormEvent<HTMLInputElement>) => {
      setInputValue(event.currentTarget.value);
    };

    const clearInputValue = () => {
      setInputValue("");
    };

    useImperativeHandle(ref, () => {
      return { clearInputValue };
    });

    const handleKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        onSearch();
      }
    };

    return (
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search"
          value={inputValue}
          onChange={updateInputValue}
          onKeyPress={handleKeyPress}
        />
        <button className="search-button" onClick={onSearch}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>
    );
  }
);

export default ContentSearch;
