import braintree from "braintree-web";

import { events } from "../../../helpers/logger";
import { rejectAfterLookup, rejectAtLookup } from "../../../helpers/payment";
import { braintreeGetClientToken } from "../../../helpers/payment/api/braintree";

import { PaymentForm, PaymentFormProps, PaymentFormState } from "./PaymentForm";

interface OnLookupCompletePayload {
  threeDSecureInfo: {
    liabilityShifted: boolean;
    liabilityShiftPossible: boolean;
  };
  paymentMethod: {
    threeDSecureInfo: {
      liabilityShifted: boolean;
      liabilityShiftPossible: boolean;
      status: string;
      enrolled: "Y" | "N";
    };
  };
}

interface ThreeDSecureInfo {
  amount: string;
  email?: string;
  merchantAccount: string;
}

interface PaymentFormSecureProps extends PaymentFormProps {
  threeDSecureInfo: ThreeDSecureInfo;
}

interface PaymentFormSecureState extends PaymentFormState {
  amount: string;
}

export class PaymentFormSecure extends PaymentForm<
  PaymentFormSecureProps,
  PaymentFormSecureState
> {
  protected threeDsecure: braintree.ThreeDSecure | null = null;

  private readonly amount: string;

  private readonly email?: string;

  private readonly merchantAccountSlug: string;

  constructor(props: PaymentFormSecureProps) {
    super(props);
    this.amount = props.threeDSecureInfo.amount;
    this.email = props.threeDSecureInfo.email;
    this.merchantAccountSlug = props.threeDSecureInfo.merchantAccount;
    this.state = {
      ...this.state,
      amount: this.props.threeDSecureInfo.amount,
    };
  }

  public updateAmount(newAmount: string) {
    this.setState({ amount: newAmount });
    this.forceUpdate();
  }

  public async tokenize() {
    if (!this.hostedFields) {
      return Promise.resolve();
    }

    events.authorization.submitted();

    const { hostedFields, fields, onToken, onLookupComplete } = this;
    return new Promise((resolve, reject) => {
      hostedFields.tokenize(
        { cardholderName: fields.cardholderName.inputNode?.current?.value },
        (tokenizeErr, tokenizationPayload) => {
          if (tokenizeErr) {
            reject(tokenizeErr);
          } else if (this.threeDsecure) {
            const threeDSecureParameters = {
              nonce: tokenizationPayload?.nonce || "",
              bin: tokenizationPayload?.details.bin || "",
              amount: parseFloat(this.state.amount),
              email: this.email,
              onLookupComplete,
            };

            this.threeDsecure.verifyCard(
              threeDSecureParameters,
              (err, verifyPayload) => {
                if (err) {
                  reject(err);
                  return;
                }
                if (verifyPayload?.liabilityShifted) {
                  // Liability has shifted
                  events.authorization.success(
                    "Card authorized",
                    "card 3D secure"
                  );
                  onToken(verifyPayload);
                  resolve();
                } else {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const statusCode = verifyPayload?.threeDSecureInfo.status;
                  if (rejectAfterLookup(statusCode)) {
                    const threeDSecureFailedError = {
                      message:
                        statusCode === "challenge_required"
                          ? "Payment cancelled"
                          : "Failed to authorize payment",
                    };
                    reject(threeDSecureFailedError);
                  } else if (verifyPayload) {
                    events.authorization.success(
                      "Card authorized with no liability shift",
                      "card 3D secure"
                    );
                    onToken(verifyPayload);
                    resolve();
                  }
                }
              }
            );
          }
        }
      );
    });
  }

  protected async onLookupComplete(
    lookupPayload: OnLookupCompletePayload,
    next: () => Record<string, unknown>
  ) {
    if (lookupPayload.threeDSecureInfo.liabilityShifted) {
      next();
    } else {
      const statusCode = lookupPayload.paymentMethod.threeDSecureInfo.status;
      if (rejectAtLookup(statusCode)) {
        this.threeDsecure?.cancelVerifyCard((err) => {
          if (err) {
            // cannot cancel verify
            throw new Error(err.message);
          }
          // pass reason to verify callback
          next();
        });
      } else {
        // pass through to verify callback to handle
        next();
      }
    }
  }

  protected async setAuthorization() {
    const result = await braintreeGetClientToken(this.merchantAccountSlug);
    await super.setAuthorization(result.clientToken);
    if (this.braintreeClient) {
      this.threeDsecure = await braintree.threeDSecure.create({
        client: this.braintreeClient,
        version: 2,
      });
    }
  }
}

export default PaymentFormSecure;
