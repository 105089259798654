import React from "react";

import { Review } from "./types";

import "./ReviewCard.scss";

interface Props {
  review: Review;
  backgroundColor: string;
  className?: string;
}
const ReviewCard = ({ review, backgroundColor, className }: Props) => {
  const style = {
    backgroundColor: backgroundColor,
  };
  return (
    <div className={`review-card ${className}`} style={style}>
      <h1 className="quote">"</h1>
      <p className="review">{review.review}</p>
      <div>
        <p className="author">{review.author}</p>
        <p className="age">
          {review.location}
          {review.age ? ` - ${review.age} years old` : ""}
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;
