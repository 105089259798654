import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { MessagePayload, NotificationPayload } from "firebase/messaging";

import { ApplicationState } from "../../store";

import Firebase from "../../helpers/notifications/firebase";

import Notification from "./Notification";

const Notifications = () => {
  const auth = useSelector((state: ApplicationState) => state.auth);
  const [show, setShow] = useState(false);
  const [link, setLink] = useState("https://www.moshikids.com");
  const [notification, setNotification] = useState<NotificationPayload | any>({
    title: "",
    body: "",
    image: "",
  });

  useEffect(() => {
    if (auth.isLoaded && auth.isLoggedIn) {
      const firebase = Firebase.getInstance();
      firebase.getMessagingToken(auth.user?.email);
      firebase.onMessageListener().then((payload: MessagePayload) => {
        if (payload.fcmOptions && payload.fcmOptions.link) {
          setLink(payload.fcmOptions.link);
        }
        if (payload.notification) {
          setNotification(payload.notification);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 5000);
        }
      });
    }
  }, [auth]);

  return <Notification notification={notification} link={link} show={show} />;
};

export default Notifications;
