import React, { useState } from "react";

import { DropDownOption } from "./types";

interface Props {
  option: DropDownOption;
  onClick: (id: string) => void;
  isSelected?: boolean;
}

const DropDownItem = ({ option, onClick, isSelected = false }: Props) => {
  const [optionId] = useState(option.id || option.value);
  const handleSelected = () => {
    onClick(optionId as string);
  };

  return (
    <div
      className={`option ${isSelected ? "selected" : ""}`}
      id={"drop-down-option-" + optionId}
      key={optionId}
      onClick={handleSelected}
    >
      <p className="option-text" key={optionId}>
        {option.label}
      </p>
    </div>
  );
};

export default DropDownItem;
