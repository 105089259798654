import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { ApplicationState } from "../../store";

import {
  ConfirmationDownloads,
  getConfirmationDownloadsByPromotion,
  getStandardLinks,
} from "../../helpers/downloads/constants";
import { Path } from "../../helpers/path/constants";

import LoadingIndicator from "../../../shared/components/loading/LoadingIndicator";

import amazonStoreButton from "../payment/images/amazon.svg";
import appleStoreButton from "../payment/images/apple.svg";
import googleStoreButton from "../payment/images/google.svg";
import DownloadButton from "./DownloadButton";

import "./Download.scss";

const Download = () => {
  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const [downloadLinks, setDownloadLinks] = useState<ConfirmationDownloads>(
    getStandardLinks()
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (promotion.data) {
      setDownloadLinks(getConfirmationDownloadsByPromotion(promotion));
    }
  }, [promotion, promotion.data]);

  useEffect(() => {
    if (downloadLinks) {
      setIsLoading(false);
    }
  }, [downloadLinks]);

  if (isLoading) {
    return (
      <React.Fragment>
        <LoadingIndicator />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="download-widget">
          <div>
            <DownloadButton
              link={downloadLinks.iosLink}
              id="ga-download-apple"
              platform="apple"
              image={appleStoreButton}
              imageAltText="Apple"
            />
          </div>
          <div>
            <DownloadButton
              link={downloadLinks.googleLink}
              id="ga-download-google-play"
              platform="google"
              image={googleStoreButton}
              imageAltText="Google Play"
            />
          </div>
          <div>
            <DownloadButton
              className="amazon"
              link={downloadLinks.amazonLink}
              id="ga-download-amazon"
              platform="amazon"
              image={amazonStoreButton}
              imageAltText="Amazon"
            />
          </div>
        </div>
        <div className="links">
          Also available on{" "}
          <NavLink
            to={
              Path.LINK_WITH_SPOTIFY +
              "?spotifyShowUri=spotify:show:3eUU6tZmEWcK17k8vfTMuK"
            }
          >
            Spotify
          </NavLink>{" "}
          and{" "}
          <a
            href={
              promotion.data?.countryCode === "GB"
                ? "https://www.amazon.co.uk/dp/B0B1W18K64"
                : "https://www.amazon.com/dp/B0B1W18K64"
            }
          >
            Alexa
          </a>
        </div>
      </React.Fragment>
    );
  }
};

export default Download;
