import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Path } from "clients/helpers/path/constants";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";

import { IAuthLogin, Schema } from "shared/store/auth/api";

import LoginLinks from "../../../../clients/components/blocks/componentAfter/LoginLinks";

import FieldButton from "../elements/FieldButton";
import FieldError from "../elements/fieldError";

export interface LoginFormProps {
  login: (u: IAuthLogin) => Promise<any>;
  fieldType: string;
}

const LoginFormTemplate = (props: LoginFormProps & FormikProps<IAuthLogin>) => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const { errors, touched, isSubmitting, fieldType } = props;

  return (
    <Form>
      <div className="form-group" onFocus={() => {}}>
        <Field
          id="username"
          name="username"
          type={fieldType}
          className={
            errors.username && touched.username
              ? "form-control email with-error"
              : "form-control email"
          }
          maxLength={128}
          placeholder="Email Address"
        />
        {errors.username && touched.username && (
          <FieldError message={errors.username} />
        )}
      </div>

      <div className="form-group mb-3">
        <Field
          name="password"
          type={showPassword ? "text" : "password"}
          className={
            errors.password && touched.password
              ? "form-control with-error"
              : "form-control"
          }
          maxLength={128}
          placeholder="Password"
        />
        <FontAwesomeIcon
          className="password-icon"
          icon={showPassword ? faEyeSlash : faEye}
          onClick={() => setShowPassword(!showPassword)}
        />
        {errors.password && touched.password ? (
          <FieldError message={errors.password} />
        ) : (
          <div className="info-message">Min 8 characters and 1 number</div>
        )}
      </div>
      <div className="text-center regular font-bold mb-3">
        No account?
        <NavLink
          to={{ pathname: Path.SIGNUP, state: location.pathname }}
          className="nav-link small font-bold"
        >
          Sign up
        </NavLink>
      </div>
      <FieldButton
        text={"Login"}
        withLoading={isSubmitting}
        extra={<LoginLinks />}
      />
    </Form>
  );
};

const LoginForm = withFormik<LoginFormProps, IAuthLogin>({
  displayName: "LoginForm",
  handleSubmit: (
    values: IAuthLogin,
    formikBag: FormikBag<LoginFormProps, IAuthLogin>
  ) => {
    formikBag.props
      .login({ ...values } as IAuthLogin)
      .then()
      .catch((e) => {
        formikBag.setSubmitting(false);
      });
  },
  mapPropsToValues: () => {
    return {
      username: "",
      password: "",
    };
  },
  validationSchema: Schema,
})(LoginFormTemplate);

export default LoginForm;
