import React, { useEffect, useRef, useState } from "react";

import { closeDropDown, openDropDown } from "./helpers/dropDownHelper";

import { useOutsideAlert } from "../../../hooks/useOutsideAlert";
import DropDownItem from "./DropDownItem";
import downIcon from "./images/down.svg";
import { DropDownOption } from "./types";

import "./DropDownMenu.scss";

interface Props {
  noSelectionTitle: string;
  options: DropDownOption[];
  onSelect: (selection: string, type?: string) => void;
  preSelectedOption?: string;
  allowClear?: boolean;
  selectionType?: string;
  disabled?: boolean;
  reverse?: boolean;
}

const DropDownMenu = ({
  noSelectionTitle,
  options,
  onSelect,
  preSelectedOption,
  allowClear,
  selectionType,
  disabled = false,
  reverse = false,
}: Props) => {
  const [selection, setSelection] = useState<string | undefined>(
    preSelectedOption
  );
  const [selectionLabel, setSelectionLabel] = useState<string | undefined>();
  useEffect(() => {
    if (selection) {
      const selectedOption = options.find(
        (option) => (option.id || option.value) === selection
      );
      setSelectionLabel(selectedOption?.label);
    }
  }, [selection, options]);

  const clearSelection = () => {
    setSelection(preSelectedOption);
    setSelectionLabel(undefined);
    onSelect(preSelectedOption || "", selectionType);
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const optionsContainer = useRef<HTMLDivElement>(null);
  const toggleMenu = () => {
    if (optionsContainer.current) {
      if (isOpen) {
        openDropDown(optionsContainer.current);
      } else {
        closeDropDown(optionsContainer.current);
      }
    }
    setIsOpen(!isOpen);
  };

  /**
   * Automatically close dropdown if user clicks anywhere outside
   */
  const ref = useRef(null);
  useOutsideAlert(ref, () => {
    if (optionsContainer.current && isOpen) {
      toggleMenu();
    }
  });

  const onChange = (id: string) => {
    if (id === selection) {
      setSelection("");
      onSelect("", selectionType);
    } else {
      setSelection(id);
      onSelect(id, selectionType);
    }
    toggleMenu();
  };

  let dropDownClass = "drop-down";
  if (isOpen) {
    dropDownClass += " open";
  } else if (selection) {
    dropDownClass += " with-selection";
  }

  return (
    <div
      ref={ref}
      className={dropDownClass}
      id={selectionType + "-drop-down-toggle"}
      style={disabled ? { pointerEvents: "none" } : {}}
    >
      <div className="drop-down-title" onClick={toggleMenu}>
        <p className="title-text">{selectionLabel || noSelectionTitle}</p>
        <img className="drop-down-arrow" src={downIcon} alt="down arrow" />
      </div>

      <div
        className={`options single ${reverse ? "reverse" : ""}`}
        ref={optionsContainer}
      >
        {options.map((option) => (
          <DropDownItem
            option={option}
            onClick={onChange}
            key={option.id || option.value}
          />
        ))}
        {allowClear && (
          <p className="clear-selection" onClick={clearSelection}>
            Clear selection
          </p>
        )}
      </div>
    </div>
  );
};

export default DropDownMenu;
