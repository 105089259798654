import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import qs, { ParsedQuery } from "query-string";

import { ApplicationState } from "./store";
import {
  PartnerInfo,
  PromotionState,
  ReferralInfo,
  promotionSetAction,
} from "./store/promotion";
import { PromotionNotFound, getPromotion } from "./store/promotion/api";
import {
  partnerForget,
  promoCodeForget,
  promoCodeRecall,
  promoCodeRemember,
  promotionRecall,
  promotionRemember,
} from "./store/promotion/local";

import { saveTagManagerParametersToLocalStorage } from "./helpers/analytics/tagManager";
import { Default, freeGeoCountries } from "./helpers/constants";
import logger, { captureException } from "./helpers/logger";
import { SpecialPromotionsByPath } from "./helpers/path/constants";
import {
  getCountryOverrideFromQueryString,
  getPartnerInfoFromPath,
  getPromotionSlugFromPath,
  getPromotionSlugFromQueryString,
  getReferralDetailsFromPath,
  getReferralDetailsFromQueryString,
  getSpecialPromotionSlugFromPath,
  shouldGetPartnerFromPath,
  shouldGetPromotionFromPath,
  shouldGetReferralFromPath,
  shouldGetSpecialPromoFromPath,
} from "./helpers/promotion/PromotionHelper";

import ModalAlert from "../shared/components/modal/ModalAlert";
import WhatsApp from "./components/contact/WhatsApp";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Notifications from "./components/notifications/Notifications";

import Router from "./Router";

import "./App.scss";

interface CookieBot {
  consent: {
    marketing: string;
  };
}
declare global {
  interface Window {
    Cookiebot: CookieBot;
  }
}

const App = () => {
  const dataLayer = window.dataLayer;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalActionText, setModalActionText] = useState("");

  const showModalAlert = (
    title: string,
    description: string,
    actionText = "Ok"
  ) => {
    setModalTitle(title);
    setModalDescription(description);
    setModalActionText(actionText);
    setModalOpen(true);
  };
  const onModalClose = () => {
    setModalOpen(false);
    setModalTitle("");
    setModalDescription("");
    setModalActionText("");
  };

  const promotion = useSelector((state: ApplicationState) => state.promotion);
  const promotionSet = useCallback(
    (newPromotion: PromotionState) =>
      dispatch(promotionSetAction(newPromotion)),
    [dispatch]
  );

  const redirectToDefault = useCallback(() => {
    history.replace("/subscribe");
    partnerForget();
    promotionRemember(Default.SUBSCRIPTION_PLAN);
    return Default.SUBSCRIPTION_PLAN;
  }, [history]);

  const canBailEarly = useCallback(
    (
      promotionSlug: string | undefined,
      countryOverride: string | undefined
    ): boolean => {
      let bail = false;
      if (
        !promotionSlug ||
        (promotion.data && promotion.data.promotionSlug === promotionSlug)
      ) {
        bail = true;
      }
      if (
        bail &&
        countryOverride &&
        (!promotion.data || promotion.data.countryCode !== countryOverride)
      ) {
        bail = false;
      }
      return bail;
    },
    [promotion.data]
  );

  const onLandingPage = useCallback(() => {
    return (
      location.pathname.includes("/subscribe") || location.pathname === "/"
    );
  }, [location.pathname]);

  const loadPromotion = useCallback(
    async (search: string, init: boolean) => {
      let promotionSlug: string | undefined;
      let countryOverride: string | undefined = promotion.country;
      let partnerPromoData: PartnerInfo | undefined;
      let referralDetails: ReferralInfo | undefined;

      if (search) {
        const query: ParsedQuery = qs.parse(search);
        if (query) {
          if (query.promo) {
            promotionSlug = getPromotionSlugFromQueryString(query);
          }
          if (query.country) {
            countryOverride = getCountryOverrideFromQueryString(query);
            promotionSet({ country: countryOverride });
          }
          if (query.code) {
            // referral code from deeplink
            promotionSlug = "referral";
            referralDetails = await getReferralDetailsFromQueryString(query);
          }
        }
      }

      if (location.pathname) {
        const path = location.pathname
          .split("/")
          .filter((s: string) => s !== "");
        if (!promotionSlug && shouldGetPromotionFromPath(path)) {
          promotionSlug = getPromotionSlugFromPath(path);
        } else if (shouldGetPartnerFromPath(location.pathname, path)) {
          try {
            partnerPromoData = await getPartnerInfoFromPath(path);
          } catch (e) {
            showModalAlert("Oh no!", e.message);
          }
          if (partnerPromoData) {
            promotionSlug = partnerPromoData.promotionSlug;
            promoCodeRemember(partnerPromoData.promoCode);
          } else {
            promotionSlug = redirectToDefault();
          }
        } else if (shouldGetReferralFromPath(path) && !referralDetails) {
          referralDetails = await getReferralDetailsFromPath(path);
          if (referralDetails) {
            promotionSlug = referralDetails.subscriptionSku;
          } else {
            promotionSlug = redirectToDefault();
          }
        } else if (shouldGetSpecialPromoFromPath(path)) {
          promotionSlug = getSpecialPromotionSlugFromPath(path);
        }
      }

      if (
        countryOverride &&
        Object.values(freeGeoCountries).includes(countryOverride)
      ) {
        promotionSlug = "free";
      }
      if (!init && canBailEarly(promotionSlug, countryOverride)) {
        return;
      }

      if (!promotionSlug) {
        if (!document.referrer.includes("moshikids.com")) {
          promotionSlug = promotionRecall();
        }
        if (
          onLandingPage() &&
          !location.pathname.includes(promotionSlug as string) &&
          Object.values(SpecialPromotionsByPath).includes(
            promotionSlug as string
          )
        ) {
          promotionSlug = Default.SUBSCRIPTION_PLAN;
        }
      }
      try {
        // the promotion has changed on reload - clear promotion specific store
        if (promotionSlug !== promotionRecall()) {
          promoCodeForget();
          partnerForget();
        }
        if (partnerPromoData && !partnerPromoData?.isPromoCodeValid) {
          redirectToDefault();
          return;
        }
        const promoCode = partnerPromoData
          ? partnerPromoData.promoCode
          : referralDetails
          ? referralDetails.referralCode
          : promoCodeRecall() || undefined;

        const newPromotion = await getPromotion(promotionSlug, countryOverride);
        if (newPromotion.promotionNotAvailableMessage) {
          toast.error(newPromotion.promotionNotAvailableMessage);
        }

        dispatch(
          promotionSetAction({
            error: undefined,
            data: newPromotion,
            partner: partnerPromoData,
            referral: referralDetails,
            promoCode,
          })
        );

        // set promotion slug in dataLayer for optimize
        dataLayer.push({ promo: newPromotion.promotionSlug });
        promotionRemember(newPromotion.promotionSlug);
      } catch (e) {
        if (
          e instanceof PromotionNotFound &&
          promotionSlug &&
          promotionSlug !== Default.SUBSCRIPTION_PLAN
        ) {
          toast.error("Could not find promotion");
          redirectToDefault();
        } else {
          // Failed to load default promotion. Something is wrong.
          dispatch(promotionSetAction({ data: undefined, error: e }));
          toast.error("Error loading data");
          captureException(e, {
            category: "braintree.promotion",
          });
          return;
        }
      }
    },
    [
      canBailEarly,
      dataLayer,
      dispatch,
      location.pathname,
      onLandingPage,
      promotion.country,
      promotionSet,
      redirectToDefault,
    ]
  );

  const init = useRef(true);
  // loadPromotion is resetting it's value at some point causing many set promotion calls.
  useEffect(() => {
    loadPromotion(location.search, init.current).then(
      () => (init.current = false)
    );
    saveTagManagerParametersToLocalStorage(location.search);
  }, [loadPromotion, location.search]);

  /**
   * Reload logger when marketing cookies are set load iterable and mixpanel
   */
  useEffect(() => {
    const onCookieAccept = () => {
      if (window.Cookiebot.consent.marketing) {
        logger.init();
      }
    };
    window.addEventListener("CookiebotOnAccept", onCookieAccept);
    return () => {
      window.removeEventListener("CookiebotOnAccept", onCookieAccept);
    };
  }, []);

  return (
    <div className="app-container">
      <ToastContainer autoClose={15000} />
      <Notifications />
      <WhatsApp />
      <Header />
      <Router />
      <Footer />
      <ModalAlert
        isOpen={modalOpen}
        title={modalTitle}
        description={modalDescription}
        actionText={modalActionText}
        close={onModalClose}
      />
    </div>
  );
};

export default App;
