import Api from "clients/helpers/api";

import { ProfileUpdateParameters } from "shared/helpers/user/types";

import { GetCurrentUserApiResponse, ProfileState } from "../types";

/**
 * Get the current user from the api
 */
export async function getCurrentUser(): Promise<ProfileState> {
  const result = (await Api.get(
    "/user/current-user"
  )) as GetCurrentUserApiResponse;
  const { UserData, SubscriptionData } = result;
  return {
    UserData: UserData ? UserData : null,
    SubscriptionData: SubscriptionData ? SubscriptionData : null,
  } as ProfileState;
}

export async function putCurrentUser(
  params: ProfileUpdateParameters
): Promise<ProfileState> {
  return (await Api.put("/user/current-user", {
    ...params,
  })) as ProfileState;
}

export async function deleteCurrentUser() {
  return Api.del("/user/current-user");
}

export default {
  getCurrentUser,
  putCurrentUser,
  deleteCurrentUser,
};
