import { CATEGORY_IDS, CONTENT_TYPES } from "../constants";
import {
  CategoryData,
  DynamicContentApiResponse,
  SchoolPlaylist,
  SeriesEpisodeData,
  StaticContentApiResponse,
  StoryData,
} from "../types";

const categoryOrder = [
  CATEGORY_IDS.MOMENTS,
  CATEGORY_IDS.MEDITATIONS,
  CATEGORY_IDS.STORIES,
  CATEGORY_IDS.MUSIC,
  CATEGORY_IDS.SOUNDS,
  CATEGORY_IDS.SERIES,
  CATEGORY_IDS.BREATHE,
  CATEGORY_IDS.LULLABY,
];

const orderedCategories: { [key: string]: number } = categoryOrder.reduce(
  (accumulator: Record<string, number>, key: string, currentIndex: number) => {
    return Object.assign(accumulator, { [key]: currentIndex });
  },
  {}
);

function getCategoryDataFromApiResponse(
  dynamicContentApiResponse: DynamicContentApiResponse
): CategoryData[] {
  const filteredScreens = [
    ...dynamicContentApiResponse.filterData[0].filteredScreens,
    ...dynamicContentApiResponse.filterData[1].filteredScreens,
  ];
  const seriesScreen = filteredScreens.find(
    (filteredScreen) => filteredScreen.id === CATEGORY_IDS.SERIES
  );
  if (seriesScreen) {
    filteredScreens.splice(filteredScreens.indexOf(seriesScreen), 1);
  }
  return ([] as CategoryData[]).concat(
    ...filteredScreens.map((screen) => {
      const allViewForScreen = screen.screenViews.find((view) =>
        view.name.match(/all/i)
      );
      return {
        id: screen.id,
        name: screen.name,
        storyIds: allViewForScreen?.storyIds || [],
      };
    })
  );
}

function removeDuplicateDataInCategories(categoryData: CategoryData[]) {
  return categoryData.filter((category, index) => {
    return index === categoryData.findIndex((obj) => obj.id === category.id);
  });
}

function mergeDuplicatedCategories(
  categoryData: CategoryData[]
): CategoryData[] {
  const filteredCategoryData = categoryData.map((category) => {
    const entries = categoryData.filter((f) => {
      return f.id === category.id;
    });
    if (entries.length > 1) {
      return {
        id: category.id,
        name: category.name,
        storyIds: Array.from(
          new Set(entries[0].storyIds.concat(entries[1].storyIds))
        ),
      };
    }
    return entries[0];
  });
  return removeDuplicateDataInCategories(filteredCategoryData);
}

function createSeriesCategory(
  storyData: StoryData[],
  seriesData: SeriesEpisodeData[]
): CategoryData {
  const seriesCategory = {
    id: CATEGORY_IDS.SERIES,
    name: "Series",
    storyIds: [] as string[],
  };
  const activeSeriesStoryData = storyData.filter((story) => {
    if (seriesData.find((series) => series.id === story.id)) {
      return story;
    }
  });

  seriesCategory.storyIds = activeSeriesStoryData.map((story) => story.id);

  return seriesCategory;
}

function createAllCategory(storyData: StoryData[]): CategoryData {
  const filteredData = storyData.filter(
    (story) => story.contentType !== CONTENT_TYPES.SERIES
  );
  const storyIds = Array.from(
    new Set(
      filteredData.map((story) => {
        return story.id;
      })
    )
  );
  return { id: "all", name: "All", storyIds };
}

export function getOrderedContentCategories(
  staticContentApiResponse: StaticContentApiResponse,
  dynamicContentApiResponse: DynamicContentApiResponse,
  storyData: StoryData[],
  schoolPlaylistsData: SchoolPlaylist[]
) {
  const categoryData = mergeDuplicatedCategories(
    getCategoryDataFromApiResponse(dynamicContentApiResponse)
  );
  categoryData.sort(
    (a, b) => orderedCategories[a.id] - orderedCategories[b.id]
  );
  categoryData.push(
    createSeriesCategory(storyData, staticContentApiResponse.seriesData)
  );
  categoryData.unshift({
    id: "playlists",
    name: "Playlists",
    storyIds: schoolPlaylistsData.map((playlist) => playlist.id),
  });

  categoryData.unshift(createAllCategory(storyData));
  return categoryData;
}
