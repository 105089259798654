export interface VersionApiResponse {
  readonly forceUpdate: boolean;
  readonly lastUpdatedAllUserProfilesMillis: number;
  readonly lastUpdatedContentMillis: number;
  readonly lastUpdatedCurrentUserProfileMillis: number;
  readonly lastUpdatedCustomPlaylistsMillis: number;
  readonly lastUpdatedDynamicContentMillis: number;
  readonly lastUpdatedFavoritesMillis: number;
  readonly lastUpdatedFiltersMillis: number;
  readonly lastUpdatedRecommendedPlaylistsMillis: number;
  readonly lastUpdatedSchoolPlaylistsMillis: number;
  readonly lastUpdatedReferralsMillis: number;
  readonly lastUpdatedStaticContentMillis: number;
  readonly lastUpdatedSubscriptionPlansMillis: number;
  readonly showAttPopUp: boolean;
}

export interface VersionState {
  isLoading?: boolean;
  readonly data?: VersionApiResponse;
}

export enum VersionActionTypes {
  VERSION_SET = "@@version/VERSION_SET",
  VERSION_FETCH = "@@version/VERSION_FETCH",
}
