import React, { createRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import FormButton from "./FormButton";

import "./FieldButton.scss";

interface Props {
  text: string;
  extra?: JSX.Element;
  withLoading?: boolean;
}
const FieldButton = ({ text, extra, withLoading = false }: Props) => {
  const actionFooterRef = createRef<HTMLDivElement>();
  const [currentTopPosition, setCurrentTopPosition] = useState<number>();
  const [className, setClassName] = useState<string>("action-footer");

  useEffect(() => {
    const handleVirtualKeyboard = () => {
      if (actionFooterRef?.current) {
        const topPosition = actionFooterRef.current.getBoundingClientRect().top;
        if (!currentTopPosition || currentTopPosition > topPosition) {
          setClassName("action-footer hidden");
        } else {
          setClassName("action-footer");
        }
        setCurrentTopPosition(topPosition);
      }
    };
    window.addEventListener("resize", handleVirtualKeyboard);
    return () => {
      window.removeEventListener("resize", handleVirtualKeyboard);
    };
  }, [actionFooterRef, currentTopPosition]);

  return (
    <React.Fragment>
      {!isMobile ? (
        <FormButton text={text} withLoading={withLoading} />
      ) : (
        <div ref={actionFooterRef} className={className} style={{ zIndex: 0 }}>
          <FormButton text={text} withLoading={withLoading} />
          {extra}
        </div>
      )}
    </React.Fragment>
  );
};

export default FieldButton;
