import { Review } from "./types";

const reviewCardColors: string[] = [
  "#82dda7",
  "#91e7dc",
  "#72d7f2",
  "#b6abfe",
  "#ffaf57",
  "#82dda7",
  "#91e7dc",
];

export const getReviewColor = (index: number): string => {
  if (index > reviewCardColors.length - 1) {
    return getReviewColor(index - reviewCardColors.length);
  }
  return reviewCardColors[index];
};

export const reviews: Review[] = [
  {
    review:
      "We've been using Moshi since my now 4-year-old was 1. This app is a LIFESAVER.",
    author: "Tom",
    location: "USA",
    age: "4",
  },
  {
    review:
      "I’ve used this app for YEARS. It’s helped my son through so many big transitions in addition to helping him fall asleep soooo much faster.",
    author: "Mike",
    location: "USA",
    age: "8",
  },
  {
    review:
      "Amazing for all ages! My 2yo loves the stories/music & 9yo loves the relaxation he gets from the meditations. He winds down to one after school daily!",
    author: "Chantel M",
    location: "USA",
    age: "2 and 9",
  },
  {
    review:
      "Moshi= SUCH a comfort to my daughter. Helped her process grief from her great-grandma passing. The meditations ease her stress, tantrums, anxiety SM.",
    author: "Joe P",
    location: "USA",
    age: "7",
  },
  {
    review:
      "My son is 10 with ADHD. Moshi had made such a difference. He now settles faster, rarely gets back up, and actively asks for Moshi every single day.",
    author: "Ben",
    location: "USA",
    age: "10",
  },
  {
    review:
      "My son is 3 years old. Has listened to Moshi stories every night. Every night since he was born, that is. Needless to say, best app ever.",
    author: "Nicole",
    location: "USA",
    age: "3",
  },
  {
    review:
      "Moshi's critical to my 4YO's mental wellbeing. The mindfulness exercises are phenomenal & made a HUGE difference in her responsive behavior to stress.",
    author: "Inga",
    location: "UK",
    age: "4",
  },
  {
    review:
      "Moshi helps my 2 kids with anxiety & sleep. Pre-Moshi, they used to have panic attacks at night. It’s been unreal to see the change, we're so grateful.",
    author: "Debbie D",
    location: "USA",
    age: "3 and 9",
  },
  {
    review:
      "We've been using Moshi sleep stories since my 4-year-old was 9 months. They're a lifesaver.",
    author: "Beth D",
    location: "USA",
    age: "4",
  },
  {
    review:
      "My children talk about the Moshi characters in their everyday life and even find characters in books or movies that remind them of their Moshi friends.",
    author: "Lisa",
    location: "USA",
    age: "4 and 10",
  },
  {
    review:
      "My son was out in 10 min on night 1 of Moshi. Every night since, in 5. If this is witchcraft, I don't care. We'd tried EVERYTHING. My mind is blown.",
    author: "Tiffany",
    location: "USA",
    age: "3",
  },
  {
    review:
      "I have a 5yo with Autism+ADHD. She's a proper live wire, and this is the ONLY app that calms her down, especially at night. Truly indebted to Moshi.",
    author: "Faye D",
    age: "5",
  },
];
