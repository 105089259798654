import React, { ComponentType, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicationState } from "../../../store";

function withEmail<T>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const auth = useSelector((state: ApplicationState) => state.auth);
    const profile = useSelector((state: ApplicationState) => state.profile);

    const [email, setEmail] = useState("");
    useEffect(() => {
      if (profile.UserData && profile.UserData.email) {
        setEmail(profile.UserData.email);
      } else if (auth && auth.user && auth.user.email) {
        setEmail(auth.user.email);
      }
    }, [auth, profile.UserData]);

    return <WrappedComponent {...{ ...props, email }} />;
  };
}

export default withEmail;
