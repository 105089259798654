import Api from "clients/helpers/api";

import {
  DynamicContentApiResponse,
  FavoritesContentApiResponse,
  PartnerPlaylistsApiResponse,
  SchoolPlaylistsContentApiResponse,
  StaticContentApiResponse,
} from "../types";
import { MarketingContentApiResponse } from "../types/marketing";

export async function getContentStatic(): Promise<StaticContentApiResponse> {
  return Api.get("/content/static");
}

export async function getContentDynamic(
  userProfileId?: string
): Promise<DynamicContentApiResponse> {
  try {
    return await Api.get("/content/dynamic", { userProfileId });
  } catch (e) {
    return Api.get("/content/dynamic");
  }
}

export async function getFavorites(
  userProfileId?: string
): Promise<FavoritesContentApiResponse> {
  try {
    return await Api.get("/content/favorites", { userProfileId });
  } catch (e) {
    return Api.get("/content/favorites");
  }
}

export async function putFavorites(
  storyId: string,
  userProfileId?: string
): Promise<FavoritesContentApiResponse> {
  return Api.put("/content/favorites", { storyId, userProfileId });
}

export async function getSchoolPlaylists(): Promise<SchoolPlaylistsContentApiResponse> {
  return Api.get("/content/playlists/schools");
}

export async function getAllContent(userProfileId?: string) {
  const staticContentApiResponse = await getContentStatic();
  const dynamicContentApiResponse = await getContentDynamic(userProfileId);
  const favoriteContentApiResponse = await getFavorites(userProfileId);
  const schoolPlaylistsContentApiResponse = await getSchoolPlaylists();
  return {
    static: staticContentApiResponse,
    dynamic: dynamicContentApiResponse,
    favorites: favoriteContentApiResponse,
    schoolPlaylists: schoolPlaylistsContentApiResponse,
  };
}

export async function getMarketingContent(): Promise<MarketingContentApiResponse> {
  return Api.get("/content/marketing");
}

export async function getPartnerPlaylistsData(
  partnerName: string
): Promise<PartnerPlaylistsApiResponse> {
  return Api.get(`/content/playlists/${partnerName}`);
}

export default {
  getContentStatic,
  getContentDynamic,
  getFavorites,
  getSchoolPlaylists,
  getAllContent,
  getMarketingContent,
};
