export function millisecondsToMinutes(milliseconds: number): number {
  return Math.ceil((milliseconds * 0.001) / 60);
}

function millisecondsToHoursAndMinutes(milliseconds: number): [number, number] {
  let seconds = Math.ceil(milliseconds / 1000);
  let minutes = Math.ceil(milliseconds / 1000 / 60);
  const hours = Math.floor(milliseconds / 1000 / 60 / 60);

  seconds = seconds % 60;
  minutes = seconds >= 30 ? minutes + 1 : minutes;
  minutes = minutes % 60;
  // hours = hours % 24;

  return [hours, minutes];
}

export function millisecondsToFormattedText(milliseconds: number): string {
  const [hours, minutes] = millisecondsToHoursAndMinutes(milliseconds);
  let formattedString = "";
  if (hours > 0) {
    formattedString = `${hours} hr `;
  }
  formattedString += `${minutes} min`;
  return formattedString;
}
