import { ClientSubscriptionData } from "shared/helpers/user/types";

export interface Promotion {
  readonly promotionSlug: string;
  readonly merchantAccountSlug: string;
  readonly groups: string[];
  readonly countryCode: string;
  readonly currencyIsoCode: string;
  readonly basePrice: string;
  readonly discountPrice: string;
  readonly billingFrequency: number;
  readonly withTrial: boolean;
  readonly labels: Labels;
  readonly purchaseSurveyId?: string;
  readonly alternativePromotion?: Promotion;
  readonly promotionNotAvailableMessage?: string;
  readonly showLogin: boolean;
}

export interface Labels {
  [key: string]: string;
}

export interface LabelizedSubscription {
  showUpgrade: boolean;
  showCancel: boolean;
  startTimeDate: string | null;
  renewalTimeDate: string | null;
  renewalLabel: string;
  renewalDetails: string;
  storeLabel: string | null;
  platform: string;
  storeLink: string | null;
  renewalTimeDateTitle: string | null;
  startTimeDateTitle: string | null;
  subscriptionStatusDetails: string;
  codeDetails: string | null;
  paymentDetails: string;
  showUpdate: boolean;
  promotionDetails: string;
  [key: string]: string | boolean | null;
}

export interface PartnerInfo {
  partnerDisplayName: string;
  partnerLogoImagePath: string;
  promotionSlug: string;
  showRedeemScreen: boolean;
  isRefererHeaderValid: boolean;
  promoCode: string;
  isPromoCodeValid: boolean;
  subscriptionPlanCopyOverrides: Record<string, string>;
}

export interface ReferralInfo {
  isUsable: boolean;
  subscriptionSku: string;
  referralCode: string;
  referralCodeOwnerName: string;
  userHadTrialBefore: boolean;
}

export interface ProductInfo {
  readonly isValid: boolean;
  readonly productRedeemType: string;
  readonly productRedeemPromotionSlug: string;
  readonly userHadTrialBefore: boolean;
}

export interface PromotionState {
  readonly error?: Error;
  readonly country?: string; // QA country override
  readonly data?: Promotion;
  readonly partner?: PartnerInfo;
  readonly referral?: ReferralInfo;
  readonly promoCode?: string;
  readonly selectedAlternativePromotion?: boolean;
}

export enum PromotionActionsTypes {
  PROMOTION_SET = "@@promotion/PROMOTION_SET",
  PROMOTION_SELECT = "@@promotion/PROMOTION_SELECT",
}

export interface PromoCodeRedeemResponse {
  readonly redeemType: string;
  readonly promoCodeSubscriptionData?: ClientSubscriptionData;
  readonly referralCodeData?: ReferralInfo;
  readonly productCodeData?: ProductInfo;
  readonly partnerCodeData?: PartnerInfo;
}

export interface CheckSchoolCodeResponse {
  isPromoCodeValid: boolean;
  redeemType: string;
}
